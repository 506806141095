import Link from "components/ExtendLink";
import moment from "moment";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import doctorImage from "../../../../files/doctor.png";
import staffImage from "../../../../files/staff.png";
import LottieLoader from '../../../../components/LottieLoader';
import lottieEmptyFile from '../../../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../../files/animation/loading.json';
import Swal from 'sweetalert2';
const _ = require("lodash");

function fullname(cell, row) {
  let imageUser;
  let isPublic;
  if (cell && row.createdBy) {
    if (!row.createdBy.isStaff) {
      imageUser = (
        <p>
          <img src={row.createdBy.profileImage ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + row.createdBy.profileImage : doctorImage} className="mr-2" alt="image" />
          {row.createdBy.fname} {row.createdBy.lname}
        </p>
      );
    } else {
      imageUser = (
        <p>
          <img src={row.createdBy.profileImage ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + row.createdBy.profileImage : staffImage} className="mr-2" alt="image" />
          {row.createdBy.fname} {row.createdBy.lname}
        </p>
      );
    }
  } else {
    imageUser = "";
  }
  if (row.isPublic) {
    isPublic = (
      <div className="ml-5">
        <span class="badge badge-pill badge-warning">Public Note</span>
      </div>
    );
  }
  return (
    <div>
      {imageUser} {isPublic}
    </div>
  );
}
function device(cell, row, index, formatExtraData) {
  return (
    <div className="text-center"><p>{cell ? cell.name :  " - "}</p></div>
  );
}

function reminderSent(cell){
  return (
    <div className="text-center"><p>{cell && cell.reminderDate ? moment(cell.reminderDate).format("MMMM DD, YYYY hh:mm A") : " - "}</p></div>
  );
}

function NoteColumn({ cell }) {
  const maxLength = 150;
  const [isExpanded, setIsExpanded] = useState(false);
  if(cell && cell.length > maxLength){
    const shortContent = cell.substring(0, maxLength);
    const contentToShow = isExpanded ? cell : shortContent;
    const toggleExpansion = () => {
      setIsExpanded(!isExpanded);
    };
    return (
      <div className="text-justify" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <p style={{ margin: 0 }}>{contentToShow}
        {!isExpanded && (
          <>
          ...
          <a onClick={toggleExpansion} className="btn-read-more">Read More</a>
          </>
        )}
        {isExpanded && (
            <>
              &nbsp;{/* Add some space */}
              <a onClick={toggleExpansion} className="btn-read-more">Read Less</a>
            </>
          )}
        </p>
      </div>
    );
  }else{
    return <div><p>{cell ? cell : " - "}</p></div>;
  }
}
function formatDate(cell) {
  return (<p>{moment(cell).format("MMMM DD, YYYY hh:mm:ss A")}</p>);
}
function deleteButton(cell, row, index, formatExtraData) {
  if (!row.isPublic) {
    return (
      <button
        type="button"
        className="btn btn-info ml-2 delete-note-click"
        onClick={() => {
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              formatExtraData.delete(row._id)
            }
          })
        }}
      >
        <i class="mdi mdi-delete"></i> Delete
      </button>
    );
  } else {
    return (
      <div>
        <button
          type="button"
          className="btn btn-info ml-2 delete-note-click"
          onClick={() => {
            Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.isConfirmed) {
                formatExtraData.delete(row._id)
              }
            })
          }}
        >
          <i class="mdi mdi-delete"></i> Delete
        </button>
      </div>
    );
  }
}
const rowComponent = (row) => {
  if (row.subject && row.subject.indexOf("Permission Request") > -1) {
    return (
      <div className="text-left">
        {row.body && <h4 className="message-display">{row.body}</h4>}
        <Link to="/sharing" className="btn btn-primary btn-sm">
          Approved Here
        </Link>
      </div>
    );
  }
  return <div className="text-left">{row.body && <h4 className="message-display">{row.body}</h4>}</div>;
};

const NoteList = (props, context) => (
  <BootstrapTable
    wrapperClasses="table-responsive"
    keyField="_id"
    noDataIndication={() => {
      return <div>
        {props.loading && (
          <LottieLoader
            loadLoop={true}
            autoplayLoop={true}
            source={lottieLoaderFile}
            Lheight={150}
            Lwidth={150}
            subTitle={"Please Wait ..."}
          />
        )}
        {!props.loading && (
          <LottieLoader
            loadLoop={true}
            autoplayLoop={true}
            source={lottieEmptyFile}
            Lheight={300}
            Lwidth={300}
            title={"Sorry! No note found :("}
            subTitle={"We can't find any note."}
          />
        )}
      </div>
    }}
    data={_.orderBy(props.notes, "createdAt", "desc")}
    expandRow={{
      renderer: (row) => rowComponent(row),
      showExpandColumn: true,
      onExpand: (row, isExpand, rowIndex, e) => {},
    }}
    filter={filterFactory()}
    filterPosition="top"
    columns={[
      {
        dataField: "subject",
        text: "Subject",
        sort: true,
        filter: textFilter({
          className: 'input-date-filter',
          placeholder:"Search by subject"
        }),
        headerStyle: (colum, colIndex) => {
          return { width: '250px', textAlign: 'left' };
        },
        formatter: (cell) => (
          <p>{cell ? cell : '-'}</p>
        ),
      },
      {
        dataField: "body",
        text: "Note",
        sort: true,
        formatter: (cell, row, index, formatExtraData) => (
          <NoteColumn cell={cell} />
        ),
        filter: textFilter({
          className: 'input-date-filter',
          placeholder:"Search by note"
        })
      },
      {
        dataField: "device",
        text: "Shipped Device",
        sort: true,
        formatter: device,  
        headerStyle: (colum, colIndex) => {
          return { width: '230px', textAlign: 'left' };
        }      
      },
      {
        dataField: "reminderNoteData",
        text: "Reminder Date",
        sort: false,
        formatter: reminderSent,  
        headerStyle: (colum, colIndex) => {
          return { width: '185px', textAlign: 'left' };
        }      
      },
      {
        dataField: "createdAt",
        text: "Time",
        sort: true,
        formatter: formatDate,
        headerStyle: (colum, colIndex) => {
          return { width: '220px', textAlign: 'left' };
        },
      },
      {
        dataField: "createdBy.lname",
        text: "Noted By",
        sort: true,
        formatter: fullname,
        filter: textFilter({
          className: 'input-date-filter',
          placeholder:"Search by last name"
        }),
        headerStyle: (colum, colIndex) => {
          return { width: '230px', textAlign: 'left' };
        }
      },

      {
        dataField: "test",
        text: "Delete",
        sort: false,
        formatter: deleteButton,
        formatExtraData: {
          delete: props.deleteInbox
        },
        headerStyle: (colum, colIndex) => {
          return { width: '100px', textAlign: 'left' };
        }
      }
    ]}
  />
);

export default NoteList;
