import classnames from "classnames";
import React, { Component } from "react";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import onBoarding from "../../files/ic_on_boarding.png";
import SelectationTab from "./../OnBoarding/SelectationTab";
import OffBoardingList from "./OffBoardingList";
const _ = require("lodash");
class OffBoarding extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      activeTab: "1",
      onDisplayAll: true,      
      onReturnDevice: false,      
      onSentInvoice: false,      
      loading: true,
      catagoryID: undefined,
      OnReturnDeviceProviderOffice: false,
      OnDeviceLoss: false
    };
  }
  
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_ALL_DEVICE"
    });
    this.onFetchReadingCount();
    // this.props.dispatch({
    //   type: "DO_REMOVE_PROFILE_DATA"
    // });
    // // this.props.dispatch({
    // //   type: "DO_GET_USERS_YOU_HAVE_ACCESS"
    // // });

    // this.props.dispatch({
    //   type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    // });
    // this.props.dispatch({
    //   type: "DO_GET_PROFILE",
    //   payload: { authProfile: true }
    // });
  }
  onFetchReadingCount(){
    this.props.dispatch({
      type: "DO_GET_ON_BOARDING_COUNT"
    });
  }

  onDisplayAll() {
    this.onFetchReadingCount();        
    this.setState({
      onDisplayAll: true,
      onReturnDevice: false,      
      onSentInvoice:false,
      catagoryID: undefined,
      OnReturnDeviceProviderOffice : false,
      OnDeviceLoss : false
    }, () => {
      this.fetchData();
    });
  }
  onDisplayReturnDevice() {
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAll: false,
      onReturnDevice: true,      
      onSentInvoice:false,      
      catagoryID: "64620ebdb1cebb9c4d5b70f7",
      OnReturnDeviceProviderOffice : false,
      OnDeviceLoss : false
    }, () => {
      this.fetchData();
    });
  }
  onDisplayReturnedToProvidersOffice(){
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAll: false,
      onReturnDevice: false,      
      onSentInvoice:false,      
      catagoryID: "667aa0e00bc7bcfe6b65aa0e",
      OnReturnDeviceProviderOffice : true,
      OnDeviceLoss : false
    }, () => {
      this.fetchData();
    });
  }
  onDisplaySentInvoice() {
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAll: false,
      onReturnDevice: false,      
      onSentInvoice: true,      
      catagoryID: "64c781a345daedcb3562a359",
      OnReturnDeviceProviderOffice : false,
      OnDeviceLoss : false
    }, () => {
      this.fetchData();
    });
  }
  onDisplayDeviceLoss(){
    this.onFetchReadingCount(); 
    this.setState({
      onDisplayAll: false,
      onReturnDevice: false,      
      onSentInvoice: false,      
      catagoryID: "6705158ac1d421c2143a8632",
      OnReturnDeviceProviderOffice : false,
      OnDeviceLoss : true
    }, () => {
      this.fetchData();
    });
  }
  render() {    
    var allOnBoarding = { backgroundColor: "#198ae3", color: "white" };    
    var ReturnDevice = { backgroundColor: "white", color: "black" };
    var SentInvoiceToPatient = { backgroundColor: "white", color: "black" };
    var DeviceLoss = { backgroundColor: "white", color: "black" };;
    var ReturnDeviceProviderOffice = { backgroundColor: "white", color: "black" };
    var allOnBoardingSize = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.allOffBoarding : 0;
    var returnMerchandiseAuthorizationSize = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.returnMerchandiseAuthorizationSize : 0;
    var sentInvoiceToPatientSize = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.sentInvoiceToPatientSize : 0;
    var returnedToProvidersOffice = this.props.onBoardingDataCount ? this.props.onBoardingDataCount.returnedToProvidersOffice : 0;
    var deviceLossPatientSize = this.props.onBoardingDataCount && this.props.onBoardingDataCount.deviceLossPatientSize ? this.props.onBoardingDataCount.deviceLossPatientSize : 0;
    if (this.state.onReturnDevice) {
      // Return Device
      allOnBoarding = { backgroundColor: "white", color: "black" };      
      ReturnDevice = { backgroundColor: "#198ae3", color: "white" };      
      SentInvoiceToPatient = { backgroundColor: "white", color: "black" };
      ReturnDeviceProviderOffice = { backgroundColor: "white", color: "black" };
      DeviceLoss = { backgroundColor: "white", color: "black" };                              
    } else if (this.state.onSentInvoice) {
      // Sent Invoice To Patient
      allOnBoarding = { backgroundColor: "white", color: "black" };      
      ReturnDevice = { backgroundColor: "white", color: "black" };      
      SentInvoiceToPatient = { backgroundColor: "#198ae3", color: "white" };
      ReturnDeviceProviderOffice = { backgroundColor: "white", color: "black" };
      DeviceLoss = { backgroundColor: "white", color: "black" };                            
    }else if(this.state.OnReturnDeviceProviderOffice){
      // retrurned device to providers office
      allOnBoarding = { backgroundColor: "white", color: "black" };      
      ReturnDevice = { backgroundColor: "white", color: "black" };      
      ReturnDeviceProviderOffice = { backgroundColor: "#198ae3", color: "white" };
      SentInvoiceToPatient = { backgroundColor: "white", color: "black" };
      DeviceLoss = { backgroundColor: "white", color: "black" };                  
    }else if(this.state.OnDeviceLoss){
      allOnBoarding = { backgroundColor: "white", color: "black" };      
      ReturnDevice = { backgroundColor: "white", color: "black" };      
      DeviceLoss = { backgroundColor: "#198ae3", color: "white" };
      SentInvoiceToPatient = { backgroundColor: "white", color: "black" };
      ReturnDeviceProviderOffice = { backgroundColor: "white", color: "black" };            
    }
    return (
      <div>
        <div className="row">
          <div className="col-12 grid-margin">          
            <div className="card">
              <div className="card-body project-tab pt-2">
                <Nav tabs className="justify-content-center">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <img className="img-tabsfluid" src={onBoarding} style={{marginRight:5}}/>
                      RPM Off-Boarding
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row>
                  <Col className="grid-margin col-sm-12 mx-auto">
                    {!this.state.loading && (
                      <ReactLoading className="mx-auto" color={"#007bff"} />
                    )}
                    {this.state.loading && (
                      <Row className="mx-auto">
                        <Col className="mt-4 col-sm-2-half">
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayAll()
                            }
                          >
                            <SelectationTab
                              title={"All Patients"}
                              displayCount={allOnBoardingSize}
                              style={allOnBoarding}
                              />           
                          </div>
                        </Col>                        
                        <Col className="mt-4 col-sm-2-half">
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayReturnDevice()
                            }
                          >
                            <SelectationTab
                              title={"RMA issued to Patient"}
                              displayCount={returnMerchandiseAuthorizationSize}
                              style={ReturnDevice}
                              />           
                          </div>
                        </Col>
                        <Col className="mt-4 col-sm-2-half">
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplayReturnedToProvidersOffice()
                            }
                          >
                            <SelectationTab
                              title={"Returned to Provider's Office"}
                              displayCount={returnedToProvidersOffice}
                              style={ReturnDeviceProviderOffice}
                              />           
                          </div>
                        </Col>                        
                        <Col className="mt-4 col-sm-2-half">
                          {" "}
                          <div
                            className="card overflow-hidden card-design"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.onDisplaySentInvoice()
                            }
                          >
                            <SelectationTab
                              title={"Sent Invoice to Patient"}
                              displayCount={sentInvoiceToPatientSize}
                              style={SentInvoiceToPatient}
                              />           
                          </div>
                        </Col>
                        <Col className="mt-4 col-sm-2-half">
                        {" "}
                        <div
                          className="card overflow-hidden card-design"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.onDisplayDeviceLoss()
                          }
                        >
                          <SelectationTab
                            title={"Device Loss"}
                            displayCount={deviceLossPatientSize}
                            style={DeviceLoss}
                            />           
                        </div>
                      </Col>                          
                      </Row>
                    )}
                  </Col>
                  <OffBoardingList
                      fetchDataFromServer={click => this.fetchData = click}
                      catagoryID={this.state.catagoryID}
                      profileOwner={this.props.profileOwner}
                      userId={this.props.userId}
                      mDeviceList={this.props.mDeviceList}
                    />
                </Row>                
              </div>
            </div>
          </div>
        </div>       
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    mDeviceList,
    onBoardingDataCount
  } = careprovider;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    mDeviceList,
    onBoardingDataCount,
    userId,
    profileOwner
  };
};

export default connect(mapStateToProps)(OffBoarding);
