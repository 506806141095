import React, { useState } from "react";
import { connect } from "react-redux";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import UserName from "../Reusable/UserName";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import { Badge } from 'reactstrap';
import moment from "moment";
const _ = require("lodash");

function fullname(cell, row) {
  if(row){
    return <UserName user={row} />;
  }else{
    return '-'
  }
}

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    {"   "}Showing {from} to {to} of {size} Results
  </span>
);
const VersionReport = (props) => {
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const platformVersion = {}
  if(props.versionReports.latestVersions && props.versionReports.latestVersions.length > 0){
    props.versionReports.latestVersions.map((item) => {
      if(item.systemName.toLowerCase() == 'ios' && item.isNewApp){
        platformVersion.iosNewAppVersion = item.latestVersion
      }else if(item.systemName.toLowerCase() == 'android'&& item.isNewApp){
        platformVersion.androidNewAppVersion = item.latestVersion
      }else if(item.systemName.toLowerCase() == 'ios'&& !item.isNewApp){
        platformVersion.iosOldAppVersion = item.latestVersion
      }else if(item.systemName.toLowerCase() == 'android' && !item.isNewApp){
        platformVersion.androidOldVersion = item.latestVersion
      }
    })
  }
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No users found in the listing for this version."}
          // subTitle={"No users found in the listing for this version."}
        />
      )}
    </div>
  );
  const sentUpdateNotification = () => {
    if(selectedUser){
      props.dispatch({
        type: "DO_SENT_UPDATE_APP_NOTIFICATION",
        payload: {
          userId: selectedUser._id,
        }
      });
      setShowUpdateModel(false);
      setSelectedUser(null);
    }else{
      alert('Something went wrong, Please try again!')
      setShowUpdateModel(false);
      setSelectedUser(null);
    }
  }
  const options = {
    page: parseInt(props.versionReports.page || 0),
    sizePerPage: parseInt(props.versionReports.limit || 0),
    totalSize: parseInt(props.versionReports.totalCount || 0),
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: parseInt(props.versionReports.totalCount) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.versionReports.totalCount) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "25",
        value: 25
      },
      {
        text: "50",
        value: 50
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "200",
        value: 200
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };

  let columns = [
    {
      dataField: "fullNamel",
      text: "Last, First Name",
      sort: true,
      formatter: fullname,
    },
    {
      dataField: "nickname",
      text: "Nickname",
      sort: true,
      formatter: (cell, row) => {
        return row.nickname ? row.nickname : '-';
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cell, row) => {
        return row.email ? row.email : '-';
      },
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
      formatter: (cell, row) => {
        return row.phone ? row.phone : '-';
      },
    },
    {
        dataField: "mainCareProviders",
        text: "Main CareProvider",
        sort: true,
        formatter: (cell, row) => {
            return row.mainCareProviders ? row.mainCareProviders : '-';
          },
      },
      {
        dataField: "organization",
        text: "Organization",
        sort: true,
        formatter: (cell, row) => {
            return row.organization ? row.organization : '-';
          },
      }, {
        dataField: "applicationVersion",
        text: "App Version",
        sort: true,
        formatter: (cell, row) => {
            return row  ? row.applicationVersion ?? '-' : '-';
          },
      },
      //  {
      //   dataField: "apiVersion",
      //   text: "API Version",
      //   sort: true,
      //   formatter: (cell, row) => {
      //       return row.versionReport  ? row.versionReport.apiVersion ?? '-' : '-';
      //     },
      // },
      {
        dataField: "systemName",
        text: "Platform",
        // sort: true,
        formatter: (cell, row) => {
            return row  ? row.deviceInformation.platform ? (row.deviceInformation.platform.toLowerCase() == 'android' ? 'Android' : 'iOS') : row.deviceInformation.systemName ?? '-' : '-';
          },
      },
      {
        dataField: "applicationName",
        text: "App Status",
        sort: true,
        formatter: (cell, row) => {
            return row && row.applicationName ?  row.applicationName.toLowerCase().includes("new") ? 'New'  :  'Old' : '-';
          },
      },
      // {
      //   dataField: "createdAt",
      //   text: "First Login",
      //   // sort: true,
      //   formatter: (cell, row) => {
      //       return row && row.createdAt ?  moment(row.createdAt).format("MMMM DD, YYYY") : '-';
      //     },
      // },
      {
        dataField: "updatedAt",
        text: "Last Login",
        sort: true,
        formatter: (cell, row) => {
            return row && row.updatedAt ?  moment(row.updatedAt).format("MMMM DD, YYYY") : '-';
          },
      },
      // {
      //   dataField: "Log out",
      //   text: "Log Out",
      //   // sort: true,
      //   formatter: (cell, row) => {
      //       return row && row.updatedAt ?  moment(row.updatedAt).format("MMMM DD, YYYY") : '-';
      //     },
      // },
      {
        dataField: "versionReport",
        text: "App Updated",
        formatter: (cell, row) => {
            // ios application new app
            if(row && row.deviceInformation && (row.applicationName && row.applicationName.toLowerCase().includes("new")) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase() == 'ios') || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'ios')) && ((row.applicationVersion) != (platformVersion.iosNewAppVersion))){
              return <button className="btn btn-info close-click-cls m-0" onClick={() => {setShowUpdateModel(true); setSelectedUser(row && row.user)}}>Update</button>
            }
            // android application new app
            else if(row && row.deviceInformation && (row.applicationName && row.applicationName.toLowerCase().includes("new")) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase()) == 'android' || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'android')) && ((row.applicationVersion) != (platformVersion.androidNewAppVersion))){
              return <button className="btn btn-info close-click-cls m-0" onClick={() => {setShowUpdateModel(true); setSelectedUser(row && row.user)}}>Update</button>
            }
            // ios application old app
             if(row && row.deviceInformation && (row.applicationName && !(row.applicationName.toLowerCase().includes("new"))) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase() == 'ios') || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'ios')) && ((row.applicationVersion) != (platformVersion.iosOldAppVersion))){
              return <button className="btn btn-info close-click-cls m-0" onClick={() => {setShowUpdateModel(true); setSelectedUser(row && row.user)}}>Update</button>
            }
            // android application old app
            else if(row && row.deviceInformation && (row.applicationName && !(row.applicationName.toLowerCase().includes("new"))) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase()) == 'android' || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'android')) && ((row.applicationVersion) != (platformVersion.androidOldVersion))){
              return <button className="btn btn-info close-click-cls m-0" onClick={() => {setShowUpdateModel(true); setSelectedUser(row && row.user)}}>Update</button>
            }
            else if(row && row.deviceInformation && (row.deviceInformation.systemName || row.deviceInformation.platform) && ((row.applicationName && !(row.applicationName.toLowerCase().includes("new"))) && (((row.applicationVersion) == (platformVersion.iosOldAppVersion)) || ((row.applicationVersion) == (platformVersion.androidOldVersion))) || ((row.applicationName && row.applicationName.toLowerCase().includes("new")) && ((row.applicationVersion) == (platformVersion.iosNewAppVersion)) || ((row.applicationVersion) == (platformVersion.androidNewAppVersion))))){
             return <Badge color="success" pill outline> UPDATED </Badge> 
            }else{
              return '-';
            }
          },
      },
  ]
  if (props.patientProfile) {
    columns = columns.filter(item => item.dataField !== 'recepient');
  }
  return (
    <div className="col-md-12 mx-auto mt-2"> <BootstrapTable
    remote={{
      sort: false,
      pagination: true,
      filter: true,
      search: true
    }
    }
    wrapperClasses="table-responsive"
    keyField="_id"
    noDataIndication={() => <NoDataIndication />}
    data={props.versionReports?.data || []}
    pagination={options.page > 0 ? paginationFactory(options) : undefined}
    columns={columns}
    bordered
    onTableChange={props.pagination}
  />
    <SweetAlert
    warning
    showCancel
    show={showUpdateModel}
    confirmBtnText="Send notification"
    confirmBtnBsStyle="danger"
    cancelBtnBsStyle="default"
    title="Are you sure?"
    onConfirm={sentUpdateNotification}
    onCancel={() => {setShowUpdateModel(false); setSelectedUser(null)}}
    >
    {`You want to send app version update notification and SMS to ${selectedUser && selectedUser.fullNamef ? selectedUser.fullNamef : 'user'}`}
    </SweetAlert>
    </div>
)
}

const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
   
  } = careprovider;
  return {

  };
};

export default connect(mapStateToProps)(VersionReport);
