import React, { useState, useEffect, useContext } from "react";

import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
const useFetch2 = initialSettings => {
  const auth = useSelector(state => state.user.userDetails);
  const role = auth.role;
  const isClient = auth.region;
  const token = isEmpty(auth) ? null : auth.token;
  const careprovider = isEmpty(auth) ? "initial" : auth.isCareProvider;
  let path = "/";
  if (careprovider !== "initial") {
    if (role === "support" || role === "admin") {
      path = "/admin";
    } else if (isClient) {
      path = "/client";
    } else if (careprovider) {
      path = "/careprovider";
    } else if (!careprovider) {
      path = "/patient";
    }
  }

  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState({ error: null, message: null });
  const [settings, setSettings] = React.useState(initialSettings);
  const [isLoading, setIsLoading] = React.useState(false);
  
  let settingsUrl = settings ? settings.url : "";
  // if(settingsUrl == undefined && settingsUrl === ""){
  //   return;
  // }
  let requestURL = process.env.REACT_APP_HOST_URL_PREFIX + path + settingsUrl;  
  React.useEffect(() => {
    const abortController = new AbortController();
    let mounted = true;
    const fetchData = async () => {
      if (mounted) setIsLoading(true);
      try {
        //console.log('settings', settings,requestURL)
        const res = await fetch(requestURL, {
          signal: abortController.signal,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": token
          },
          ...settings
        });
        const json = await res.json();
        if (res.ok) {
          if (mounted) setResponse(json);
          if (settings.method != "GET") {
            toast.success("Success");

            setIsLoading(false);
          }
        } else {
          toast.error("Action not successful");
          if (mounted) setError(json);
          if (mounted) setIsLoading(false);
        }

        if (mounted) setIsLoading(false);
      } catch (error) {
        if (error.name === "AbortError") {
          if (settings.disableWait) {
          } else {
            toast.warning("Please Wait");
          }
        } else {
          toast.error("Action not successful");
        }

        if (mounted) setError(error);
      }
    };
    if (settings) {
      fetchData();
    }
    const cleanup = () => {
      mounted = false;
      abortController.abort();
    };
    return cleanup;
  }, [settings]);
  return [{ response, error, isLoading }, setSettings, setError];
};

function formatDuration(duration) {
  function pad(number) {
    return strPad(number, 2);
  }

  let hours = (duration / 3600) | 0;
  let minutes = ((duration % 3600) / 60) | 0;
  let seconds = duration % 60;
  let minsSecs = `${pad(minutes)}:${pad(seconds)}`;

  return hours > 0 ? `${hours}:${minsSecs}` : "00:" + minsSecs;
}
function strPad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
export { useFetch2, formatDuration };
