import _ from "lodash";
import moment from "moment-timezone";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-datetime/css/react-datetime.css";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Alert,
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { toast } from "react-toastify";
import { AdherenceBody } from "./configBody";
import Heading from "./Heading";
import RequiredSelect from "./RequiredSelect";
import { AdherenceIcon } from "./RuleIcon";

class Adherence extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch({
      type: "DO_GET_USERS_LIST"
    });

    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      countTime: 1,
      timezone: { label: moment.tz.guess(), value: moment.tz.guess() },

      body: AdherenceBody
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.handleConditions = this.handleConditions.bind(this);
    this.handleTimezone = this.handleTimezone.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }
  resetForm() {
    this.setState({
      modal: false,
      modal2: false
    });

    this.props.dispatch({
      type: "GENERIC_ERROR",
      payload: { isGenericSuccess: false }
    });

    this.setState({
      body: {
        ...this.state.body,
        AdherenceBody
      }
    });
    this.state.body.notifySelf.length = 0;
    this.state.body.notifyReadingOwner.length = 0;
    this.state.body.notifyStaff.length = 0;
    this.props.history.goBack();
  }

  handleChange = (selectedOption) => {
    this.setState({ readingSubType: selectedOption });
  
    this.setState({
      body: {
        ...this.state.body,
        readingType: selectedOption.reading,
        readingSubType: selectedOption.name,
        readingSubTypeId: selectedOption._id
      }
    });
  };
  submit(e) {
    e.preventDefault();

    if (
      this.state.body.notifyReadingOwner.length > 0 &&
      this.state.body.message.trim().length === 0
    ) {
      toast.error(
        "A notification is checked in notify reading owner, please provide a message for the Patient"
      );
    } else if ((this.state.body.notifyStaff.length > 0 ||
        this.state.body.notifySelf.length > 0) && this.state.body.messageCareprovider.trim().length === 0) {
      toast.error(
        "A notification is checked in notify me/staff, please provide a message for the care prodiver/Staff"
      );
    } else {
      this.props.dispatch({
        type: "DO_SAVE_ADHERENCE_RULE",
        payload: this.state.body
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;

    const name = target.name;
    const notifySelf = this.state.body.notifySelf;
    const notifyReadingOwner = this.state.body.notifyReadingOwner;
    const notifyStaff = this.state.body.notifyStaff;
    if (target.type === "checkbox") {
      let index;
      if (target.checked) {
       // add the numerical value of the checkbox to options array
       if (name === "notifySelf") {
        const checkSelf = notifySelf.includes(event.target.value);
        if(!checkSelf){
          notifySelf.push(event.target.value);
        }
      } else if (name === "notifyStaff") {
        const checkStaff = notifyStaff.includes(event.target.value);
        if(!checkStaff){
          notifyStaff.push(event.target.value);
        }
      } else {
        const checkReadingOwner = notifyReadingOwner.includes(event.target.value);
        if(!checkReadingOwner){
          notifyReadingOwner.push(event.target.value);
        }
      }
      } else {
        // or remove the value from the unchecked checkbox from the array
        if (name === "notifySelf") {
          index = notifySelf.indexOf(event.target.value);
          notifySelf.splice(index, 1);
        } else if (name === "notifyStaff") {
          index = notifyStaff.indexOf(event.target.value);
          notifyStaff.splice(index, 1);
        } else {
          index = notifyReadingOwner.indexOf(event.target.value);
          notifyReadingOwner.splice(index, 1);
        }
      }
      this.setState({
        body: {
          ...this.state.body,
          notifySelf: notifySelf,
          notifyReadingOwner: notifyReadingOwner,
          notifyStaff: notifyStaff
        }
      });
      return;
    }

    if (name == "maxValue" || name == "minValue" || name == "value") {
      this.setState({
        body: {
          ...this.state.body,
          valueProperty: { ...this.state.body.valueProperty, [name]: value }
        }
      });
      return;
    }
    this.setState({ body: { ...this.state.body, [name]: value } });
  }

  handleUsers = (selectedOption) => {
    this.setState({ sharedUsers: selectedOption });
    const receivedFrom = _.map(selectedOption, "_id");
    this.setState({
      body: {
        ...this.state.body,
        sharedUsers: receivedFrom
      }
    });

    
  };
  handleTimezone = (selectedOption) => {
    this.setState({
      body: {
        ...this.state.body,
        timezone: selectedOption.value
      },
      timezone: selectedOption
    });

    
  };
  handleConditions = (selectedOption) => {
    
    this.setState({
      body: {
        ...this.state.body,
        conditionGroup: _.map(selectedOption, "_id")
      }
    });
  };
  componentDidMount() {
    if (!this.props.isCareProvider) {
      this.setState({
        body: {
          ...this.state.body,
          sharedUsers: [this.props.userId]
        }
      });
    }
    this.props.dispatch({
      type: "GENERIC_ERROR",
      payload: { isGenericSuccess: false }
    });
    if (!this.props.conditions) {
      this.props.dispatch({
        type: "DO_GET_CONDITIONS"
      });
    }
    const timezoneList = moment.tz.names();
    const mapTimezones = _.map(timezoneList, (v) => {
      return { label: v, value: v };
    });
  
    this.setState({ timezoneList: mapTimezones });
  }

  render() {
    return (
      <div>
        <div className="row">
          <Heading
            backbutton="d-flex"
            mainbutton="d-none"
            title="Create Adherence Rule x times Per Day"
            isCareProvider={this.props.isCareProvider}
          >
            <img
              src={AdherenceIcon}
              className="mr-2"
              style={{ width: "40px", height: "40px" }}
              alt="image"
            />
          </Heading>
          <div className="col-12 grid-margin">
            <div className="card">
              <Alert color="info">
                Adherence will be process everyday at 9:00AM base on timezone
                you choose
              </Alert>
              <div className="card-body project-tab  pt-2 mt-4">
                <Form onSubmit={this.submit}>
                  <FormGroup>
                    <Label for="exampleSelect">Select a Timezone</Label>
                    <RequiredSelect
                      SelectComponent={Select}
                      value={this.state.timezone}
                      onChange={this.handleTimezone}
                      options={this.state.timezoneList}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Expectation Name</Label>
                    <Input
                      type="text"
                      name="name"
                      className="input-date-filter"
                      id="exampleEmail"
                      required={true}
                      onChange={this.handleInputChange}
                      placeholder="Expectation Name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">
                      Notification Message (Patient)
                    </Label>
                    <Input
                      className="input-date-filter"
                      type="textarea"
                      name="message"
                      onChange={this.handleInputChange}
                      rows={10}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">
                      Notification Message (Careprovider)
                    </Label>
                    <Input
                      className="input-date-filter"
                      type="textarea"
                      name="messageCareprovider"
                      onChange={this.handleInputChange}
                      rows={10}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Pick a Reading Type</Label>
                    <RequiredSelect
                      SelectComponent={Select}
                      required={true}
                      value={this.state.readingSubTypesForExpecation}
                      onChange={this.handleChange}
                      getOptionLabel={(option) =>
                        option["name"] + " (" + option["abbrev"] + ")"
                      }
                      getOptionValue={(option) => option["_id"]}
                      options={this.props.readingSubTypesForExpecation}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">Times per Day</Label>
                    <Input
                      min={1}
                      className="input-date-filter"
                      type="number"
                      name="repeatPerDay"
                      required={true}
                      onChange={this.handleInputChange}
                      rows={10}
                      onWheel={(e) => e.target.blur()}
                    />
                  </FormGroup>

                  {this.props.isCareProvider && (
                    <>
                      <FormGroup>
                        <Label for="exampleSelect">
                          Select a Condition to Monitor
                        </Label>
                        <RequiredSelect
                          SelectComponent={Select}
                          // value={this.state.sharedUsers}
                          onChange={this.handleConditions}
                          options={this.props.conditions}
                          isMulti={true}
                          getOptionLabel={(option) =>
                            `${option["condition"]}  (${option["description"]})`
                          }
                          getOptionValue={(option) => option["_id"]}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleSelect">
                          Select a Patient to Monitor
                        </Label>
                        <RequiredSelect
                          SelectComponent={Select}
                          value={this.state.sharedUsers}
                          onChange={this.handleUsers}
                          options={this.props.assignedUsersList}
                          isMulti={true}
                          getOptionLabel={(option) =>
                            option["fname"] + " " + option["lname"]
                          }
                          getOptionValue={(option) => option}
                        />
                      </FormGroup>
                    </>
                  )}
                  {this.props.isCareProvider && (
                    <FormGroup>
                      <Label for="exampleCheckbox">Notify Me</Label>
                      <div>
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          value="inbox"
                          label="INBOX"
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline2"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          label="SMS"
                          value="sms"
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline3"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          label="VOICE"
                          value="voice"
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline4"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          label="EMAIL"
                          value="email"
                          inline
                        />
                      </div>
                    </FormGroup>
                  )}

                  <FormGroup>
                    {this.props.isCareProvider && (
                      <Label for="exampleCheckbox">Notify Readings Owner</Label>
                    )}

                    <div>
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline6"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        value="inbox"
                        label="INBOX"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline7"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        label="SMS"
                        value="sms"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline8"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        label="VOICE"
                        value="voice"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline9"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        label="EMAIL"
                        value="email"
                        inline
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    {this.props.isCareProvider && (
                      <Label for="exampleCheckbox">Notify Staff</Label>
                    )}

                    <div>
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline10"
                        name="notifyStaff"
                        onChange={this.handleInputChange}
                        value="inbox"
                        label="INBOX"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline11"
                        name="notifyStaff"
                        onChange={this.handleInputChange}
                        label="SMS"
                        value="sms"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline12"
                        name="notifyStaff"
                        onChange={this.handleInputChange}
                        label="VOICE"
                        value="voice"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline13"
                        name="notifyStaff"
                        onChange={this.handleInputChange}
                        label="EMAIL"
                        value="email"
                        inline
                      />
                    </div>
                  </FormGroup>

                  <Button
                    type="submit"
                    className="btn btn-info btn-lg btn-block"
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={this.resetForm}
          confirmBtnBsStyle="info"
        >
          Submitted Successfully
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    readingSubTypes,
    readingSubTypesForExpecation,
    usersOfferedAccess,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    conditions,
    assignedUsersList
  } = careprovider;
  const userId = user.userDetails._id;
  const isCareProvider = user.userDetails.isCareProvider;

  return {
    readingSubTypes,
    readingSubTypesForExpecation,
    usersOfferedAccess,
    userId,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    conditions,
    isCareProvider,
    assignedUsersList
  };
};

export default connect(mapStateToProps)(Adherence);
