import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Row,
  Collapse,
  Col,
} from "reactstrap";

import Link from "components/ExtendLink";
import { NavLink } from "reactstrap";
import axios from "axios";

import { useFetch } from "components/utils/fetchHook";
import { useFetch2 } from "components/utils/authenticatedHook";
import Select from "react-select";
import { primaryInsuranceProviders } from "../../../constants/general.constant";
const CreateUserQuickModal = (props) => {
  const [emailAvailable, setEmailAvailable] = useState({
    validate: true,
    message: "",
  });
  const [usernameAvailable, setUsernameAvailable] = useState({
    validate: true,
    message: "",
  });
  const [prescribeTestContainer, setprescribeTestContainer] = useState(false);
  const [{ response, isLoading }] = useFetch2({
    url: "/get-careprovider-conditions/" + props.careproviderId,
    method: "GET"
  });
  const conditions = response ? response.data : response;
  const toggle = () => setprescribeTestContainer(!prescribeTestContainer);
  return (
    <Modal
      isOpen={props.modalQuickUser}
      toggle={props.toggleUserQuick}
      backdrop="static"
      size="lg"
      className="bastaLang modal-md"
    >
      <ModalHeader toggle={props.toggleUserQuick}>
        Create a Patient Account
      </ModalHeader>

      <ModalBody style={{ backgroundColor: "white" }}>
        <Form onSubmit={props.submitUser}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleSelect">
                  Select the Condition of the Patient (Can be Multiple)*
                </Label>
                <Select
                  SelectComponent={Select}
                  onChange={props.handleInputChangeCondition}
                  options={conditions != undefined ? conditions : []}
                  isMulti={true}
                  required={true}
                  getOptionLabel={(option) =>
                    `${option["condition"]}  (${option["description"]})`
                  }
                  getOptionValue={(option) => option["_id"]}
                  isLoading={isLoading}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Email*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  required={true}
                  type="email"
                  name="email"
                  onChange={props.handleInputChange}
                  onBlur={e => {
                    axios
                      .get("/check-availability?email=" + e.target.value)
                      .then(function(response) {
                        setEmailAvailable({ validate: true, message: "" });
                      })
                      .catch(function(error) {
                        const { response = {} } = error;
                        const { data = {} } = response;
                        const { message = "" } = data;

                        setEmailAvailable({
                          validate: false,
                          message: message
                        });
                      });
                  }}
                  invalid={!emailAvailable.validate}
                  rows={10}
                />
                <FormFeedback>{emailAvailable.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Username*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="username"
                  required={true}
                  onBlur={(e) => {
                    axios
                      .get("/check-availability?username=" + e.target.value)
                      .then(function (response) {
                        // handle success
                        setUsernameAvailable({ validate: true, message: "" });
                      })
                      .catch(function (error) {
                        const { response = {} } = error;
                        const { data = {} } = response;
                        const { message = "" } = data;

                        setUsernameAvailable({
                          validate: false,
                          message: message,
                        });
                      });
                  }}
                  invalid={!usernameAvailable.validate}
                  onChange={props.handleInputChange}
                  rows={10}
                />
                <FormFeedback>{usernameAvailable.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Password*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="password"
                  name="password"
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Confirm Password*</Label>
                <Input
                  invalid={props.errorPassword}
                  type="password"
                  className="form-control form-control-lg input-date-filter remove-bottom-margin"
                  placeholder="Confirm Password"
                  name="confirmpassword"
                  onChange={props.handleInputChange}
                  rows={10}
                />

                {props.errorPassword && (
                  <FormFeedback>Password does not match</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="exampleText">First Name*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="fname"
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Last Name*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="lname"
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Nickname</Label>
                <Input
                  type="text"
                  name="nickname"
                  required={false}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Phone*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="phone"
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">DOB*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="date"
                  name="bday"
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <div className="form-group">
                <label>Gender</label>
                <select
                  name="gender"
                  className="form-control"
                  defaultValue="Male"
                  onChange={props.handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="form-group">
                <label>Primary Insurance Provider</label>
                <select
                  name="insuranceProvider"
                  className="form-control"
                  defaultValue=""
                  onChange={props.handleInputChange}
                >
                {primaryInsuranceProviders && primaryInsuranceProviders.length > 0 &&
                  primaryInsuranceProviders.map((item) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))
                }
                </select>
              </div>
            </Col>
          </Row>
          <button type="submit" className="btn btn-block btn-info">
            SAVE
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CreateUserQuickModal;
