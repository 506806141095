import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PropTypes from "prop-types";
import Link from "components/ExtendLink";
import React from 'react';
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from "reactstrap";
import userImage from "../../files/user.png";
import LottieLoader from '../LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import lottiePending from '../../files/animation/ic_pending.json';
import lottieCompleted from '../../files/animation/ic_completed.json';
const _ = require("lodash");

const OnBoardingCCMDataTable = (props) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: parseInt(props.totalSize) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.totalSize) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "200",
        value: 200
      },
      {
        text: "All",
        value: props.totalSize
      }
    ]
  };
  const columns = [
    {
      dataField: "recepientLname",
      text: "Last, First Name",
      formatter: fullname,
      sort: true
    },{
      dataField: "recepientNickname",
      text: "Nickname",
      formatter: (cell, row) => {
        return row.recepientNickname ? row.recepientNickname : <div className='text-center'>-</div>;
      },
      sort: true
    },{
      dataField: "recepientPhone",
      text: "Phone",
      formatter: (cell, row) => {
        return row.recepientPhone ? row.recepientPhone : <div className='text-center'>-</div>;
      },
      sort: true
    },{
      dataField: "lastNoteCreatedDate",
      text: "Date of Last Note",
      formatter: (cell, row) => {
        const lastOnBoarding = row.lastOnBoarding;
        const model = _.find(props.allCategorys, model => {
          if(lastOnBoarding && lastOnBoarding.category){
            return model._id == lastOnBoarding.category;
          } else if (lastOnBoarding &&  lastOnBoarding.lastCategory){
            return model._id == lastOnBoarding.lastCategory;
          }          
        })
        if(model && model.category){
          return lastOnBoarding && lastOnBoarding.createdAt ? (
            <div>
              <span>{moment(lastOnBoarding.updatedAt).format("MMMM DD, YYYY hh:mm:ss A")}</span>
              <span style={{fontWeight:800}}><br></br><br></br>{model ? model.category : <div className='text-center'>-</div>}</span>
            </div>
          ) : <div className='text-center'>-</div>;
        } else {
          return lastOnBoarding && lastOnBoarding.createdAt ? (
            <div>
              <span>{moment(lastOnBoarding.updatedAt).format("MMMM DD, YYYY hh:mm:ss A")}</span>
              <span style={{fontWeight:800}}><br></br><br></br>{lastOnBoarding ? (lastOnBoarding.subject ? lastOnBoarding.subject : '-') : <div className='text-center'>-</div>}</span>
            </div>
          ) : <div className='text-center'>-</div>;
        }
        
      },
      sort: true
    },{
      dataField: "lastNeedToRequestCCMOrderFromProvider",
      text: "Need To Request CCM Order From Provider",
      sort: false,
      formatter: (cell, row) => {
        return cell && cell.createdAt ? moment(cell.createdAt).format("MMMM DD, YYYY hh:mm:ss A") : <div className='text-center'>-</div>;
      },
    },{
      dataField: "lastRequestedCCMOrderFromProvider",
      text: "Requested CCM Order From Provider",
      sort: false,
      formatter: (cell, row) => {
        return cell && cell.createdAt ? moment(cell.createdAt).format("MMMM DD, YYYY hh:mm:ss A") : <div className='text-center'>-</div>;
      },
    }, {
      dataField: "recepientMainCareProvider",
      text: "Main Care Provider",
      formatter: (cell, row) => {
        return row.recepientMainCareProvider ? row.recepientMainCareProvider : <div className='text-center'>-</div>;
      },
      sort: true
    }, {
      dataField: "recepientOrganization",
      text: "Organization",
      formatter: (cell, row) => {
        return row.recepientOrganization ? row.recepientOrganization : <div className='text-center'>-</div>;
      },
      sort: true
    },{
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className='div-center'>
            <Link
              to={`/dashboard/${row.recepient.fname
                .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                .toLowerCase()}-${row.recepient.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row.recepient._id
                }`}
              className="btn pull-right more-btn ">
              <i class="mdi mdi-account-card-details"></i> More Details
            </Link>
          </div>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: '150px', textAlign: 'center' };
      },
      sort: false
    },
  ];
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No ccm on boarding shipment found :("}
          subTitle={"We can't find any shipment."}
        />
      )}
    </div>
  );
  return (
    <Row className="col-sm-12 main-cls-modal remove-table-padding">
      <Col sm="12" className="mt-12">
        <div className="scrollbar-table-cls">
        <BootstrapTable
          remote={{
            sort: false,
            pagination: true,
            filter: true,
            search: true
          }
          }
          keyField="_id"
          loading={props.loading}
          data={props.data}
          columns={columns}
          pagination={paginationFactory(options)}
          onTableChange={props.onTableChange}
          noDataIndication={() => <NoDataIndication />}
          hover={props.totalSize > 0 ? true : false}
          condensed
          bordered
          wrapperClasses="table-responsive"
          expandRow={{
            renderer: (row) => rowComponent(row,props),
            onlyOneExpanding: false,
            showExpandColumn: true,
            expandByColumnOnly: false
          }}
        />
        </div>
      </Col>
    </Row>
  );
};

function fullname(cell, row) {
  const profile = row.recepient && row.recepient.profileImage ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + row.recepient.profileImage : userImage;
  // return <UserName user={row} />;
  return (
    <div className={row.isActive ? "" : " text-danger"}>
      <span>
        <img src={profile} className="mr-2" alt="image" />
        {capitalize(row.recepientLname)}
        {" "}
        {capitalize(row.recepientFname)}
      </span>
    </div>
  );
}

function device(cell, row) {
  const deviceImage = row.deviceAbbrev ? require(`../../files/Reading/${row.deviceAbbrev}.svg`) : undefined;
  return (
    <div className="text-left reading-card-for-all-reading">
      {deviceImage && (
        <img
          src={deviceImage}
          alt="profile-image"
          className="device-icon rounded-circle img-fluid"
        />
      )}
      <div>
        <span style={{ paddingLeft: 5 }}>{row.deviceDescription}</span>
      </div>
    </div>
  );
}



function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}

const rowComponent = (rowData,props) => {  
  const orderCategory = _.orderBy(rowData.onBoardings,["createdAt"],["acs"]);
  return (
    <div>
      {orderCategory && orderCategory.length > 0 && (
        <Row style={{ margin: "15px 0px" }}>
          <Col sm="12" className="mt-2 remove-summary-paddding">
            <Card style={{ border: "0px 10px 10px 0px" }}>
              <CardBody className='padding-card-body'>
                <BootstrapTable
                  keyField='id'
                  data={orderCategory}
                  columns={[{
                    dataField: 'category',
                    text: 'Shipment Category',
                    formatter: (cell, row) => {
                      const modelSession = _.find(props.allCategorys, (modelV) => {
                        return row.category == modelV._id
                      })
                      return (
                        <div>
                          {modelSession && (
                            <div className='text-left'>
                              <span>{modelSession.category}</span>
                            </div>
                          )}
                          {!modelSession && (
                            <div className='text-center'>
                              <span> - </span>
                            </div>
                          )}
                        </div>
                      )
                    },
                    classes: "row-padding",
                    headerStyle: (colum, colIndex) => {
                      return { width: '300px', textAlign: 'left' };
                    },
                  }, {
                    dataField: 'title',
                    text: "Title",
                    headerStyle: (colum, colIndex) => {
                      return { width: '300px', textAlign: 'left' };
                    },
                    formatter: (cell, row) => {
                      return (
                        <div>
                          {row.subject && (
                            <div className='text-left'>
                              <span>{row.subject}</span>
                            </div>
                          )}
                          {!row.subject && (
                            <div className='text-center'>
                              <span> - </span>
                            </div>
                          )}
                        </div>
                      )
                    }
                  }, {
                    dataField: 'body',
                    text: 'Message',
                    formatter: (cell, row) => {
                      return (
                        <div>
                          {row.body && (
                            <div className='text-left'>
                              <span>{row.body}</span>
                            </div>
                          )}
                          {!row.body && (
                            <div className='text-center'>
                              <span> - </span>
                            </div>
                          )}
                        </div>
                      )
                    }
                  }, {
                    dataField: 'createdAt',
                    text: 'Date',
                    headerStyle: (colum, colIndex) => {
                      return { width: '190px', textAlign: 'left' };
                    },
                    formatter: (cell, row) => {
                      return (
                        <div>
                          {row.createdAt && (
                            <div className='text-left'>
                              <span>{moment(row.createdAt).format("MMMM DD, YYYY hh:mm:ss A")}</span>
                            </div>
                          )}
                          {!row.createdAt && (
                            <div className='text-center'>
                              <span> - </span>
                            </div>
                          )}
                        </div>
                      )
                    },
                  }]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

OnBoardingCCMDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
export default OnBoardingCCMDataTable;