import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import configureStore from "../../store/configureStore";
class SimpleView extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }
  logout() {
    this.props.dispatch({
      type: "DO_LOGOUT"
    });
  }
  componentDidMount() {}
  render() {
    return (
      <div>
        {this.props.userDetails && (
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth">
              <div className="row w-100" style={{ marginLeft: "0px" }}>
                <div className="col-8 mx-auto">
                  <div className="auth-form-light text-left p-5 login-style">
                    <div className="brand-logo mx-auto text-center">
                      <img
                        alt=""
                        src="https://yt3.ggpht.com/a-/ACSszfHNC-8J7s5yBqy1gHGi5eXlmlOXp8xW7gQpOw=s900-mo-c-c0xffffffff-rj-k-no"
                      />

                      <div />
                    </div>
                    <div className="col-8 mx-auto">
                      {" "}
                      <h2 className>
                        Here is your credential for API Access (
                        {this.props.userDetails.organization}){" "}
                      </h2>
                      <div>
                        <b>CLIENT_ID</b> : {this.props.userDetails.CLIENT_ID}
                      </div>
                      <div>
                        <b>CLIENT_TOKEN</b>:{" "}
                        {this.props.userDetails.CLIENT_TOKEN}
                      </div>
                      Fill out <b>CLIENT-ID</b> , <b>CLIENT-TOKEN</b> as
                      Authorization in Authorize button before you try to call
                      the webservices under CLIENT API. In your application you
                      could use as a header or body parameter
                      <a
                        href={`${process.env.REACT_APP_VIDEO_CALL_REQUEST}/api-docs/`}
                        target="_blank"
                      >
                      {`${process.env.REACT_APP_VIDEO_CALL_REQUEST}/api-docs/`}
                      </a>
                      <h2 className="mt-5">Your Account</h2>
                      <div>
                        <b>Username</b> : {this.props.userDetails.username}
                      </div>
                      <div>
                        <b>Email</b>: {this.props.userDetails.email}
                      </div>
                      <div>
                        <b>Country</b>: {this.props.userDetails.country}
                      </div>
                      <div>
                        <b>Region</b>: {this.props.userDetails.region}
                      </div>
                      <div>
                        <b>Organization</b>:{" "}
                        {this.props.userDetails.organization}
                      </div>
                      <a
                        className="nav-link btn btn-info mt-2"
                        href="#"
                        onClick={this.logout}
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, user }) => {
  const { email, password, errorLogin } = auth;
  const userDetails = user.userDetails;
  return { email, password, errorLogin, userDetails };
};

export default connect(mapStateToProps)(SimpleView);
