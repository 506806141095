import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardHeader,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
} from "reactstrap";
import inviteImage from "../../../files/invite.png";
import classnames from "classnames";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Heading from "./Heading";
import StaffList from "./StaffList";
import CreateStaffModal from "./CreateStaffModal";
const _ = require("lodash");
class StaffClient extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      modalStaff: false,
      emailOffer: "",
      emailAccess: "",
      fname: "",
      lname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      showAlertSuccess: false,
      errorPassword: false,
      isClient: true,
      mainCareProvider: this.props.userId,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleStaff = this.toggleStaff.bind(this);
    this.openInvite = this.openInvite.bind(this);
    this.submit = this.submit.bind(this);
    this.submitStaff = this.submitStaff.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.deleteInvitation = this.deleteInvitation.bind(this);
    this.userInputChange = this.userInputChange.bind(this);
    this.expectationInputChange = this.expectationInputChange.bind(this);
  }
  userInputChange(event, staffId) {
    const target = event.target;
    const isCheck = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (isCheck) {
      this.props.dispatch({
        type: "DO_ADD_OR_DELETE_STAFF_PATIENT",
        payload: { staffId: staffId, isAdd: true, users: [target.name] },
      });
    } else {
      this.props.dispatch({
        type: "DO_ADD_OR_DELETE_STAFF_PATIENT",
        payload: { staffId: staffId, isAdd: false, user: target.name },
      });
    }
    setTimeout(() => {
      if (this.props.isAll) {
        this.props.dispatch({
          type: "DO_GET_STAFF",
          payload: { isClient: true, isAll: true },
        });
      } else {
        this.props.dispatch({
          type: "DO_GET_STAFF",
          payload: { isClient: true, user: this.props.userId },
        });
      }
    }, 200);
    // this.setState({
    //   [name]: value
    // });
  }
  expectationInputChange(event, staffId, expectation) {
    const target = event.target;
    const isCheck = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (isCheck) {
      
      this.props.dispatch({
        type: "DO_ADD_OR_DELETE_EXPECTATION",
        payload: {
          staffId: staffId,
          isAdd: true,
          expectationId: expectation._id,
          expectationType: expectation.ruleType,
        },
      });
    } else {
      this.props.dispatch({
        type: "DO_ADD_OR_DELETE_EXPECTATION",
        payload: {
          staffId: staffId,
          isAdd: false,
          expectationId: expectation._id,
          expectationType: expectation.ruleType,
        },
      });
    }
    setTimeout(() => {
      if (this.props.isAll) {
        this.props.dispatch({
          type: "DO_GET_STAFF",
          payload: { isClient: true, isAll: true },
        });
      } else {
        this.props.dispatch({
          type: "DO_GET_STAFF",
          payload: { isClient: true, user: this.props.userId },
        });
      }
    }, 200);
    // this.setState({
    //   [name]: value
    // });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }
  resetForm() {
    this.setState({
      modal2: false,
      modal: false,
      modalStaff: false,
      fname: "",
      lname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      role: "",
    });
    this.props.dispatch({
      type: "GENERIC_ERROR",
    });
    if (this.props.isAll) {
      this.props.dispatch({
        type: "DO_GET_STAFF",
        payload: { isClient: true, isAll: true },
      });
    } else {
      this.props.dispatch({
        type: "DO_GET_STAFF",
        payload: { isClient: true, user: this.props.userId },
      });
    }
  }
  toggleModal() {
    
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggleStaff() {
    
    this.setState({
      modalStaff: !this.state.modalStaff,
    });
  }
  openInvite() {
    
    this.setState({
      modal2: !this.state.modal2,
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  deleteInvitation(id) {
    this.props.dispatch({
      type: "DO_DELETE_STAFF",
      payload: { id: id },
    });
  }
  groupByUser() {
    var result = _.chain(this.props.pmRecords)
      .groupBy("patient._id")
      .map((trackTime, userId) => ({ trackTime, userId }))
      .value();
    return result;
  }
  submit(e) {
    e.preventDefault();

    this.props.dispatch({
      type: "DO_SEND_INVITE",
      payload: {
        fname: this.state.fname,
        lname: this.state.lname,
        email: this.state.email,
        role: this.state.role,
        isStaff: true,
        isCareProvider: false,
      },
    });
  }
  submitStaff(e) {
    e.preventDefault();
    const cleanStaff = _.omit(this.state, [
      "activeTab",
      "modal",
      "modal2",
      "modalStaff",
      "emailOffer",
      "confirmpassword",
      "emailAccess",
      "showAlertSuccess",
      "errorPassword",
    ]);
    this.props.dispatch({
      type: "DO_SAVE_STAFF",
      payload: cleanStaff,
    });
    setTimeout(() => {
      if (this.props.isAll) {
        this.props.dispatch({
          type: "DO_GET_STAFF",
          payload: { isClient: true, isAll: true },
        });
      } else {
        this.props.dispatch({
          type: "DO_GET_STAFF",
          payload: { isClient: true, user: this.props.userId },
        });
      }

      this.setState({
        modal2: false,
        modal: false,
        modalStaff: false,
        fname: "",
        lname: "",
        email: "",
        username: "",
        role: "",
      });
    }, 400);
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "GENERIC_ERROR",
    });
    if (this.props.isAll) {
      this.props.dispatch({
        type: "DO_GET_STAFF",
        payload: { isClient: true, isAll: true },
      });
    } else {
      this.props.dispatch({
        type: "DO_GET_STAFF",
        payload: { isClient: true, user: this.props.userId },
      });
    }
  }

  render() {
    let staffData;
    if (this.props.isClient) {
      staffData = this.props.clientStaff;
    } else {
      staffData = this.props.staff;
    }
    return (
      <div>
        <div className="row">
          {this.props.isAll && (
            <Heading
              title="STAFF/SUBUSER LIST"
              description=""
              backbutton="d-none"
              mainbutton="d-none"
              toggleModal={this.toggleModal}
            >
              <span className="page-title-icon bg-gradient-danger   text-white mr-2">
                <i className="mdi mdi-account-convert" />
              </span>
            </Heading>
          )}
          {!this.props.isAll && (
            <Heading
              title={null}
              description=""
              backbutton="d-none"
              mainbutton="d-block"
              toggleModal={this.toggleModal}
            >
              <span className="page-title-icon bg-gradient-danger   text-white mr-2">
                <i className="mdi mdi-account-convert" />
              </span>
            </Heading>
          )}

          <div className="col-12 grid-margin">
            <div className="card">
              <Nav tabs className="justify-content-center">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    STAFF
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="card-body project-tab pt-2">
                <Row>
                  <Col sm="12" className="grid-margin mt-4">
                    {staffData && (
                      <StaffList
                        usersOfferedAccess={this.props.usersOfferedAccess}
                        expectations={this.props.myRules}
                        deleteInvitation={this.deleteInvitation}
                        staff={staffData}
                        userInputChange={this.userInputChange}
                        expectationInputChange={this.expectationInputChange}
                      />
                    )}
                    {/* {this.props.pmRecords && (
                      <CCMList
                        pmRecords={this.groupByUser()}
                        userId={this.props.userId}
                      />
                    )} */}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>
            Create a New Staff
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <h5 className="text-center" />
            <div className="row">
              {this.props.isClient && (
                <div className="col-12">
                  <NavLink
                    className="text-center d-block"
                    onClick={this.toggleStaff}
                  >
                    <img
                      src="https://img.freepik.com/free-vector/cute-doctor-couple_57812-25.jpg?size=338&ext=jpg"
                      style={{ height: "180px" }}
                    />
                    <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                      CREATE A NEW STAFF ACCOUNT
                    </span>
                    <small>You will input everything</small>
                  </NavLink>
                </div>
              )}
              {!this.props.isClient && (
                <div className="col-6">
                  <NavLink
                    className="text-center d-block"
                    onClick={this.openInvite}
                  >
                    <img src={inviteImage} style={{ height: "180px" }} />
                    <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                      INVITE A USER
                    </span>
                    <small>Send a sign up link</small>
                  </NavLink>
                </div>
              )}
              {!this.props.isClient && (
                <div className="col-6">
                  <NavLink
                    className="text-center d-block"
                    onClick={this.toggleStaff}
                  >
                    <img
                      src="https://img.freepik.com/free-vector/cute-doctor-couple_57812-25.jpg?size=338&ext=jpg"
                      style={{ height: "180px" }}
                    />
                    <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                      CREATE A NEW STAFF ACCOUNT
                    </span>
                    <small>You will input everything</small>
                  </NavLink>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal2}
          toggle={this.openInvite}
          className={this.props.className}
        >
          <ModalHeader toggle={this.openInvite}>
            Send an invite link
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <Form onSubmit={this.submit}>
              <FormGroup>
                <Label for="exampleText">First Name</Label>
                <Input
                  type="text"
                  name="fname"
                  required={true}
                  onChange={this.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Last Name</Label>
                <Input
                  type="text"
                  name="lname"
                  required={true}
                  onChange={this.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Role</Label>
                <Input
                  type="text"
                  name="role"
                  required={true}
                  onChange={this.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Email</Label>
                <Input
                  type="email"
                  name="email"
                  required={true}
                  onChange={this.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <button type="submit" className="btn btn-block btn-info">
                Send
              </button>
            </Form>
          </ModalBody>
        </Modal>
        <CreateStaffModal
          modalStaff={this.state.modalStaff}
          toggleStaff={this.toggleStaff}
          errorPassword={this.state.errorPassword}
          submitStaff={this.submitStaff}
          handleInputChange={this.handleInputChange}
        />
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={this.resetForm}
          confirmBtnBsStyle="info"
        >
          {/* {!this.state.bday && (
            <div>
              Submitted Successfully Invitation is Sent to {this.state.email}
            </div>
          )}
          {this.state.bday && <div>Staff Created Successfully</div>} */}
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR",
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    readingSubTypes,
    pmRecords,
    staff,
    usersOfferedAccess,
    myRules,
  } = careprovider;

  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    readingSubTypes,
    pmRecords,
    staff,
    usersOfferedAccess,
    myRules,
  };
};

export default connect(mapStateToProps)(StaffClient);
