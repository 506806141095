import classnames from "classnames";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import RequiredSelect from "./RequiredSelect";

class VitalsDetails extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      body: null,
      showInputCondition: false,
      showRangeInput: false,
      showAlertSuccess: false
    };
    this.baseState = this.state.body;
    this.handleChange = this.handleChange.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleCondition = this.handleCondition.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }
  resetForm() {}
  handleChange = (selectedOption) => {
    this.setState({ readingSubType: selectedOption });
  
    this.setState({
      body: {
        ...this.state.body,
        readingType: selectedOption.reading,
        readingSubType: selectedOption.name,
        readingSubTypeId: selectedOption._id
      }
    });
  };
  submit(e) {
    e.preventDefault();

    if (
      this.state.body.notifyReadingOwner.length > 0 &&
      this.state.body.message.trim().length === 0
    ) {
      toast.error(
        "A notification is checked in notify reading owner, please provide a message for the Patient"
      );
    } else if ((this.state.body.notifyStaff.length > 0 ||
        this.state.body.notifySelf.length > 0) && this.state.body.messageCareprovider.trim().length === 0) {
      toast.error(
        "A notification is checked in notify me/staff, please provide a message for the care prodiver/Staff"
      );
    } else {
      this.props.dispatch({
        type: "DO_SAVE_VITAL_RULE",
        payload: this.state.body
      });
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_CAREPROVIDER_EXPECTATIONS",
          payload: { careproviderId: this.props.profile._id }
        });
      }, 500);
    }
  }

  handleCondition = (selectedOption) => {
    this.setState({ condition: selectedOption });
    if (selectedOption.value == "between") {
      this.setState({
        body: {
          ...this.state.body,
          condition: selectedOption.value,
          valueProperty: [{ minValue: "", maxValue: "" }]
        },
        condition: selectedOption,
        showInputCondition: true
      });
    } else {
      this.setState({
        body: {
          ...this.state.body,
          condition: selectedOption.value,
          valueProperty: [{ value: "" }]
        },
        condition: selectedOption,
        showInputCondition: true
      });
    }
    if (selectedOption.value == "between") {
      this.setState({
        showRangeInput: true
      });
    } else {
      this.setState({
        showRangeInput: false
      });
    }
  };
  handleConditions = (selectedOption) => {
    
    this.setState({
      body: {
        ...this.state.body,
        conditionGroup: _.map(selectedOption, "_id")
      }
    });
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;

    const name = target.name;
    const notifySelf = this.state.body.notifySelf;
    const notifyReadingOwner = this.state.body.notifyReadingOwner;
    const notifyStaff = this.state.body.notifyStaff;
    if (target.type === "checkbox") {
      let index;
      if (target.checked) {
        // add the numerical value of the checkbox to options array
        if (name === "notifySelf") {
          notifySelf.push(event.target.value);
        } else if (name === "notifyStaff") {
          notifyStaff.push(event.target.value);
        } else {
          notifyReadingOwner.push(event.target.value);
        }
      } else {
        // or remove the value from the unchecked checkbox from the array
        if (name === "notifySelf") {
          index = notifySelf.indexOf(event.target.value);
          notifySelf.splice(index, 1);
        } else if (name === "notifyStaff") {
          index = notifyStaff.indexOf(event.target.value);
          notifyStaff.splice(index, 1);
        } else {
          index = notifyReadingOwner.indexOf(event.target.value);
          notifyReadingOwner.splice(index, 1);
        }
      }
      this.setState({
        body: {
          ...this.state.body,
          notifySelf: notifySelf,
          notifyReadingOwner: notifyReadingOwner,
          notifyStaff: notifyStaff
        }
      });
      return;
    }

    if (name == "maxValue" || name == "minValue" || name == "value") {
      this.setState({
        body: {
          ...this.state.body,
          valueProperty: { ...this.state.body.valueProperty, [name]: value }
        }
      });
      return;
    }
    this.setState({ body: { ...this.state.body, [name]: value } });
  }

  handleUsers = (selectedOption) => {
    this.setState({ sharedUsers: selectedOption });
    // const receivedFrom = _.map(selectedOption, "receivedFrom");
    this.setState({
      body: {
        ...this.state.body,
        sharedUsers: _.map(selectedOption, "_id")
      }
    });

    
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  componentDidMount() {
    if (!this.props.isCareProvider) {
      this.setState({
        body: {
          ...this.state.body,
          sharedUsers: [this.props.userId]
        }
      });
    }
    this.setState({ body: this.props.ruleDetails }, () => {
      if (this.props.ruleDetails.condition === "between") {
        this.setState({
          body: {
            ...this.state.body,
            valueProperty: {
              minValue: this.props.ruleDetails.valueProperty[0]
                ? this.props.ruleDetails.valueProperty[0].minValue
                : "",
              maxValue: this.props.ruleDetails.valueProperty[0]
                ? this.props.ruleDetails.valueProperty[0].maxValue
                : ""
            }
          }
        });
      } else {
        this.setState({
          body: {
            ...this.state.body,
            valueProperty: {
              value: this.props.ruleDetails.valueProperty[0]
                ? this.props.ruleDetails.valueProperty[0].value
                : ""
            }
          }
        });
      }
    });

    this.setState({
      showInputCondition: true
    });
    if (this.props.ruleDetails.condition === "between")
      this.setState({
        showRangeInput: true
      });
  }

  render() {
    const valueReading = this.props.ruleDetails.valueProperty;
    const getUsers = this.props.ruleDetails.sharedUsers;
    const defaultUsers = _.filter(
      this.props.assignedUsersList,
      (item) => getUsers.indexOf(item._id) > -1
    );
    const conditionGroup = this.props.ruleDetails.conditionGroup;
    const defaultConditionGroup = _.filter(
      this.props.conditions,
      (item) => conditionGroup.indexOf(item._id) > -1
    );
    return (
      <div>
        {this.state.body && (
          <Form onSubmit={this.submit}>
            <Card>
              <CardHeader
              tag="h4"
                className="justify-content-center"
                style={{ background: "white" }}
              >
                <span className="text-center">
                  {this.props.ruleDetails.name}
                </span>
                <>
                  {" "}
                  <button
                    style={{ float: 'right' }}
                    onClick={this.props.isDelete}
                    type="button"
                    className="btn btn-info ml-2 close-click-cls" >
                    <i class="mdi mdi-close" style={{
                      fontSize: 20
                    }}></i>
                    Delete
                  </button>
                  <button
                    name="submit"
                    style={{ float: 'right' }}
                    className="btn btn-info ml-2 print-click-cls" >
                    <i class="mdi mdi-content-save" style={{
                      fontSize: 20,
                      marginRight: 5
                    }}></i>
                    Update
                  </button>
                </>
              </CardHeader>
            </Card>
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body project-tab pt-2">
                    <Nav tabs className="justify-content-center">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1"
                          })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          GENERAL
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2"
                          })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          NOTIFICATIONS
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "3"
                          })}
                          onClick={() => {
                            this.toggle("3");
                          }}
                        >
                          PATIENTS
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12" className="grid-margin mt-5">
                            <FormGroup>
                              <Label for="exampleEmail">Expectation Name</Label>
                              <Input
                                type="text"
                                name="name"
                                id="exampleEmail"
                                defaultValue={this.state.body.name}
                                required={true}
                                onChange={this.handleInputChange}
                                placeholder="Expectation Name"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="exampleSelect">
                                Pick a Reading Type
                              </Label>
                              <RequiredSelect
                                SelectComponent={Select}
                                // value={this.state.sharedUsers}
                                defaultValue={_.filter(
                                  this.props.readingSubTypesForExpecation,
                                  (item) => {
                                    return (
                                      this.props.ruleDetails.readingSubTypeId.indexOf(
                                        item._id
                                      ) > -1
                                    );
                                  }
                                )}
                                onChange={this.handleChange}
                                getOptionLabel={(option) =>
                                  option["name"] + " (" + option["abbrev"] + ")"
                                }
                                getOptionValue={(option) => option["_id"]}
                                options={this.props.readingSubTypesForExpecation}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="exampleSelect">Analyze By</Label>
                              <Select
                                required={true}
                                onChange={this.handleCondition}
                                value={[
                                  { value: "between", label: "In Range" },
                                  { value: ">=", label: "Greater Than" },
                                  { value: "<=", label: "Less Than" }
                                ].filter(
                                  ({ value }) =>
                                    value === this.state.body.condition
                                )}
                                options={[
                                  { value: "between", label: "In Range" },
                                  { value: ">=", label: "Greater Than" },
                                  { value: "<=", label: "Lesser Than" }
                                ]}
                              />
                            </FormGroup>
                            {this.state.showRangeInput && (
                              <Row form>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="exampleEmail">Min Value</Label>
                                    <Input
                                      required
                                      type="text"
                                      name="minValue"
                                      defaultValue={
                                        this.state.body.valueProperty
                                          ? this.state.body.valueProperty
                                              .minValue
                                          : ""
                                      }
                                      onChange={this.handleInputChange}
                                      placeholder="Input Reading Min Value"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="examplePassword">
                                      Max Value
                                    </Label>
                                    <Input
                                      required
                                      type="text"
                                      name="maxValue"
                                      defaultValue={
                                        this.state.body.valueProperty
                                          ? this.state.body.valueProperty
                                              .maxValue
                                          : ""
                                      }
                                      onChange={this.handleInputChange}
                                      placeholder="Input Reading Max Value"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                            {!this.state.showRangeInput && (
                              <FormGroup>
                                <Label for="exampleText">Value</Label>
                                <Input
                                  required
                                  type="text"
                                  name="value"
                                  defaultValue={
                                    this.state.body.valueProperty
                                      ? this.state.body.valueProperty.value
                                      : ""
                                  }
                                  onChange={this.handleInputChange}
                                  placeholder="Input Reading Max Value"
                                />
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12" className="grid-margin mt-5">
                            <FormGroup>
                              <Label for="exampleText">
                                Notification Message (Patient)
                              </Label>
                              <Input
                                type="textarea"
                                name="message"
                                value={this.state.body.message}
                                onChange={this.handleInputChange}
                                rows={10}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label for="exampleText">
                                Notification Message (Careprovider)
                              </Label>
                              <Input
                                type="textarea"
                                name="messageCareprovider"
                                value={this.state.body.messageCareprovider}
                                onChange={this.handleInputChange}
                                rows={10}
                              />
                            </FormGroup>
                            {this.props.isCareProvider && (
                              <FormGroup>
                                <Label for="exampleCheckbox">Notify Me</Label>
                                <div>
                                  <CustomInput
                                    type="checkbox"
                                    id="exampleCustomInline"
                                    name="notifySelf"
                                    checked={_.includes(
                                      this.state.body.notifySelf,
                                      "inbox"
                                    )}
                                    onChange={this.handleInputChange}
                                    value="inbox"
                                    label="Inbox"
                                    inline
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="exampleCustomInline2"
                                    name="notifySelf"
                                    checked={_.includes(
                                      this.state.body.notifySelf,
                                      "sms"
                                    )}
                                    onChange={this.handleInputChange}
                                    label="SMS"
                                    value="sms"
                                    inline
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="exampleCustomInline3"
                                    name="notifySelf"
                                    checked={_.includes(
                                      this.state.body.notifySelf,
                                      "voice"
                                    )}
                                    onChange={this.handleInputChange}
                                    label="VOICE"
                                    value="voice"
                                    inline
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="exampleCustomInline4"
                                    name="notifySelf"
                                    checked={_.includes(
                                      this.state.body.notifySelf,
                                      "email"
                                    )}
                                    onChange={this.handleInputChange}
                                    label="EMAIL"
                                    value="email"
                                    inline
                                  />
                                </div>
                              </FormGroup>
                            )}

                            <FormGroup>
                              {this.props.isCareProvider && (
                                <Label for="exampleCheckbox">
                                  Notify Readings Owner
                                </Label>
                              )}

                              <div>
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline6"
                                  name="notifyReadingOwner"
                                  onChange={this.handleInputChange}
                                  value="inbox"
                                  checked={_.includes(
                                    this.state.body.notifyReadingOwner,
                                    "inbox"
                                  )}
                                  label="Inbox"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline7"
                                  name="notifyReadingOwner"
                                  onChange={this.handleInputChange}
                                  checked={_.includes(
                                    this.state.body.notifyReadingOwner,
                                    "sms"
                                  )}
                                  label="SMS"
                                  value="sms"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline8"
                                  name="notifyReadingOwner"
                                  onChange={this.handleInputChange}
                                  label="VOICE"
                                  checked={_.includes(
                                    this.state.body.notifyReadingOwner,
                                    "voice"
                                  )}
                                  value="voice"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline9"
                                  name="notifyReadingOwner"
                                  onChange={this.handleInputChange}
                                  label="EMAIL"
                                  value="email"
                                  checked={_.includes(
                                    this.state.body.notifyReadingOwner,
                                    "email"
                                  )}
                                  inline
                                />
                              </div>
                            </FormGroup>
                            <FormGroup>
                              {this.props.isCareProvider && (
                                <Label for="exampleCheckbox">
                                  Notify Staff
                                </Label>
                              )}

                              <div>
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline10"
                                  name="notifyStaff"
                                  onChange={this.handleInputChange}
                                  value="inbox"
                                  checked={_.includes(
                                    this.state.body.notifyStaff,
                                    "inbox"
                                  )}
                                  label="Inbox"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline11"
                                  name="notifyStaff"
                                  onChange={this.handleInputChange}
                                  checked={_.includes(
                                    this.state.body.notifyStaff,
                                    "sms"
                                  )}
                                  label="SMS"
                                  value="sms"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline12"
                                  name="notifyStaff"
                                  onChange={this.handleInputChange}
                                  label="VOICE"
                                  checked={_.includes(
                                    this.state.body.notifyStaff,
                                    "voice"
                                  )}
                                  value="voice"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline13"
                                  name="notifyStaff"
                                  onChange={this.handleInputChange}
                                  label="EMAIL"
                                  value="email"
                                  checked={_.includes(
                                    this.state.body.notifyStaff,
                                    "email"
                                  )}
                                  inline
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12" className="grid-margin mt-5">
                            {this.props.isCareProvider && (
                              <>
                                <FormGroup>
                                  <Label for="exampleSelect">
                                    Select a Condition to Monitor
                                  </Label>
                                  <Select
                                    defaultValue={defaultConditionGroup}
                                    onChange={this.handleConditions}
                                    options={this.props.conditions}
                                    isMulti={true}
                                    getOptionLabel={(option) =>
                                      `${option["condition"]}  (${option["description"]})`
                                    }
                                    getOptionValue={(option) => option["_id"]}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="exampleSelect">
                                    Select a Patient to Monitor
                                  </Label>
                                  <Select
                                    defaultValue={defaultUsers}
                                    onChange={this.handleUsers}
                                    options={this.props.assignedUsersList}
                                    isMulti={true}
                                    getOptionLabel={(option) =>
                                      option["fname"] + " " + option["lname"]
                                    }
                                    getOptionValue={(option) => option}
                                  />
                                </FormGroup>
                              </>
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" />
          </Form>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    readingSubTypes,
    readingSubTypesForExpecation,
    assignedUsersList,
    isGenericSuccess,
    isGenericError,
    conditions,
    genericErrorMessage
  } = careprovider;
  const userId = user.userDetails._id;
  const isCareProvider = user.userDetails.isCareProvider;
  const profile = user.profile;
  return {
    readingSubTypes,
    readingSubTypesForExpecation,
    assignedUsersList,
    userId,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    conditions,
    isCareProvider,
    profile
  };
};

export default connect(mapStateToProps)(VitalsDetails);
