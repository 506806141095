import React, { PureComponent } from "react";
import {
  Col,
  Row
} from "reactstrap";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory from "react-bootstrap-table2-filter";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import ReportConfigration from "./ReportConfigration";

const _ = require("lodash");
class ConfigerReports extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMonthlyreport: false,
      reportList: [],
    };
    this.handleCellEdit = this.handleCellEdit.bind(this);
    this.saveCCMDetails = this.saveCCMDetails.bind(this);
  }
  saveCCMDetails(data){    
    if(data){      
      const patient = this.props.profile._id;      
      const notifyReadingMonthlyReport = {
        emailIds: data.emailIds,
        faxNumbers: data.faxNumbers
      }
      this.props.dispatch({
        type: "DO_SAVE_PROFILE_CONFIGRATION_NOTIFY",
        payload: {
          user: patient,
          notifyReadingMonthlyReport: notifyReadingMonthlyReport
        }
      });
      setTimeout(() => {
        const userId = this.props.profile._id;
        this.props.dispatch({
          type: "DO_DEFAULT_PROFILE_CONFIGRATION",
          payload: {
            user: userId
          }
        });
      }, 1500);
    }    
  }

  componentDidMount() {
    const reportList = [{
      name: "End of the Monthly Report for Reading!",
      status: undefined,
    }];
    this.setState({ reportList: reportList });
    const userId = this.props.profile._id;
    this.props.dispatch({
      type: "DO_DEFAULT_PROFILE_CONFIGRATION",
      payload: {
        user: userId
      }
    });

  }

  // Handle cell edits and send updated data to the backend
  handleCellEdit(newValue, row, column) {
    const userId = this.props.profile._id;
    if (userId) {
      this.props.dispatch({
        type: "DO_SAVE_PROFILE_CONFIGRATION",
        payload: {
          user: userId,
          status: newValue,
          key: row.key.toString(),
        }
      });
      setTimeout(() => {
        const userId = this.props.profile._id;
        this.props.dispatch({
          type: "DO_DEFAULT_PROFILE_CONFIGRATION",
          payload: {
            user: userId
          }
        });
      }, 1500);
    }
  };

  render() {
    return (
      <div
        className="col-12 mx-auto mt-5">
        <Row className="header-device-list">
          <Col sm="8" className="center-label remove-table-padding">
            {!this.props.hideTitle && (
              <h3 className="text-center title-device">
                <i class="mdi mdi-settings"></i> Configuration
              </h3>
            )}
          </Col>
        </Row>
        {this.props.ongoingSaveProfileConfigration && (
          <LottieLoader
            loadLoop={true}
            autoplayLoop={true}
            source={lottieLoaderFile}
            Lheight={150}
            Lwidth={150}
            subTitle={"Please Wait ..."}
          />
        )}
        {!this.props.ongoingSaveProfileConfigration && (
          <>
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="id"
              filterPosition="top"
              data={this.props.defaultProfileConfigration && _.size(this.props.defaultProfileConfigration) > 0 ? this.props.defaultProfileConfigration : []}
              cellEdit={
                cellEditFactory({
                  mode: 'click',
                  blurToSave: false,
                })
              }
              noDataIndication={() => (
                <div>
                  <LottieLoader
                    loadLoop={false}
                    autoplayLoop={true}
                    source={lottieEmptyFile}
                    Lheight={200}
                    Lwidth={200}
                    titleSmall={"Sorry! No Report found :("}
                    subTitleSmall={"We can't find any report."}
                  />
                </div>
              )}
              columns={[
                {
                  dataField: "key",
                  text: "Name of the configuration",
                  headerStyle: (colum, colIndex) => {
                    return {
                      padding: "16px 8px",
                      fontSize: "15px"
                    };
                  },
                  editable: false,
                },
                {
                  dataField: "status",
                  text: "Status",
                  headerStyle: (colum, colIndex) => {
                    return {
                      padding: "16px 8px",
                      fontSize: "15px"
                    };
                  },
                  formatter: (cell, row) => {
                    return <div className="text-center">{this.props.isSentMonthlyReadingReport ? "YES" : "NO"}</div>;
                  },
                  editor: {
                    type: Type.SELECT,
                    options: ['YES', 'NO']
                  },
                  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    return (
                      <select
                        className="form-control input-date-filter remove-bottom-margin"
                        value={this.props.isSentMonthlyReadingReport ? "YES" : "NO"}
                        onChange={(e) => {
                          if (e.target.value !== "Select") {
                            const status = e.target.value == "YES" ? true : false;
                            this.handleCellEdit(status, row, column);
                          } else {
                            this.handleCellEdit(false, row, column);
                          }
                        }}
                      >
                        {column.editor.options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    );
                  },
                  sort: false
                }
              ]}
              filter={filterFactory()}
            />
          </>
        )}
        {!this.props.ongoingSaveProfileConfigration && this.props.isSentMonthlyReadingReport && (
          <ReportConfigration
            isEditable={true}
            notifyReadingMonthlyReport={this.props.notifyReadingMonthlyReport}
            saveCCMDetails={this.saveCCMDetails}
          />
        )}        
      </div>
    );
  }
}
const mapStateToProps = ({ user, careprovider }) => {
  const { authenticated, userDetails, profile } = user;
  const { defaultProfileConfigration, ongoingSaveProfileConfigration, isSentMonthlyReadingReport, notifyReadingMonthlyReport } = careprovider;
  return {
    authenticated,
    userDetails,
    profile,
    defaultProfileConfigration,
    isSentMonthlyReadingReport,
    ongoingSaveProfileConfigration,
    notifyReadingMonthlyReport
  };
};

export default connect(mapStateToProps)(ConfigerReports);
