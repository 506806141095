import moment from "moment";
import React from "react";
import LineGraph from "./LineGraph";
import TableReadingPrint from "./TableReadingPrint";
import "css/css/style.css";
import { Col, Row } from "reactstrap";
import DisplayCardThreshold from "./DisplayCardThreshold";
const _ = require("lodash");
const GraphTableReading = React.forwardRef((props, ref) => {
    // Change date format
    //console.log('in graph table reading',props.image);
    const callingThroughValue = props.callingThroughValue;
    const dateFrom = moment(props.startDate).clone().format("MMMM DD, YYYY");
    const dateTo = moment(props.endDate).clone().format("MMMM DD, YYYY");
    const bDateFrom = moment(dateFrom).clone().format("MMMM DD, YYYY");
    const bDateTo = moment(dateTo).clone().subtract(1, "d").format("MMMM DD, YYYY");
    const printPageStyle = "@page { size: A4 landscape; }";
    return (
    <div ref={ref} className="row align-items-center justify-content-center text-center m-2">
        <style>{printPageStyle}</style>
        <div className="text-center col-md-12 m-4">
          {/* <img
            src={`${process.env.REACT_APP_VIDEO_CALL_REQUEST}/static/media/smarthealth.c3ca62dd.jpg`}            alt="new"
            width="230px"
            /> */}
            <Row className="text-center" style={{ margin: 5, padding: 0 }}>
              <div className="notes-pdf-title">
                <div className="text-left reading-card-for-all-reading float-right">
                  <img
                    src={require(`../../files/Reading/${props.readingType.abbrev}.svg`)}
                    alt="profile-image"
                    className="profile rounded-circle img-fluid reading-icon"
                  /> 
                  <div>
                    <h4 class="reading-card-title">{props.readingType.description}</h4>
                    <h6 class="reading-card-subtitle">{`${props.lname} ${props.fname}`}</h6>
                  </div>
                </div>
              </div>
              <Col className="notes-pdf-details">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-calendar" style={{ color: "#ff5722" }}></i> DOB:</td>
                      <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                      { props.notesPatientDetails && props.notesPatientDetails.bday ? moment(props.notesPatientDetails.bday).format("MMMM DD, YYYY") : " - "}</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-face" style={{ color: "#ff5722" }}></i> Nickname:</td>
                      <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                      { props.notesPatientDetails && props.notesPatientDetails.nickname ? props.notesPatientDetails.nickname : " - "}</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-account" style={{ color: "#ff5722" }}></i> Main Care Provider:</td>
                        <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                        {props.notesPatientDetails && props.notesPatientDetails.mainCareProvider ? 
                        props.notesPatientDetails.mainCareProvider.fname +" " + 
                        props.notesPatientDetails.mainCareProvider.lname : " - "}</td>                        
                      </tr>
                      <tr>
                        <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-phone" style={{ color: "#ff5722" }}></i> Phone No :</td>
                        <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}{props.notesPatientDetails ? props.notesPatientDetails.phone : " - "}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-calendar" style={{ color: "#ff5722" }}></i> Last Reading Date :</td>
                        <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                        { props.latestReadingUser && props.latestReadingUser.deviceReadingDate 
                        ? moment(props.latestReadingUser.deviceReadingDate).format("MMMM DD, YYYY") : "NONE"}</td>
                      </tr>
                  </tbody>
                </table>                        
            </Col>
            <Col className="notes-pdf-details">
                <table>
                  <tbody>
                      <tr>
                        <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-calendar" style={{ color: "#ff5722" }}></i> Start Date :</td>
                        <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                          {moment(props.startDate).format("LL")}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-calendar" style={{ color: "#ff5722" }}></i> End Date :</td>
                        <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}{moment(props.endDate).format("LL")}</td>
                      </tr>                    
                      <tr>
                        <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-clock-outline" style={{ color: "#ff5722" }}></i> Total CCM Time :</td>
                        <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}{props.ccmTotalDuration ?? '00:00:00'}</td>
                      </tr> 
                      <tr>
                        <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-clock-outline" style={{ color: "#ff5722" }}></i> Total RPM Time :</td>
                        <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}{props.rpmTotalDuration ?? '00:00:00'}</td>
                      </tr>  
                  </tbody>
                </table>                        
            </Col>
              </Row>   
                {!callingThroughValue || callingThroughValue != 'logbook' &&
                  <div style={{ width: props.readingThreshold && callingThroughValue == 'graph' ? 850 : 910 }} className="mx-auto mt-4">
                    {props.readingThreshold && callingThroughValue == 'graph' && (
                      <>
                        <div style={{ marginBottom: 5, marginTop: 5 }}>
                          <DisplayCardThreshold
                            isHideBorder={true}
                            leftAline={true}
                            readingThreshold={props.readingThreshold}
                          />
                        </div>
                      </>
                    )}
                    <LineGraph
                      readingDate={props.readingDate}
                      readingDataSet={props.readingDataSet}
                      title={`(${dateFrom} - ${dateTo})`}
                    />
                  </div>
                }
        </div>                
        {!callingThroughValue || callingThroughValue !='graph' &&
        <div className={"text-center col-md-12 " + (props.callingThroughValue == 'graphlogbook' ? 'm-4' : '')}>
            {props.readingThreshold && (
              <>
                <hr style={{marginTop:50}}></hr>
                <DisplayCardThreshold
                  isHideBorder={true}
                  leftAline={true}
                  readingThreshold={props.readingThreshold}
                />
                <hr></hr>
              </>
            )}
            <TableReadingPrint
              ref={ref}
              readingType={props.readingType}
              reading={props.reading}
              subType={props.subType}
              readingValue={props.readingValue}
              unitMeasurement={props.unitMeasurement}
              startDate={bDateFrom}
              endDate={bDateTo}
              date={`(${bDateFrom} - ${bDateTo})`}
              fname={props.fname}
              lname={props.lname}
              printLogoValue = '1'
              printHeaderValue = '1'
              bodyTraceWS={props?.bodyTraceWS ? true : false}
              isBloodPressureReading={props.isBloodPressureReading}
            />
        </div>
        }
    </div>
  );
});

export default GraphTableReading;
