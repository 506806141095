import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useFetch = (url, options, needAuth = true) => {
  const authToken = useSelector(state => state.user.userDetails.token);
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(options);
  const [random, setrandom] = useState(0);
  let requestURL = process.env.REACT_APP_HOST_URL_PREFIX;
  const refetchAgain = () => {
    setrandom(Math.floor(Math.random() * 100 + 1));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(requestURL + url, {
          headers: { "x-auth-token": authToken }
        });
        const json = await res.json();
        setResponse(json);
        setIsLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [random]);

  return { response, error, isLoading, refetchAgain };
};

function formatDuration(duration) {
  // return [
  //   ("0" + Math.floor(duration.asHours())).slice(-2),
  //   ("0" + duration.minutes()).slice(-2),
  //   ("0" + duration.seconds()).slice(-2)
  // ].join(":");
  return duration;
}
function strPad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
export { useFetch, formatDuration };
