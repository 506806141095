import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
  Container
} from "reactstrap";
import PatientList from "./PatientList";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import usersImage from "../../../files/users.png";

import ReactLoading from "react-loading";
const DeleteUserAction = (props) => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [userId, setUserId] = useState(false);
  const [name, setName] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningEnable, setWarningEnable] = useState(false);
  const [warningDisable, setWarningDisable] = useState(false);
  const [surewarning, setSureWarning] = useState(false);
  const dispatch = useDispatch();

  const proceedDisableUser = (name, userId) => {
    setUserId(userId);
    setWarningDisable(false);
    setModal(true);
    setName(name);
    dispatch({
      type: "DO_DISABLE_USER",
      payload: {
        user: userId
      }
    });
    setRefresh(true);
    setTimeout(() => {
      dispatch({
        type: "DO_GET_USERS_YOU_HAVE_ACCESS",
        payload: { client: true }
      });
      setTimeout(() => {
        setRefresh(false);
      }, 1200);
    }, 200);
  };
  const disableUser = (name, userId) => {
    setUserId(userId);
    setName(name);
    setWarningDisable(true);
    setModal(true);
  };
  const enableUser = (name, userId) => {
    setUserId(userId);
    setName(name);
    setWarningEnable(true);
    setModal(true);
  };
  const proceedEnableUser = (name, userId) => {
    setUserId(userId);
    setName(name);
    setWarningEnable(false);
    dispatch({
      type: "DO_ENABLE_USER",
      payload: {
        user: userId
      }
    });
    setRefresh(true);
    setTimeout(() => {
      dispatch({
        type: "DO_GET_USERS_YOU_HAVE_ACCESS",
        payload: { client: true }
      });
      setTimeout(() => {
        setRefresh(false);
      }, 1200);
    }, 200);
  };
  const deleteUser = (name, userId) => {
    setUserId(userId);
    setName(name);
    setWarning(true);
  };
  const proceedWarningDelete = (name, userId) => {
    setWarning(false);
    setSureWarning(true);
    setModal(true);
  };
  const proceedDelete = (name, userId) => {
    setWarning(false);
    setSureWarning(false);
    dispatch({
      type: "DO_DELETE_USER",
      payload: {
        user: userId
      }
    });
    setRefresh(true);
    setTimeout(() => {
      dispatch({
        type: "DO_GET_USERS_YOU_HAVE_ACCESS",
        payload: { client: true }
      });
      setTimeout(() => {
        setRefresh(false);
      }, 1200);
    }, 200);
  };

  return (
    <>
      <Col
        md="3"
        className="text-center px-1 py-1"
        onClick={() => setModal(!modal)}
      >
        <Card body className="px-1">
          <CardText>
            <img
              src={usersImage}
              width="140"
              height="140"
              border="0"
              className="img-fluid"
            />
            <div className="text-center">DELETE/DEACTIVATE USER</div>
          </CardText>
        </Card>
      </Col>
      <Modal isOpen={modal} size="lg">
        <ModalHeader toggle={() => setModal(!modal)}>
          Delete Patient
        </ModalHeader>
        <ModalBody className="bg-white">
          {/* {refresh && (
            <ReactLoading
              type="spin"
              className="text-center mx-auto"
              color="blue"
            />
          )}
          {!refresh && (
            <PatientList
              deleteUser={deleteUser}
              disableUser={disableUser}
              enableUser={enableUser}
              usersGivenAccessMyReading={props.users}
              readingSubTypes={props.readingSubTypes}
              userId={props.userId}
              deleteAction={true}
            />
          )} */}
          <h1 className="text-center">
            This part has been moved to Accounts Management Section
          </h1>
        </ModalBody>
      </Modal>
      <SweetAlert
        warning
        showCancel
        show={warning}
        confirmBtnText="Yes, delete the user now!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => proceedWarningDelete(name, userId)}
        onCancel={() => setWarning(false)}
      >
        This will delete or wipeout all data related to the user {name}
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        show={surewarning}
        confirmBtnText="Proceed!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you very sure?"
        onConfirm={() => proceedDelete(name, userId)}
        onCancel={() => {
          setWarning(false);
          setSureWarning(false);
        }}
      >
        This will really delete or wipeout all data related to the user {name}
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        show={warningDisable}
        confirmBtnText="Proceed!"
        confirmBtnBsStyle="warning"
        cancelBtnBsStyle="default"
        title={`Are you sure to disable ${name}?`}
        onConfirm={() => proceedDisableUser(name, userId)}
        onCancel={() => {
          setWarningDisable(false);
        }}
      >
        Disabling or Deactiviting Patient will immediately pause its schedule
        expectations and unable to do things like login and other stuff
      </SweetAlert>
      <SweetAlert
        warning
        showCancel
        show={warningEnable}
        confirmBtnText="Enable!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={`Are you sure to enable back ${name}?`}
        onConfirm={() => proceedEnableUser(name, userId)}
        onCancel={() => {
          setWarningEnable(false);
        }}
      >
        Enable or Activiting Patient will immmediately resume its schedule
        expectations and able to do things like login and other stuff.
      </SweetAlert>
    </>
  );
};

export default DeleteUserAction;
