import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import { filter } from "lodash";
import SharedDetails from "./SharedDetails";
import familyAccount from "../../files/ic_family_account.svg";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  CustomInput
} from "reactstrap";
const _ = require("lodash");
const { SearchBar } = Search;
function fullname(cell, row) {
  return (
    <div className={row.user != undefined && row.user.isActive ? "" : " text-danger"}>
      <span>
        <img src={row.user && row.user.profileImage ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + row.user.profileImage : familyAccount} className="mr-2" alt="image" />
        {capitalize(row.lname)} {capitalize(row.fname)}
      </span>
    </div>
  );
}
function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}
function isApproved(cell, row) {
  return (
    <span>
      {cell ? (
        <label className="badge shared-account-created">ACCOUNT CREATED</label>
      ) : (
        <label className="badge badge-warning">PENDING INVITATION</label>
      )}
    </span>
  );
}
function formatDate(cell, row) {
  return moment(row.receivedBy.bday).format("MMMM DD, YYYY");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      {!row.isCreated && (        
        <Button
          className="account-delete-click"
          onClick={() => {
            formatExtraData.delete(row._id)
          }}><i className="mdi mdi-delete"></i> Delete
        </Button>
      )}
    </div>
  );
}
const rowComponent = (
  row,
  users,
  expectations,
  userInputChange,
  expectationInputChange
) => (
  <SharedDetails
    row={row}
    users={users}
    userInputChange={userInputChange}
    expectationInputChange={expectationInputChange}
    expectations={expectations}
  />
);
const MySearch = props => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <div>
      <div class="form-group">
        <input
          type="text"
          class="form-control input-date-filter remove-bottom-margin"
          id="exampleInputName1Test"
          style={{ fontSize: "20px" }}
          placeholder="Search Name, Nickname, Email, etc..."
          ref={n => (input = n)}
          onChange={handleClick}
        />
      </div>
    </div>
  );
};
const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Row>
      <Col>
        <button className="btn btn-info ml-2 download-click-cls float-left" onClick={handleClick}>
        <i class="mdi mdi-download" style={{
                fontSize: 20
        }}></i> Export to CSV
        </button>
      </Col>
    </Row>
  );
};
const SharedList = (props, context) => (
  <ToolkitProvider
    keyField="_id"    
    data={props.sharedAccount}
    columns={[
      {
        dataField: "fname",
        text: "Name",
        formatter: fullname,
        sort: true,
        csvFormatter: (cell, row, rowIndex) => {
          return row.user ? ""+row.lname +" " + row.fname : "-";
        },
        filterValue: (cell, row) => {
          return row.fname + row.lname + row.organization;
        },
        headerFormatter: priceFormatter,
      },
      {
        dataField: "user.nickname",
        text: "Nickname",
        sort: true,
        csvFormatter: (cell, row, rowIndex) => {
          return (row.user ? (row.user.nickname ? row.user.nickname : "-") : "-");
        },
        headerStyle: (colum, colIndex) => {
          return { width: '280px', textAlign: 'center' };
        },
        formatter: (cell, row) => {
          return (row.user ? (row.user.nickname ? row.user.nickname : <div className="text-center">-</div>) : <div className="text-center">-</div>);
        },
        filterValue: (cell, row) => {
          return (row.user ? (row.user.nickname ? row.user.nickname : "-") : "-");
        },
        headerFormatter: priceFormatter,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,        
        headerFormatter: priceFormatter,
      },
      {
        dataField: "role",
        text: "Role",
        sort: true,
        formatter: capitalize,
        headerFormatter: priceFormatter,
      },

      {
        dataField: "isCreated",
        text: "Status",
        formatter: isApproved,
        headerStyle: (colum, colIndex) => {
          return { width: '100px', textAlign: 'center' };
        },
        csvExport: false,
        headerFormatter: priceFormatter,
      },
      {
        dataField: "action",
        text: "Action",
        formatter: showButtons,
        headerFormatter: priceFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '100px', textAlign: 'center' };
        },
        csvExport: false,
        formatExtraData: {
          delete: props.deleteInvitation,
        },
      },
    ]}
    exportCSV={{ fileName: `SharedFamilyAccounts.csv` }}
    search>
    {
      propsParam => (
        <div>
          <Row className={`text-center col-md-12 mx-auto float-right`}>
            <Col md={10}>
              <MySearch {...propsParam.searchProps} />
            </Col>
            <Col md={2}>
              <MyExportCSV {...propsParam.csvProps}>
                Export CSV
              </MyExportCSV>
            </Col>
          </Row>
          <BootstrapTable
            wrapperClasses="table-responsive"
            noDataIndication={() => {
              return (
                <div>
                  {props.loading && (
                    <LottieLoader
                      loadLoop={true}
                      autoplayLoop={true}
                      source={lottieLoaderFile}
                      Lheight={150}
                      Lwidth={150}
                      subTitle={"Please Wait ..."}
                    />
                  )}
                  {!props.loading && (
                    <LottieLoader
                      loadLoop={false}
                      autoplayLoop={true}
                      source={lottieEmptyFile}
                      Lheight={300}
                      Lwidth={300}
                      title={"Sorry! No shared/family account found :("}
                      subTitle={"We can't find any shared/family account."}
                    />
                  )}
                </div>)
            }}
            expandRow={{
              renderer: (row) =>
                rowComponent(
                  row,
                  filter(props.usersOfferedAccess, { isActive: true }),
                  props.expectations,
                  props.userInputChange,
                  props.expectationInputChange
                ),
              onlyOneExpanding: true,
              showExpandColumn: true,
            }}
            filter={filterFactory()}
            filterPosition="top"
            {...propsParam.baseProps}
          />
        </div>

      )
    }
  </ToolkitProvider>
);

export default SharedList;
