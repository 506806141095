import React from "react";
import userImage from "../../files/user.png";

const FullNameWithAvatar = (props) => {
  const { isActive, fullName } = props;
  return (
    <div className={isActive ? "" : " text-danger"}>
      <img src={userImage} className="mr-2" alt="image" />
      <br />
      <span>{fullName}</span>
    </div>
  );
};

export default FullNameWithAvatar;
