import classnames from "classnames";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import RequiredSelect from "./RequiredSelect";

class TrendingViolationDetails extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch({
      type: "DO_GET_USERS_LIST"
    });

    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      myRules: "",
      countTime: 1,
      timezone: this.props.timezone,
      timezoneList: this.props.timezoneList,
      body: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTimezone = this.handleTimezone.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  handleTimezone = (selectedOption) => {
    this.setState({
      body: {
        ...this.state.body,
        timezone: selectedOption.value
      },
      timezone: selectedOption
    });

    
  };
  handleChange = (selectedOption) => {
    this.setState({ rule: selectedOption });
  
    this.setState({
      body: {
        ...this.state.body,
        rule: { ruleId: selectedOption._id, ruleType: selectedOption.ruleType }
      }
    });
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  submit(e) {
    e.preventDefault();

    if (
      this.state.body.notifyReadingOwner.length > 0 &&
      this.state.body.message.trim().length === 0
    ) {
      toast.error(
        "A notification is checked in notify reading owner, please provide a message for the Patient"
      );
    } else if ((this.state.body.notifyStaff.length > 0 ||
        this.state.body.notifySelf.length > 0) && this.state.body.messageCareprovider.trim().length === 0) {
      toast.error(
        "A notification is checked in notify me/staff, please provide a message for the care prodiver/Staff"
      );
    } else {
      this.props.dispatch({
        type: "DO_SAVE_TRENDING_VIOLATION_RULE",
        payload: this.state.body
      });
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_MY_RULES"
        });
        this.props.dispatch({
          type: "DO_GET_RULES_SET_BY_OTHERS"
        });
      }, 500);
    }
  }
  handleCondition = (selectedOption) => {
    this.setState({ condition: selectedOption });
    this.setState({
      body: {
        ...this.state.body,
        repeatPer: selectedOption.value
      }
    });
  };
  handleConditions = (selectedOption) => {
    
    this.setState({
      body: {
        ...this.state.body,
        conditionGroup: _.map(selectedOption, "_id")
      }
    });
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;

    const name = target.name;
    const notifySelf = this.state.body.notifySelf;
    const notifyReadingOwner = this.state.body.notifyReadingOwner;
    const notifyStaff = this.state.body.notifyStaff;
    if (target.type === "checkbox") {
      let index;
      if (target.checked) {
        // add the numerical value of the checkbox to options array
        if (name === "notifySelf") {
          notifySelf.push(event.target.value);
        } else if (name === "notifyStaff") {
          notifyStaff.push(event.target.value);
        } else {
          notifyReadingOwner.push(event.target.value);
        }
      } else {
        // or remove the value from the unchecked checkbox from the array
        if (name === "notifySelf") {
          index = notifySelf.indexOf(event.target.value);
          notifySelf.splice(index, 1);
        } else if (name === "notifyStaff") {
          index = notifyStaff.indexOf(event.target.value);
          notifyStaff.splice(index, 1);
        } else {
          index = notifyReadingOwner.indexOf(event.target.value);
          notifyReadingOwner.splice(index, 1);
        }
      }
      this.setState({
        body: {
          ...this.state.body,
          notifySelf: notifySelf,
          notifyReadingOwner: notifyReadingOwner,
          notifyStaff: notifyStaff
        }
      });
      return;
    }

    if (name == "maxValue" || name == "minValue" || name == "value") {
      this.setState({
        body: {
          ...this.state.body,
          valueProperty: { ...this.state.body.valueProperty, [name]: value }
        }
      });
      return;
    }
    this.setState({ body: { ...this.state.body, [name]: value } });
  }

  handleUsers = (selectedOption) => {
    this.setState({ sharedUsers: selectedOption });
    this.setState({
      body: {
        ...this.state.body,
        sharedUsers: _.map(selectedOption, "_id")
      }
    });

    
  };

  componentDidMount() {
    if (!this.props.isCareProvider) {
      this.setState({
        body: {
          ...this.state.body,
          sharedUsers: [this.props.userId]
        }
      });
    }
    this.setState({ body: this.props.ruleDetails });
  }

  render() {
    const getRule = _.find(this.props.myRules, {
      _id: this.props.ruleDetails.rule[0].ruleId
    });
    const getUsers = this.props.ruleDetails.sharedUsers;
    const defaultUsers = _.filter(
      this.props.assignedUsersList,
      (item) => getUsers.indexOf(item._id) > -1
    );
    const conditionGroup = this.props.ruleDetails.conditionGroup;
    const defaultConditionGroup = _.filter(
      this.props.conditions,
      (item) => conditionGroup.indexOf(item._id) > -1
    );

    const onlyVitals = _.filter(this.props.myRules, { ruleType: "Vitals" });
    return (
      <div>
        <Form onSubmit={this.submit}>
          <Card>
            <CardHeader
              tag="h4"
              className="justify-content-center"
              style={{ background: "white" }}
            >
              <span className="text-center">
                {this.props.ruleDetails.name}
              </span>

              {this.props.isCareProvider && (
                <>
                  {" "}
                  <button
                    style={{ float: 'right' }}
                    onClick={this.props.isDelete}
                    type="button"
                    className="btn btn-info ml-2 close-click-cls" >
                    <i class="mdi mdi-close" style={{
                      fontSize: 20
                    }}></i>
                    Delete
                  </button>
                  <button
                    name="submit"
                    style={{ float: 'right' }}
                    className="btn btn-info ml-2 print-click-cls" >
                    <i class="mdi mdi-content-save" style={{
                      fontSize: 20,
                      marginRight: 5
                    }}></i>
                    Update
                  </button>
                </>
              )}
            </CardHeader>
          </Card>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body project-tab pt-2" style={{paddingBottom:0}}>
                  <Nav tabs className="justify-content-center">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1"
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        GENERAL
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2"
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        NOTIFICATIONS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3"
                        })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        PATIENTS
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12" className="grid-margin mt-5">
                          <FormGroup>
                            <Label for="exampleSelect">Select a Timezone</Label>
                            <RequiredSelect
                              SelectComponent={Select}
                              value={this.state.timezone}
                              onChange={this.handleTimezone}
                              options={this.props.timezoneList}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="exampleEmail">Expectation Name</Label>
                            <Input
                              className="input-date-filter"
                              type="text"
                              name="name"
                              id="exampleEmail"
                              defaultValue={this.state.body.name}
                              required={true}
                              onChange={this.handleInputChange}
                              placeholder="Expectation Name"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="exampleSelect">Pick a Rule</Label>
                        <Select
                          required={true}
                          defaultValue={getRule}
                          onChange={this.handleChange}
                          getOptionLabel={(option) => {
                            if(option["valueProperty"] && option["valueProperty"][0] && option["valueProperty"][0]["minValue"]){
                              return (
                                option["name"] +
                                " (" +
                                option["condition"] +
                                " " +
                                option["valueProperty"][0]["minValue"] +
                                " - " +
                                option["valueProperty"][0]["maxValue"] +
                                ")"
                              );
                            }
                            if (
                              option["valueProperty"] &&
                              option["valueProperty"].length > 0
                            ) {
                              return (
                                option["name"] +
                                " (" +
                                option["condition"] +
                                " " +
                                option["valueProperty"][0]["value"] +
                                ")"
                              );
                            }
                            return (
                              option["name"] +
                              " (No Condition and/or Value Assigned)"
                            );
                          }}
                          getOptionValue={(option) => option["_id"]}
                          options={onlyVitals}
                        />
                      </FormGroup>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="exampleEmail">
                              Number of Times Violation Triggered
                            </Label>
                            <Input
                              className="input-date-filter"
                              type="number"
                              name="numberOfTimes"
                              defaultValue={this.state.body.numberOfTimes}
                              onChange={this.handleInputChange}
                              placeholder="Number of Times"
                              onWheel={(e) => e.target.blur()}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup>
                            <Label for="exampleSelect">Every</Label>
                            <Select
                              required={true}
                              value={[
                                { value: "hour", label: "Hour" },
                                { value: "day", label: "Day" },
                                { value: "week", label: "Week" },
                                { value: "month", label: "Month" }
                              ].filter(
                                ({ value }) =>
                                  value === this.state.body.repeatPer
                              )}
                              onChange={this.handleCondition}
                              options={[
                                { value: "hour", label: "Hour" },
                                { value: "day", label: "Day" },
                                { value: "week", label: "Week" },
                                { value: "month", label: "Month" }
                              ]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12" className="grid-margin mt-5">
                          <FormGroup>
                            <Label for="exampleText">
                              Notification Message (Patient)
                            </Label>
                            <Input
                              className="input-date-filter"
                              type="textarea"
                              name="message"
                              value={this.state.body.message}
                              onChange={this.handleInputChange}
                              rows={10}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="exampleText">
                              Notification Message (Careprovider)
                            </Label>
                            <Input
                              className="input-date-filter"
                              type="textarea"
                              name="messageCareprovider"
                              value={this.state.body.messageCareprovider}
                              onChange={this.handleInputChange}
                              rows={10}
                            />
                          </FormGroup>
                          {this.props.isCareProvider && (
                            <FormGroup>
                              <Label for="exampleCheckbox">Notify Me</Label>
                              <div>
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline"
                                  name="notifySelf"
                                  checked={_.includes(
                                    this.state.body.notifySelf,
                                    "inbox"
                                  )}
                                  onChange={this.handleInputChange}
                                  value="inbox"
                                  label="INBOX"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline2"
                                  name="notifySelf"
                                  checked={_.includes(
                                    this.state.body.notifySelf,
                                    "sms"
                                  )}
                                  onChange={this.handleInputChange}
                                  label="SMS"
                                  value="sms"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline3"
                                  name="notifySelf"
                                  checked={_.includes(
                                    this.state.body.notifySelf,
                                    "voice"
                                  )}
                                  onChange={this.handleInputChange}
                                  label="VOICE"
                                  value="voice"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline4"
                                  name="notifySelf"
                                  checked={_.includes(
                                    this.state.body.notifySelf,
                                    "email"
                                  )}
                                  onChange={this.handleInputChange}
                                  label="EMAIL"
                                  value="email"
                                  inline
                                />
                              </div>
                            </FormGroup>
                          )}

                          <FormGroup>
                            {this.props.isCareProvider && (
                              <Label for="exampleCheckbox">
                                Notify Readings Owner
                              </Label>
                            )}

                            <div>
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline6"
                                name="notifyReadingOwner"
                                onChange={this.handleInputChange}
                                value="inbox"
                                checked={_.includes(
                                  this.state.body.notifyReadingOwner,
                                  "inbox"
                                )}
                                label="INBOX"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline7"
                                name="notifyReadingOwner"
                                onChange={this.handleInputChange}
                                checked={_.includes(
                                  this.state.body.notifyReadingOwner,
                                  "sms"
                                )}
                                label="SMS"
                                value="sms"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline8"
                                name="notifyReadingOwner"
                                onChange={this.handleInputChange}
                                label="VOICE"
                                checked={_.includes(
                                  this.state.body.notifyReadingOwner,
                                  "voice"
                                )}
                                value="voice"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline9"
                                name="notifyReadingOwner"
                                onChange={this.handleInputChange}
                                label="EMAIL"
                                value="email"
                                checked={_.includes(
                                  this.state.body.notifyReadingOwner,
                                  "email"
                                )}
                                inline
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            {this.props.isCareProvider && (
                              <Label for="exampleCheckbox">Notify Staff</Label>
                            )}

                            <div>
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline10"
                                name="notifyStaff"
                                onChange={this.handleInputChange}
                                value="inbox"
                                checked={_.includes(
                                  this.state.body.notifyStaff,
                                  "inbox"
                                )}
                                label="INBOX"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline11"
                                name="notifyStaff"
                                onChange={this.handleInputChange}
                                checked={_.includes(
                                  this.state.body.notifyStaff,
                                  "sms"
                                )}
                                label="SMS"
                                value="sms"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline12"
                                name="notifyStaff"
                                onChange={this.handleInputChange}
                                label="VOICE"
                                checked={_.includes(
                                  this.state.body.notifyStaff,
                                  "voice"
                                )}
                                value="voice"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline13"
                                name="notifyStaff"
                                onChange={this.handleInputChange}
                                label="EMAIL"
                                value="email"
                                checked={_.includes(
                                  this.state.body.notifyStaff,
                                  "email"
                                )}
                                inline
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12" className="grid-margin mt-5">
                          {this.props.isCareProvider && (
                            <>
                              <FormGroup>
                                <Label for="exampleSelect">
                                  Select a Condition to Monitor
                                </Label>
                                <Select
                                  defaultValue={defaultConditionGroup}
                                  onChange={this.handleConditions}
                                  options={this.props.conditions}
                                  isMulti={true}
                                  getOptionLabel={(option) =>
                                    `${option["condition"]}  (${option["description"]})`
                                  }
                                  getOptionValue={(option) => option["_id"]}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label for="exampleSelect">
                                  Select a Patient to Monitor
                                </Label>
                                <Select
                                  defaultValue={defaultUsers}
                                  onChange={this.handleUsers}
                                  options={this.props.assignedUsersList}
                                  isMulti={true}
                                  getOptionLabel={(option) =>
                                    option["fname"] + " " + option["lname"]
                                  }
                                  getOptionValue={(option) => option}
                                />
                              </FormGroup>
                            </>
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
          <div className="row" />
        </Form>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    readingSubTypes,
    assignedUsersList,
    isGenericSuccess,
    isGenericError,
    myRules,
    conditions,
    genericErrorMessage
  } = careprovider;
  const userId = user.userDetails._id;
  const isCareProvider = user.userDetails.isCareProvider;
  return {
    readingSubTypes,
    assignedUsersList,
    userId,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myRules,
    conditions,
    isCareProvider
  };
};

export default connect(mapStateToProps)(TrendingViolationDetails);
