import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col
} from "reactstrap";

import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { useFetch2 } from "components/utils/authenticatedHook";
import { primaryInsuranceProviders } from "../../../constants/general.constant";
const CreateUserModal = props => {
  const [emailAvailable, setEmailAvailable] = useState({
    validate: true,
    message: ""
  });
  const [usernameAvailable, setUsernameAvailable] = useState({
    validate: true,
    message: ""
  });
  const [prescribeTestContainer, setprescribeTestContainer] = useState(false);
  const [{ response, isLoading }] = useFetch2({
    url: "/get-careprovider-conditions/" + props.careproviderId,
    method: "GET"
  });
  const conditions = response ? response.data : response;
  const toggle = () => setprescribeTestContainer(!prescribeTestContainer);
  return (
    <Modal
      isOpen={props.modalUser}
      toggle={props.toggleUser}
      backdrop="static"
      size="lg"
      className="bastaLang modal-md"
    >
      <ModalHeader toggle={props.toggleUser}>
        Create a Patient Account
      </ModalHeader>

      <ModalBody style={{ backgroundColor: "white" }}>
        <Form onSubmit={props.submitUser}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleSelect">
                  Select the Condition of the Patient (Can be Multiple)
                </Label>
                <Select
                  SelectComponent={Select}
                  onChange={props.handleInputChangeCondition}
                  options={conditions != undefined ? conditions : []}
                  isMulti={true}
                  getOptionLabel={option =>
                    `${option["condition"]}  (${option["description"]})`
                  }
                  getOptionValue={option => option["_id"]}
                  isLoading={isLoading}
                />
              </FormGroup>

              <FormGroup>
                <Label for="exampleText">
                  MemberID (Reference ID OF CLIENT'S DB)
                </Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="memberId"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Email</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  required={true}
                  type="email"
                  name="email"
                  onChange={props.handleInputChange}
                  onBlur={e => {
                    axios
                      .get("/check-availability?email=" + e.target.value)
                      .then(function(response) {
                        setEmailAvailable({ validate: true, message: "" });
                      })
                      .catch(function(error) {
                        const { response = {} } = error;
                        const { data = {} } = response;
                        const { message = "" } = data;

                        setEmailAvailable({
                          validate: false,
                          message: message
                        });
                      });
                  }}
                  invalid={!emailAvailable.validate}
                  rows={10}
                />
                <FormFeedback>{emailAvailable.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Username*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="username"
                  required={true}
                  onBlur={e => {
                    axios
                      .get("/check-availability?username=" + e.target.value)
                      .then(function(response) {
                        // handle success
                        setUsernameAvailable({ validate: true, message: "" });
                      })
                      .catch(function(error) {
                        const { response = {} } = error;
                        const { data = {} } = response;
                        const { message = "" } = data;

                        setUsernameAvailable({
                          validate: false,
                          message: message
                        });
                      });
                  }}
                  invalid={!usernameAvailable.validate}
                  onChange={props.handleInputChange}
                  rows={10}
                />
                <FormFeedback>{usernameAvailable.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Password*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="password"
                  name="password"
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Confirm Password*</Label>
                <Input
                  invalid={props.errorPassword}
                  type="password"
                  className="form-control form-control-lg input-date-filter remove-bottom-margin"
                  placeholder="Confirm Password"
                  name="confirmpassword"
                  onChange={props.handleInputChange}
                  rows={10}
                />

                {props.errorPassword && (
                  <FormFeedback>Password does not match</FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="exampleText">First Name*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="fname"
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Last Name*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="lname"
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Nickname</Label>
                <Input
                  type="text"
                  name="nickname"
                  required={false}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Phone*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="phone"
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">DOB*</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="date"
                  name="bday"
                  max={moment().format("YYYY-MM-DD")}
                  required={true}
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="exampleText">SubOrganization</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="subOrganization"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Home Number</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="homeNumber"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Work Number</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="workNumber"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Address</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="address"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>

              <FormGroup>
                <Label for="exampleText">City</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="city"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">State</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="state"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Zip Code</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="zip"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Height (ft)</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="height"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Weight (lb)</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="weight"
                  onChange={props.handleInputChange}
                  rows={10}
                />
              </FormGroup>

              <div className="form-group">
                <label>Gender</label>
                <select
                  name="gender"
                  className="form-control"
                  defaultValue="Male"
                  onChange={props.handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="form-group">
                <label>Primary Insurance Provider</label>
                <select
                  name="insuranceProvider"
                  className="form-control"
                  defaultValue=""
                  onChange={props.handleInputChange}
                >
                {primaryInsuranceProviders && primaryInsuranceProviders.length > 0 &&
                  primaryInsuranceProviders.map((item) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))
                }
                </select>
              </div>
            </Col>
          </Row>
          <button type="submit" className="btn btn-block btn-info">
            SAVE
          </button>
        </Form>
        {/* <div id="accordion">
          <div className="card">
            <div className="card-header" id="headingOne">
              <h5 className="mb-0">
                <Button color="link" onClick={toggle}>
                  Test Rate (Maintenance)
                </Button>
              </h5>
            </div>
            <Collapse isOpen={prescribeTestContainer}>
              <div className="card-body">...</div>
            </Collapse>
          </div>
        </div> */}
      </ModalBody>
    </Modal>
  );
};

export default CreateUserModal;
