import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PropTypes from "prop-types";
import Link from "components/ExtendLink";
import React from 'react';
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from "reactstrap";
import callingIcon from "../../../files/called.png";
import userImage from "../../../files/user.png";
import LottieLoader from '../../LottieLoader';
import lottieEmptyFile from '../../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../files/animation/loading.json';
const _ = require("lodash");

function fullname(cell, row) {
  const profile = row.profileImage ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + row.profileImage : userImage;
  // return <UserName user={row} />;
  return (
    <div className={row.isActive ? "" : " text-danger"}>
      <span>
        <img src={profile} className="mr-2" alt="image"   
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = userImage;
        }}/>
        {capitalize(row.lname)}
        {", "}
        {capitalize(row.fname)}

        {row?.ccmTrackings?.length > 0 && <img src={callingIcon} />}
      </span>
    </div>
  );
}

function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}

function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}

const ImprovedPatientList = (props, context) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: props.displayNeedToFollowUpList ? true : parseInt(props.totalSize) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.totalSize) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "200",
        value: 200
      }
    ]
  };
  const columns = [
    {
      dataField: "lname",
      text: "Last, First Name",
      formatter: fullname,
      csvFormatter: (cell, row) => {
        return (
          row.lname +
          ", " +
          row.fname +
          ` (${row.isActive ? "ACTIVE" : "INACTIVE"})`
        );
      },
      sort: true,
      // filterValue: (cell, row) => {
      //   return row.lname + ", " + row.fname;
      // },
      headerFormatter: priceFormatter
      // hidden: props.displayCellularConnectivityList ? true : false,
      // csvExport: props.displayCellularConnectivityList ? false : true
    },
    {
      dataField: "nickname",
      text: "Nickname",
      formatter: (cell, row) => {
        return (row.nickname? row.nickname :"-");
      },
      csvFormatter: (cell, row) => {
        return (row.nickname ? row.nickname : "-");
      },
      sort: true,
      headerFormatter: priceFormatter
    },
    {
      dataField: "bday",
      text: "DOB",
      formatter: (cell, row) => {
        return moment(row.bday).format("MMMM DD, YYYY");
      },
      csvFormatter: (cell, row) => {
        return moment(row.bday).format("MMMM DD, YYYY");
      },
      sort: true,
      onSort: (field, order) => {
      }
      //hidden: props.displayCellularConnectivityList ? true : false,
      //csvExport: props.displayCellularConnectivityList ? false : true
    },
    {
      dataField: "createdAt",
      text: "Enrolled Date",
      sort: true,
      formatter: (cell, row) => {
        return moment(row.createdAt).tz(moment.tz.guess()).format("MMMM DD, YYYY");
      },
      headerFormatter: priceFormatter,
      csvFormatter: (cell, row, rowIndex) => {
        return moment(row.createdAt).tz(moment.tz.guess()).format("MMMM DD, YYYY");
      },
      //hidden: props.displayCellularConnectivityList ? true : false,
      //csvExport: props.displayCellularConnectivityList ? false : true
    },
    {
      dataField: "deviceReadingDate",
      text:
        props.displayActiveList === true || props.displayActiveList === null
          ? "Last Upload Date"
          : "Deactivated Date",
      sort: false,
      formatter: (cell, row) => {
        if (
          props.displayActiveList === true ||
          props.displayActiveList === null
        ) {
          if (row.deviceReadingDate && row.deviceReadingDate) {
            return moment(row.deviceReadingDate).tz(moment.tz.guess()).format(
              "MMMM DD, YYYY"
            );
          }
          return "NONE";
        } else if (props.displayActiveList === false) {
          return row.inActiveAt ? 
          moment(row.inActiveAt).tz(moment.tz.guess()).format("MMMM DD, YYYY") : 
          moment(row.updatedAt).tz(moment.tz.guess()).format("MMMM DD, YYYY");
        }
      },
      csvFormatter: (cell, row) => {
        if (
          props.displayActiveList === true ||
          props.displayActiveList === null
        ) {
          if (row.lastReading && row.lastReading.deviceReadingDate) {
            return moment(row.lastReading.deviceReadingDate).format(
              "MMMM DD, YYYY"
            );
          }
          return "NONE";
        } else if (props.displayActiveList === false) {
          return moment(row.updatedAt).format("MMMM DD, YYYY");
        }
      },
      //hidden: props.displayCellularConnectivityList ? true : false,
      //csvExport: props.displayCellularConnectivityList ? false : true
    },
    {
      dataField: props.displayFollowUpReminderList || props.displayReminderSentList  ? "lastNoteReminderDate" : "lastNoteDate",
      text: props.displayItIssuesList ? "Date of Last IT Issue Note" : (props.displayFollowUpReminderList) ?  'Follow Up Reminder Date' : props.displayReminderSentList ? 'Follow Up Reminder Sent Date'  :"Date of Last Note" ,
      sort: true,    
      order: 'asc', 
      headerStyle: (colum, colIndex) => {
        return { width: '160px', textAlign: 'left' };
      },
      headerFormatter: priceFormatter,
      formatter: (cell, row) => {
        if(props.displayFollowUpReminderList || props.displayReminderSentList){
          if (row.lastNoteReminderDate) {
            return row.lastNoteReminderDate ? (
              <div>
                <span>{moment(row.lastNoteReminderDate).format("MMMM DD, YYYY  hh:mm A")}</span>
                <span style={{fontWeight:800}}><br></br><br></br>{row.lastNoteSubject ? row.lastNoteSubject : <div className='text-center'>-</div>}</span>
              </div>
            ) : <div className='text-center'>-</div>;
          } else{
            return <div className="text-center">-</div>
          }
        }else{
          if (row.lastNoteDate) {
            return row.lastNoteDate ? (
              <div>
                <span>{moment(row.lastNoteDate).format("MMMM DD, YYYY hh:mm A")}</span>
                <span style={{fontWeight:800}}><br></br><br></br>{row.lastNoteSubject ? row.lastNoteSubject : <div className='text-center'>-</div>}</span>
              </div>
            ) : <div className='text-center'>-</div>;
          } else{
            return <div className="text-center">-</div>
          }
        }
      },
      hidden: props.displayNeedToFollowUpList || props.displayHospitalAddmissionList || props.displayItIssuesList || props.displayFollowUpReminderList || props.displayReminderSentList ? false : true,
      csvExport: props.displayNeedToFollowUpList || props.displayHospitalAddmissionList || props.displayItIssuesList || props.displayFollowUpReminderList || props.displayReminderSentList ? false : true
    },
    {
      dataField: "organization",
      text: "Organization",
      sort: true,
      headerFormatter: priceFormatter,
      formatter: (cell, row) => {
        if (row.organization) {
          return row.organization;
        }else{
          return <div className="text-center">-</div>
        }
      },
      //hidden: props.displayCellularConnectivityList ? true : false,
      //csvExport: props.displayCellularConnectivityList ? false : true
    },
    {
      dataField: "mainCareProviders",
      text: "Main Care Provider",
      sort: true,      
      headerFormatter: priceFormatter,
      formatter: (cell, row) => {
        if (row.mainCareProviders) {
          return row.mainCareProviders;
        }else{
          return <div className="text-center">-</div>
        }
      },
      //hidden: props.displayCellularConnectivityList ? true : false,
      //csvExport: props.displayCellularConnectivityList ? false : true
    },
    {
      dataField: "enrolledPrograms",
      text: "Enrolled Program(s)",
      sort: true,
      formatter: (cell, row) => {
        if (row.enrolledPrograms) {
          return <div className="text-center">{row.enrolledPrograms}</div>;
        }else{
          return <div className="text-center">-</div>
        }
      }, 
      // hidden: props.displayCellularConnectivityList ? true : false,
      // csvExport: props.displayCellularConnectivityList ? false : true
    },
    {
      dataField: "expectationsToFollow",
      text: "Client Expectations to Follow",
      formatter: (cell, row) => {
        return row.expectationsToFollow ? row.expectationsToFollow : '-';
      },
      csvFormatter: (cell, row) => {
        return row.expectationsToFollow ? row.expectationsToFollow : '-';
      },
      sort: true,
      headerFormatter: priceFormatter
    },
    {
      dataField: "totalCCMTrackingsTime",
      text: "Total CCM Time",
      formatter: (cell, row) => {
        return row.ccmTotalTimes ? row.ccmTotalTimes : "";
      },
      csvFormatter: (cell, row) => {
        return row.ccmTotalTimes ? row.ccmTotalTimes : "";
      },
      sort: true,
      hidden: props.displayCellularConnectivityList ? true : false,
      csvExport: props.displayCellularConnectivityList ? false : true
    },

    {
      dataField: "rpmTotalTimes",
      text: "Total RPM Time",
      formatter: (cell, row) => {
        return row.rpmTotalTimes ? row.rpmTotalTimes : "";
      },
      csvFormatter: (cell, row) => {
        return row.rpmTotalTimes ? row.rpmTotalTimes : "";
      },
      sort: true,
      hidden: props.displayCellularConnectivityList ? true : false,
      csvExport: props.displayCellularConnectivityList ? false : true
    },
    {
      dataField: "nonAdhenrenceDay",
      text: "Non Adherence Days",
      formatter: (cell, row) => {
        return row.nonAdhenrenceDay ? row.nonAdhenrenceDay : 0;
      },
      sort: false,
      headerFormatter: priceFormatter
    },

    {
      dataField: "weekAssigned",
      text: "Designated Week",
      formatter: (cell, row) => {
        if(row.weekAssigned){
          return <div className='text-center'>{ row.weekAssigned }</div>;
        }else{
          return <div className='text-center'>-</div>;
        }
      },
      csvFormatter: (cell, row) => {
        return row.weekAssigned ? row.weekAssigned : "";
      },
      sort: true,
      hidden: props.displayCellularConnectivityList ? true : false,
      csvExport: props.displayCellularConnectivityList ? false : true
    },

    {
      dataField: "bodytraceBPIMEI",
      text: "BP IMEI",
      sort: true,
      headerFormatter: priceFormatter,
      hidden: props.displayCellularConnectivityList ? false : true,
      csvExport: props.displayCellularConnectivityList ? true : false
    },
    {
      dataField: "bodytraceWSIMEI",
      text: "WS IMEI",
      sort: true,
      headerFormatter: priceFormatter,
      hidden: props.displayCellularConnectivityList ? false : true,
      csvExport: props.displayCellularConnectivityList ? true : false
    },
    {
      dataField: "called",
      text: "Called",
      sort: false,
      formatter: (cell, row, rowIndex, extra) => {
        return (
          <div className="text-center">
            {row.called && (
              <img src={callingIcon} />
            )}
            {!row.called && (
              <div style={{textAlign:"center"}}>-</div>
            )}
          </div>
        );
      },
      formatExtraData: props,
      csvFormatter: (cell, row) => {
        return row.called ? true : false;
      }
    }
  ];
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No patient found :("}
          subTitle={"We can't find any patient."}
        />
      )}              
    </div>
  );
  return (
    <Row className="col-sm-12 main-cls-modal remove-table-padding">
      <Col sm="12" className="mt-12">
        <BootstrapTable
          remote={{ 
            sort: false,
            pagination:true,
            filter:true,
            search:true
           } 
          }
          keyField="_id"
          loading={props.loading}
          data={props.data}
          columns={columns}
          pagination={paginationFactory(options)}
          onTableChange={props.onTableChange}
          noDataIndication={ () => <NoDataIndication /> }
          hover={props.totalSize > 0 ? true : false}
          condensed
          bordered
          wrapperClasses="table-responsive"
          expandRow={{
            renderer: (row) => 
              rowComponent(row, props, props.profileOwner),
            onlyOneExpanding: false,
            showExpandColumn: true,
            expandByColumnOnly: false
          }}
        />
      </Col>
    </Row>
  );
};
const rowComponent = (row, props, profile) => {
  const readingSubTypes = props?.readingSubTypes ? props.readingSubTypes : [];
  if(props.displayReminderSentList || props.displayFollowUpReminderList){
      let readingsHeader = "No reminders notes for this patient";
      if(_.size(row.notes)> 0){
        readingsHeader = 'Follow Up Reminder History'
      }
      return(
        <div>
          <Row style={{ margin: "15px 0px" }}>
            <Col sm="12" className="mt-2 remove-summary-paddding">
              <Card style={{ border: "0px 10px 10px 0px" }}>
              <CardHeader className="text-center summary-title summary-header">
              {capitalize(row?.fname || '')} {capitalize(row?.lname || '')} - {readingsHeader}
              <Link
              to={`/dashboard/${row.fname
                .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row._id
                }`}
              className="btn pull-right more-btn "
            >
              <i class="mdi mdi-account-card-details"></i> More Details
            </Link>
              </CardHeader>
               {row && row.notes && row.notes.length > 0 && (
                <CardBody className='padding-card-body'>
                <BootstrapTable
                  keyField='_id'
                  data={row.notes}
                  columns={[
                    {
                      dataField: 'senderData',
                      text: 'Created By',
                      formatter: (cell, row) => {
                        const user = cell[0];
                        const profile = user.profileImage || userImage;
                        return(
                          <div className={user.isActive ? "" : " text-danger"}>
                          <p>
                            <img src={profile} className="mr-2" alt="image"   
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = userImage;
                            }}/>
                            {capitalize(user.lname)}
                            {", "}
                            {capitalize(user.fname)}
                          </p>
                        </div>
                        )
                      },
                      sort: false
                    },
                    {
                      dataField: 'reminderNoteData',
                      text: 'Notify On',
                      formatter: (cell, row) => {
                        if(cell && cell.notifyMe && cell.notifyMe.length){
                          const dataString = cell.notifyMe.join(', ');
                          return(
                            <div >
                             <p>{dataString}</p>
                          </div>
                          )
                        }else{
                          return (<div> - </div>)
                        }
                      },
                      sort: false
                    },
                    {
                      dataField: 'reminderNoteData',
                      text: 'Reminder Sent To',
                      formatter: (cell, row) => {
                        if(cell && cell.reminderSent && cell.status == 'Sent'){
                          let email = null;
                          let sms = null;
                          if(cell.notifyMe.includes('Email')){
                            email = cell.reminderSent &&  cell.reminderSent.recepientEmail || ''
                          }if(cell.notifyMe.includes('Sms')){
                            sms = cell.reminderSent &&  cell.reminderSent.recepientPhone || ''
                          }
                          const returnValue = []
                          if(sms){
                            returnValue.push(<p>{sms}</p>)
                          }
                          if(email){
                            returnValue.push(<p>{email}</p>)
                          }
                          if(cell.notifyMe.includes('Inbox')){
                            returnValue.push(<p>Inbox</p>)
                          }
                          return returnValue
                        }else{
                          return (<div> - </div>)
                        }
                      },
                      sort: false,
                      hidden: props.displayReminderSentList ? false : true
                    },
                    {
                      dataField: 'reminderNoteData',
                      text: props.displayReminderSentList ? 'Reminder Sent Date' : 'Reminder Date',
                      formatter: (cell, row) => {
                        if(cell && cell.reminderDate){
                          if(cell?.reminderSentDate){
                            const dataString = cell.reminderSentDate;
                            return(
                              <div >
                               <p>{moment(dataString).format("MMMM DD, YYYY hh:mm A")}</p>
                            </div>
                            )
                          }else{
                            const dataString = cell.reminderDate;
                            return(
                              <div >
                               <p>{moment(dataString).format("MMMM DD, YYYY hh:mm A")}</p>
                            </div>
                            )
                          }
                        }else{
                          return (<div> "-" </div>)
                        }
                      },
                      sort: false
                    },
                    {
                      dataField: 'body',
                      text: 'Message',
                      formatter: (cell, row) => {
                        if(cell){
                          const dataString = cell
                          return(
                            <div >
                             <p>{dataString}</p>
                          </div>
                          )
                        }else{
                          return (<div> "-" </div>)
                        }
                      },
                      sort: false
                    },
                    {
                      dataField: 'reminderNoteData',
                      text: 'Status',
                      formatter: (cell, row) => {
                        if(cell && cell.status){
                          const dataString = cell.status == 'Reminder' ? 'Reminder Pending' : cell.status == 'Completed' ? 'Follow Up Completed' : cell.status;
                          let condition;
                          if(cell.status == 'Reminder'){
                          condition = <span class="badge bg-danger" style={{'fontSize' : '90%', 'color' : 'white'}}>Reminder Pending</span>
                          }else if(cell.status == 'Completed'){
                            condition = <span class="badge bg-info" style={{'fontSize' : '90%', 'color' : 'white'}}>Follow Up Completed</span>
                            }else{
                              condition = <span class="badge bg-success" style={{'fontSize' : '90%', 'color' : 'white'}}>{cell.status}</span>
                              }
                          return(
                            <div>
                             {condition}
                          </div>
                          )
                        }else{
                          return (<div> "-" </div>)
                        }
                      },
                      sort: false
                    }
                 ]}
                />
              </CardBody>
               )}
              </Card>
            </Col>
          </Row>
      </div>
      )
    }else{
    let readingsHeader = "No readings in the Last 30 Days";
    if (_.size(row.readingsSevenDays) > 0 || _.size(row.bodyTraceReadings) > 0) {
      readingsHeader = "Last 30 Days Summary";
    }
    const readingSubType = readingSubTypes.filter((item) => item.description !== "Weight Difference");
    return (
      <div>
        <Card className="remove-card-shadow remove-card-radius">
          <CardHeader className="text-center summary-title summary-header">
            <div>
              {(_.size(row.readingsSevenDays) == 0 && _.size(row.bodyTraceReadings) == 0) && (
                <>
                  <div className="summary-div-center">
                    <LottieLoader
                      loadLoop={false}
                      autoplayLoop={true}
                      source={lottieEmptyFile}
                      Lheight={200}
                      Lwidth={200}
                      titleSmall={"Sorry! No last 30 days summary found :("}
                      subTitleSmall={"We can't find any reading's for last 30 days."}
                    />
                    <Link
                      to={`/dashboard/${row.fname
                        .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                        .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row._id
                        }`}
                      className="btn pull-center more-btn"
                      style={{ marginTop: 10 }}
                    >
                      <i class="mdi mdi-account-card-details"></i> More Details
                    </Link>
                  </div>
                </>
              )}
              {(_.size(row.readingsSevenDays) > 0 || _.size(row.bodyTraceReadings) > 0) && (
                <>
                {capitalize(row?.fname || '')} {capitalize(row?.lname || '')} - {readingsHeader}
                  <Link
                    to={`/dashboard/${row.fname
                      .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                      .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row._id
                      }`}
                    className="btn pull-right more-btn "
                  >
                    <i class="mdi mdi-account-card-details"></i> More Details
                  </Link>
                </>
              )}
            </div>
          </CardHeader>
        </Card>
        <Row style={{ margin: "15px 0px" }}>
          {readingSubType.map((value, i) => {
            // this added code for heart rate take from puls ox filter(HeartRateFromPulsOx)
            if (row.isBloodPressureReading && value && value._id == '5be56cbe3863cd0647d79896') {
              return;
            }
            // finished puls ox filter code(HeartRateFromPulsOx)
            var result = _.includes(value.abbrev, "BT_");
            var readings = undefined;
            var readingValue = undefined
            var checkThis = undefined
            if (result) {
              readings = _.filter(row.bodyTraceReadings, (reading) => {
                if (_.includes(value.abbrev, "WS") && reading.values.unit == 0) {
                  return false;
                } else if (value.name.toLowerCase() in reading.values) {
                  return true;
                }
                return false;
              });
              readingValue = _.map(readings, "values");
              checkThis = _.map(readingValue, (readingIndex, i) => {
                return _.get(readingValue[i], value.name.toLowerCase());
              });
  
            } else {
              readings = _.filter(row.readingsSevenDays, {
                readingType: value.reading
              });
              readingValue = _.map(readings, "value");
              checkThis = _.map(readingValue, (readingIndex) => {
                return readingIndex[value.index];
              });
            }
  
            if (value.name === "Mean Arterial") {
              checkThis = _.map(readingValue, (readingIndex) => {
                return _.round(
                  (1 / 3) * readingIndex[0] + (2 / 3) * readingIndex[1],
                  2
                );
              });
            }
            var checkNan = isNaN(_.min(checkThis));
            if (readings.length === 0 || checkNan) return;
            var latestReading = _.first(_.orderBy(readings, ['deviceReadingDate'], ['desc']));
            var getTheValueLatest;
            if (_.has(latestReading, "value")) {
              getTheValueLatest = _.pick(latestReading, "value");
            } else {
              getTheValueLatest = _.pick(latestReading, "values");
            }
            var latestResult
            if (_.has(latestReading, "value")) {
              latestResult = getTheValueLatest.value[value.index];
              if (value.name === "Mean Arterial") {
                latestResult = Math.round(
                  _.round(
                    (1 / 3) * getTheValueLatest.value[0] +
                    (2 / 3) * getTheValueLatest.value[1],
                    2
                  )
                );
              }
            } else {
              latestResult = _.get(getTheValueLatest.values, value.name.toLowerCase());
              if (value.name === "Mean Arterial") {
                latestResult = Math.round(
                  _.round(
                    (1 / 3) * _.get(getTheValueLatest.values, "systolic") +
                    (2 / 3) * _.get(getTheValueLatest.values, "diastolic"),
                    2
                  )
                );
              }
            }
            var lastSevenDays = _.size(readings);
            var lastTestTaken =
              readings.length > 0 &&
              moment(latestReading.deviceReadingDate).format("MM/DD/YY"); // hh:mm A
  
            var matchingExpectationsBreached = _.filter(
              row.expectations,
              function (o) {
                return (
                  o &&
                  o && o.readingSubType.toLowerCase() == _.replace(_.replace(value.name, "Pulse", "Heart Rate").toLowerCase(), "bpm", "Heart Rate").toLowerCase() &&
                  !o.name.toLowerCase().includes("Event".toLowerCase())
                );
              }
            );
            // Checked all of the readings to see which had a low threshold.
            var lowExpectation = _.flatMap(_(matchingExpectationsBreached)
              .filter((o) => o && o.condition == "<=" || o.condition == "between")
              .flatMap((_value, i) => _.pick(_value, "valueProperty"))
              .value(), "valueProperty");
  
            var highExpectation = _.flatMap(_(matchingExpectationsBreached)
              .filter((o) => o && o.condition == ">=" || o.condition == "between")
              .flatMap((_value, i) => _.pick(_value, "valueProperty"))
              .value(), "valueProperty");
  
            var lowMinThreshold = _.maxBy(_.map(lowExpectation, i => i.minValue || i.value), function (o) { return o && parseInt(o) });
            var highMaxThreshold = _.minBy(_.map(highExpectation, i => i.maxValue || i.value), function (o) { return o && parseInt(o) });
  
            var lowThreshold = _.filter(checkThis, (reading, i) => {
              if (lowMinThreshold != undefined) {
                return lowMinThreshold != undefined ? reading <= lowMinThreshold : reading <= lowMinThreshold;
              } else {
                return false;
              }
            });
            // Checked all of the readings to see which had a high threshold.
            var highThreshold = _.filter(checkThis, (reading, i) => {
              if (highMaxThreshold != undefined) {
                return highMaxThreshold != undefined ? reading >= highMaxThreshold : reading >= highMaxThreshold;
              } else {
                return false;
              }
            });
  
            var countLowThreshold = Math.round(
              (_.size(lowThreshold) / lastSevenDays) * 100
            );
            var countHighThreshold = Math.round(
              (_.size(highThreshold) / lastSevenDays) * 100
            );
            var goodThreshold = Math.round(
              100 -
              (parseFloat(countLowThreshold) + parseFloat(countHighThreshold))
            );
            var sevenDaysLowest =
              value.name != "Body Temperature"
                ? Math.round(_.min(checkThis))
                : _.min(checkThis).toFixed(2);
            var sevenDaysHighest =
              value.name != "Body Temperature"
                ? Math.round(_.max(checkThis))
                : _.max(checkThis).toFixed(2);
            var sevenDaysAverage =
              value.name != "Body Temperature"
                ? Math.round(_.mean(checkThis))
                : _.mean(checkThis).toFixed(2);
            if (value.name == "Body Temperature") {
              latestResult = ((latestResult * 9) / 5 + 32).toFixed(2);
              sevenDaysLowest = ((sevenDaysLowest * 9) / 5 + 32).toFixed(2);
              sevenDaysHighest = ((sevenDaysHighest * 9) / 5 + 32).toFixed(2);
              sevenDaysAverage = ((sevenDaysAverage * 9) / 5 + 32).toFixed(2);
            }
            if (value.name == "Weight") {
              if(value.abbrev == "BT_WS"){
                latestResult = Math.round(latestResult);
                sevenDaysLowest = Math.round(sevenDaysLowest);
                sevenDaysHighest = Math.round(sevenDaysHighest);
                sevenDaysAverage = Math.round(sevenDaysAverage);
              }else{
                latestResult = Math.round(latestResult * 2.2046);
                sevenDaysLowest = Math.round(sevenDaysLowest * 2.2046);
                sevenDaysHighest = Math.round(sevenDaysHighest * 2.2046);
                sevenDaysAverage = Math.round(sevenDaysAverage * 2.2046);
              }
            } else {
              latestResult = Math.round(latestResult);
            }
            var readingName = _.replace(value.name, "Pulse", "Heart Rate");
            return (
              <Col sm="4" className="mt-2 remove-summary-paddding">
                <Card style={{ border: "0px 10px 10px 0px" }}>
                  <CardHeader className="text-white" style={{ padding: "3px 0px" }}>
                    <div class="col-12">
                      <div>
                        <div className="text-left reading-card-for-all-reading">
                          <img
                            src={require(`../../../files/Reading/${value.abbrev}.svg`)}
                            alt="profile-image"
                            className="profile rounded-circle img-fluid mr-2 reading-icon-summary"
                          /> <div>
                            <h4 class="reading-card-title" style={{ color: "black", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "700" }}>{readingName}</h4>
                            <h6 class="reading-card-subtitle" style={{ color: "#787878", fontSize: 14, fontFamily: "ubuntu-regular", fontWeight: "500" }}>{`${value.description}`}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className='padding-card-body'>
                    <Row className="mb-2">
                      <Col sm="8">No. of Test taken in last 30 days:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {lastSevenDays || 0}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">Last Test Taken:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {lastTestTaken}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">Last Test Result:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {latestResult}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">30 days lowest:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {sevenDaysLowest || 0}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">30 days highest:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {sevenDaysHighest || 0}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">30 days average:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {sevenDaysAverage || 0}
                      </Col>
                    </Row>
                    <>
                      {matchingExpectationsBreached == undefined || matchingExpectationsBreached.length == 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% High:</Col>
                          <Col
                            sm="4"
                            className="font-weight-bold"
                            style={{ color: "red" }}
                          >
                            No care plan
                          </Col>
                        </Row>
                      )}
                      {matchingExpectationsBreached !== undefined && matchingExpectationsBreached.length > 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% High:</Col>
                          <Col sm="4" className="font-weight-bold">
                            {countHighThreshold || 0}%
                          </Col>
                        </Row>
                      )}
                      {matchingExpectationsBreached == undefined || matchingExpectationsBreached.length == 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% Low:</Col>
                          <Col
                            sm="4"
                            className="font-weight-bold"
                            style={{ color: "red" }}
                          >
                            No care plan
                          </Col>
                        </Row>
                      )}
                      {matchingExpectationsBreached !== undefined && matchingExpectationsBreached.length > 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% Low</Col>
                          <Col sm="4" className="font-weight-bold">
                            {countLowThreshold || 0}%
                          </Col>
                        </Row>
                      )}
                      {matchingExpectationsBreached == undefined || matchingExpectationsBreached.length == 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% Good(InRange):</Col>
                          <Col
                            sm="4"
                            className="font-weight-bold"
                            style={{ color: "red" }}
                          >
                            No care plan
                          </Col>
                        </Row>
                      )}
                      {matchingExpectationsBreached !== undefined && matchingExpectationsBreached.length > 0 && (
                        <Row className="mb-2">
                          <Col sm="8">% Good(InRange)</Col>
                          <Col sm="4" className="font-weight-bold">
                            {goodThreshold || 0}%
                          </Col>
                        </Row>
                      )}
                    </>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
};
ImprovedPatientList.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
export default ImprovedPatientList;