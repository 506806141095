import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import { Row, Col, Spinner } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../../src/files/animation/empty_new.json';
import lottieLoaderFile from '../../../src/files/animation/loading.json';
import callingIcon from "../../../src/files/called.png";
const _ = require("lodash");

function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}

function formatDuration(duration) {
  return [
    ("0" + Math.floor(duration.asHours())).slice(-2),
    ("0" + duration.minutes()).slice(-2),
    ("0" + duration.seconds()).slice(-2)
  ].join(":");
}

const List = (props, context) => {
  const { selectedMonth, selectedYear } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  function fullname(cell, row) {
    if (moment(row.joinedDate).month() + 1 == selectedMonth && moment(row.joinedDate).year() == selectedYear) {
      //new user
      return (
        <div>
          {row.fullName} <label class="badge badge-gradient-info">NEW</label>
        </div>
      );
    }
    return row.fullName;
  }
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: props.currentMonthReport == 0 ? true : false, // Hide the sizePerPage dropdown always
    showTotal: props.currentMonthReport == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "25",
        value: 25
      },
      {
        text: "50",
        value: 50
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "All",
        value: props.currentMonthReport.length
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Row>
        <Col>
        <button 
          style={{ marginTop: 1,width:164, height:43 }} 
          className="btn btn-info ml-2 download-click-cls float-right"
          onClick={handleClick}>
            <i class="mdi mdi-download" style={{
                fontSize: 20
              }}></i> Export to CSV
          </button>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (
      (selectedMonth && selectedYear) ||
      (props.currentMonthReport, selectedMonth, selectedYear)
    ) {
      setShowSpinner(!showSpinner);
    }
  }, [props.currentMonthReport, selectedMonth, selectedYear]);
  const { SearchBar, ClearSearchButton } = Search;
  const MySearch = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };
    return (
      <div>
        <div class="form-group">
          <input
            type="text"
            class="form-control input-date-filter remove-bottom-margin"
            id="exampleInputName1Test"
            style={{ fontSize: "20px" }}
            placeholder="Search..."
            ref={(n) => (input = n)}
            onChange={handleClick}
          />
        </div>
      </div>
    );
  };
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No patient found :("}
          subTitle={"We can't find any patient."}
        />
      )}              
    </div>
  );
  return (
    <div className="col-sm-12">
      <ToolkitProvider
        keyField="_id"
        data={_.orderBy(props.currentMonthReport, "fullName", "asc")}
        columns={[
          {
            dataField: "fullName",
            text: "Last, First Name",
            sort: true,
            searchable: true,
            formatter: fullname,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              if (moment(row.joinedDate).month() + 1 == selectedMonth && moment(row.joinedDate).year() == selectedYear) {
                return row.fullName + " (NEW)";
              }
              return row.fullName;
            }
          },
          {
            dataField: "nickname",
            text: "Nickname",
            sort: true,
            searchable:true,
            formatter: (cell, row) => {
              return (row.nickname? row.nickname :"-");
            },
            csvFormatter: (cell, row) => {
              return (row.nickname? row.nickname :"-");
            },
            headerFormatter: priceFormatter,          
          },
          {
            dataField: "bday",
            text: "DOB",
            sort: true,
            searchable: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.bday;
            }
          },
          {
            dataField: "joinedDate",
            text: "Enrolled Date",
            sort: true,
            searchable: true,
            formatter: (cell, row) => {
              return row.joinedDate;
            },
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.joinedDate;
            }
          },
          {
            dataField: "lastReadingDate",
            text: "Last Upload Date",
            sort: true,
            searchable: true,            
            headerFormatter: priceFormatter,
            formatter: (cell, row, rowIndex) => {
              return row.lastReadingDate ? moment(row.lastReadingDate).format(
                "MMMM DD, YYYY"
              ) : "NONE";
            },
            csvFormatter: (cell, row, rowIndex) => {
              return row.lastReadingDate ? moment(row.lastReadingDate).format(
                "MMMM DD, YYYY"
              ) : "NONE";
            }
          },{
            dataField: "mainCareProvider",
            text: "Main Care Provider",
            sort: true,
            searchable: true,
            formatter: (cell, row) => {
              if (row.mainCareProvider) {
                return (
                  row.mainCareProvider.fname + " " + row.mainCareProvider.lname
                );
              }
              return "Not Assigned";
            },
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              if (row.mainCareProvider) {
                return (
                  row.mainCareProvider.fname + " " + row.mainCareProvider.lname
                );
              }
              return "Not Assigned";
            }
          },
          {
            dataField: "organization",
            text: "Organization",
            sort: true,
            searchable: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.organization;
            }
          },
          {
            dataField: "firstReading",
            text: "First Upload This Month",
            sort: true,
            searchable: true,
            formatter: (cell, row) => {
              return row.firstReading ? row.firstReading : "NONE";
            },
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.firstReading ? row.firstReading : "NONE";
            }
          },
          {
            dataField: "lastReading",
            text: "Last Upload This Month",
            sort: true,
            searchable: true,
            headerFormatter: priceFormatter,
            formatter: (cell, row) => {
              return row.lastReading ? row.lastReading : "NONE";
            },
            csvFormatter: (cell, row, rowIndex) => {
              return row.lastReading ? row.lastReading : "NONE";
            }
          },
          {
            dataField: "totalReadingCount",
            text: "Non Adherence Days",
            sort: true,
            searchable: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              return row.totalReadingCount;
            },
            formatter: (cell, row) => {
              return row.totalReadingCount;
            }
          },
          {
            dataField: "enrolledPrograms",
            text: "Enrolled Program(s)",
            sort: true,
            searchable: true,
            headerFormatter: priceFormatter,
            formatter: (cell, row, rowIndex) => {
              return row.enrolledPrograms ? row.enrolledPrograms : '-';
            },
            csvFormatter: (cell, row, rowIndex) => {
              return row.enrolledPrograms ? row.enrolledPrograms : '-';
            }
          },
          {
            dataField: "insuranceProvider",
            text: "Primary Insurance Provider",
            sort: true,
            searchable: true,
            headerFormatter: priceFormatter,
            formatter: (cell, row, rowIndex) => {
              return row.insuranceProvider ? row.insuranceProvider : '-';
            },
            csvFormatter: (cell, row, rowIndex) => {
              return row.insuranceProvider ? row.insuranceProvider : '-';
            }
          },
          {
            dataField: "rpmTotal",
            text: "RPM Total",
            sort: true,
            searchable: true,
            formatter: (cell, row) => {             
              return row.rpmTotal;
            },
            csvFormatter: (cell, row, rowIndex) => {              
              return row.rpmTotal;
            }
          },
          {
            dataField: "ccmTotal",
            text: "CCM Total",
            sort: true,
            searchable: true,
            formatter: (cell, row) => {
              return row.ccmTotal;
            },
            csvFormatter: (cell, row, rowIndex) => {
              return row.ccmTotal;
            },
          },
          {
            dataField: "hadCalls",
            text: "Called",
            sort: true,
            formatter: (cell, row, rowIndex, extra) => {
              if(cell){
                return (
                  <div className="text-center">
                    <img src={callingIcon} />
                  </div>
                );
              } else {
                return (
                  <div className="text-center">    
                    <span>-</span>                
                  </div>
                );
              }              
            },
            csvFormatter: (cell, row, rowIndex) => {
              return cell ? "TRUE" : 'FALSE';
            }
          }
        ]}
        search
        exportCSV={{ fileName: "EndOfTheMonthReport_" + selectedMonth + "-" + selectedYear + ".csv" }}
      >
        {(toolKitProp) => (
          <div>
            <div>
              <Row className={`text-center col-md-12 mx-auto float-right  remove-table-padding`}>
                <Col md={10} className="remove-table-padding">
                  <MySearch {...toolKitProp.searchProps} />
                </Col>
                <Col md={2} className="remove-table-padding">
                  <MyExportCSV {...toolKitProp.csvProps}>
                    Export CSV
                  </MyExportCSV>
                </Col>
              </Row>
            </div>
            <div className="scrollbar-table-cls">
              <BootstrapTable
                wrapperClasses="table-responsive"
                keyField="_id"
                noDataIndication={ () => <NoDataIndication /> }
                filter={filterFactory()}
                {...toolKitProp.baseProps}
                pagination={paginationFactory(options)}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default List;
