import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  dateFilter
} from "react-bootstrap-table2-filter";
import moment from "moment";
import Link from "components/ExtendLink";
import Avatar from "react-avatar";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import LottieLoader from "../../LottieLoader";
import lottieEmptyFile from '../../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../files/animation/loading.json';
import UserName from "../../Reusable/UserName";
const _ = require("lodash");
function fullname(cell, row) {
  return (
    <UserName user={row} />
  );
}
function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}
function formatDate(cell, row) {
  return moment(cell).format("MMMM DD, YYYY");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  const authenticatedUser = formatExtraData.userId;
  if (row) {
    const isSameUser = authenticatedUser !== row.receivedBy._id;
    const approvedButton =
      isSameUser && !row.isApproved ? (
        <button
          type="button"
          onClick={() => formatExtraData.approve(row._id)}
          className="btn btn-success btn-fw"
        >
          Accept Request
        </button>
      ) : (
        ""
      );
    return (
      <span>
        {row.isApproved ? (
          <button
            type="button"
            onClick={() => formatExtraData.delete(row._id)}
            className="btn btn-danger btn-fw"
          >
            Delete
          </button>
        ) : (
          approvedButton
        )}
      </span>
    );
  }
}

const rowComponent = (row, readingSubTypes) => {
  if (row.readingsSevenDays && row.readingsSevenDays.length > 0) {
    return (
      <div>
        <Card>
          <CardHeader className="text-center">
            {row.fname} {row.lname} Last 7 Day Summary
            <Link
              to={`/dashboard/${row.fname
                .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row._id
                }`}
              className="text-white ml-auto btn btn-sm btn-success pull-right btn-rounded btn-fw"
            >
              More Details
            </Link>
          </CardHeader>
        </Card>
        <Row>
          {readingSubTypes.map((value, i) => {
            const readings = _.filter(row.readingsSevenDays, {
              readingType: value.reading
            });
            const readingValue = _.map(readings, "value");

            let checkThis = _.map(readingValue, readingIndex => {
              return readingIndex[value.index];
            });
            const latestReading = _.maxBy(readings, "deviceReadingDate");
            const getTheValueLatest = _.pick(latestReading, "value");
            // let latestValue = _.map(getTheValueLatest, readingIndex => {
            //   return readingIndex[value.index];
            // });

            // const getTheValueLatest = _.map(latestReading, "value");
            // let latestValue = _.map(getTheValueLatest, readingIndex => {
            //   return readingIndex[value.index];
            // });

            if (value.name === "Mean Arterial") {
              checkThis = _.map(readingValue, readingIndex => {
                return _.round(
                  (1 / 3) * readingIndex[0] + (2 / 3) * readingIndex[1],
                  2
                );
              });
            }

            const checkNan = isNaN(_.min(checkThis));
            if (value.name == "Beats Per Minute(BPM)") {
            }
            if (readings.length === 0 || checkNan) return;

            let latestResult = getTheValueLatest.value[value.index];
            if (value.name === "Mean Arterial") {
              latestResult = Math.round(
                _.round(
                  (1 / 3) * getTheValueLatest.value[0] +
                  (2 / 3) * getTheValueLatest.value[1],
                  2
                )
              );
            }
            let lastSevenDays = _.size(readings);
            let lastTestTaken =
              readings.length > 0 &&
              moment(
                _.maxBy(readings, "deviceReadingDate").deviceReadingDate
              ).format("MMMM DD, YYYY hh:mm A");
            let sevenDaysLowest =
              value.name != "Body Temperature"
                ? Math.round(_.min(checkThis))
                : _.min(checkThis).toFixed(2);
            let sevenDaysHighest =
              value.name != "Body Temperature"
                ? Math.round(_.max(checkThis))
                : _.max(checkThis).toFixed(2);
            let sevenDaysAverage =
              value.name != "Body Temperature"
                ? Math.round(_.mean(checkThis))
                : _.mean(checkThis).toFixed(2);
            if (value.name == "Body Temperature") {
              latestResult = ((latestResult * 9) / 5 + 32).toFixed(2);
              sevenDaysLowest = ((sevenDaysLowest * 9) / 5 + 32).toFixed(2);
              sevenDaysHighest = ((sevenDaysHighest * 9) / 5 + 32).toFixed(2);
              sevenDaysAverage = ((sevenDaysAverage * 9) / 5 + 32).toFixed(2);
            }
            if (value.name == "Weight") {
              if (value.abbrev == "BT_WS") {
                latestResult = Math.round(latestResult);
                sevenDaysLowest = Math.round(sevenDaysLowest);
                sevenDaysHighest = Math.round(sevenDaysHighest);
                sevenDaysAverage = Math.round(sevenDaysAverage);
              } else {
                latestResult = Math.round(latestResult * 2.2046);
                sevenDaysLowest = Math.round(sevenDaysLowest * 2.2046);
                sevenDaysHighest = Math.round(sevenDaysHighest * 2.2046);
                sevenDaysAverage = Math.round(sevenDaysAverage * 2.2046);
              }
            } else {
              latestResult = Math.round(latestResult)
            }
            return (
              <Col sm="4" className="mt-2">
                <Card>
                  <CardHeader className="bg-info text-white">
                    {value.name} - ({value.abbrev})
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-2">
                      <Col sm="8">No. of Test taken in last 7 days:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {lastSevenDays}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">Last Test Taken:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {lastTestTaken}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">Last Test Result:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {latestResult}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">7 days lowest:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {sevenDaysLowest}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">7 days highest:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {sevenDaysHighest}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="8">7 days average:</Col>
                      <Col sm="4" className="font-weight-bold">
                        {sevenDaysAverage}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    )
  }
  return (
    <div>
      <Card>
        <CardHeader className="text-center">
          <LottieLoader
            loadLoop={false}
            autoplayLoop={true}
            source={lottieEmptyFile}
            Lheight={170}
            Lwidth={170}
            subTitleSmall={"Sorry! No reading data found :("}
            subTitleExtraSmall={"We can't find any reading data."}
          />
        </CardHeader>
      </Card>
    </div>
  );
}
const defaultSorted = [
  {
    dataField: "createdAt", // if dataField is not match to any column you defined, it will be ignored.
    order: "desc" // desc or asc
  }
];
const status = (cell, row) => {
  const newUser = moment(row.createdAt).isSame(new Date(), "week");
  if (!row.isActive) {
    return <label class="badge badge-gradient-danger">INACTIVE</label>;
  }
  if (newUser) {
    return <label class="badge badge-gradient-info">NEW</label>;
  } else {
    return <label class="badge badge-gradient-success">ACTIVE</label>;
  }
};
const quickActions = (cell, row, rowIndex, formatExtraData) => {
  let sendTo;
  let to = row.phone;
  if (to) {
    if (
      to.includes("+1") ||
      to.includes("+") ||
      to.includes("09") ||
      to.includes("+63")
    ) {
      sendTo = to;
    } else {
      sendTo = "+1" + to;
    }
    sendTo = "tel:" + sendTo.replace(/-/g, "");
  } else {
    sendTo = "javascript:;";
  }

  const fullname = row.fname + " " + row.lname;
  return (
    <div className="btn-group">
      <UncontrolledTooltip
        placement="bottom"
        target={"resetPassword" + row._id}
      >
        Reset Password of {fullname}
      </UncontrolledTooltip>

      <button
        type="button"
        className="btn btn-info reset-password-click"
        onClick={() => formatExtraData(fullname, row._id)}
        id={"resetPassword" + row._id}
      >
        Reset Password
      </button>
    </div>
  );
};
const { SearchBar, ClearSearchButton } = Search;
const MySearch = props => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <div>
      <div class="form-group">
        <input
          type="text"
          class="form-control input-date-filter"
          id="exampleInputName1Test"
          style={{ fontSize: "20px" }}
          placeholder="Search Name, Nickname, Email, etc..."
          ref={n => (input = n)}
          onChange={handleClick}
        />
      </div>
    </div>
  );
};
const NewUser = (props, context) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "All",
        value: props.usersGivenAccessMyReading.length
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };
  const statusFilter = textFilter({
    defaultValue: "" // default filtering value
  });
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={170}
          Lwidth={170}
          subTitleSmall={"Sorry! No patient found :("}
          subTitleExtraSmall={"We can't find any patient."}
        />
      )}              
    </div>
  );
  return (
    <ToolkitProvider
      keyField="_id"
      data={props.usersGivenAccessMyReading}
      columns={[
        {
          dataField: "fname",
          text: "Name",
          formatter: fullname,
          sort: true,

          filterValue: (cell, row) => {
            return row.fname + " " + row.lname;
          },
          headerFormatter: priceFormatter
        },
        {
          dataField: "nickname",
          text: "Nickname",
          sort: true,
          formatter: (cell, row) => {
            return (row.nickname? row.nickname :"-");
          },
          filterValue: (cell, row) => {
            return (row.nickname? row.nickname :"-");
          },
          headerFormatter: priceFormatter,          
        },
        {
          dataField: "wala",
          text: "Status",
          sort: false,
          filter: statusFilter,
          headerFormatter: priceFormatter,
          filter: textFilter({
            className: 'input-date-filter',
            placeholder:"Status"
          }),          
          formatter: status
        },
        {
          dataField: "organization",
          text: "Organization",
          sort: true,
          formatter: (cell, row) => {
            return (row.organization? row.organization :"-");
          },
          headerFormatter: priceFormatter
        },
        {
          dataField: "email",
          text: "Email Address",
          sort: true,

          headerFormatter: priceFormatter
        },
        {
          dataField: "username",
          text: "Username",
          sort: true,

          headerFormatter: priceFormatter
        },

        {
          dataField: "createdAt",
          text: "Date Added",
          formatter: formatDate,
          sort: true
        },
        {
          dataField: "bday",
          text: "Quick Actions",
          formatter: quickActions,
          formatExtraData: props.toggleReset,
          sort: false
        }
      ]}
      search
    >
      {toolKitProp => (
        <div>
          <div>
            <MySearch {...toolKitProp.searchProps} />
          </div>

          <BootstrapTable
            wrapperClasses="table-responsive"
            // expandRow={{
            //   renderer: row => rowComponent(row, props.readingSubTypes),
            //   onlyOneExpanding: false,
            //   showExpandColumn: true,
            //   expandByColumnOnly: false
            // }}
            {...toolKitProp.baseProps}
            noDataIndication={ () => <NoDataIndication /> }
            filter={filterFactory()}
            defaultSorted={defaultSorted}
            pagination={paginationFactory(options)}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default NewUser;
