import React, { Component } from 'react';

import { connect } from 'react-redux';
import moment from "moment";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter,dateFilter } from "react-bootstrap-table2-filter";
import _ from "lodash";
import LottieLoader from '../LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import ReactToPrint from 'react-to-print';
import ViewCCMAnswerDetails from './ViewCCMAnswerDetails';

class CCMPatientAnswerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ccmAnswersModal: false,
      selectedModel: undefined
    };
    this.toggleCcmAnswersModal = this.toggleCcmAnswersModal.bind(this);
  }

  toggleCcmAnswersModal() {
    this.setState({
      ccmAnswersModal: !this.state.ccmAnswersModal
    });
  }

  componentDidMount() {

  }

  fetchData() {
    this.props.dispatch({
      type: "DO_GET_PATIENT_CCM_ANSWERS",
      payload: { userid: this.props.currentUserId }
    });
  }

  MyExportCSV = () => {
    const handleClick = () => {
      //props.onExport();
    };
    return (
      <Row>
        <Col>
          <button
            style={{ marginTop: 5, width: 164, height: 43 }}
            className="btn btn-info ml-2 download-click-cls"
            onClick={handleClick}>
            <i class="mdi mdi-download" style={{
              fontSize: 20
            }}></i> Export to CSV
          </button>
        </Col>
      </Row>
    );
  };

  MySearch = () => {
    let input;
    const handleChange = (e) => {
      e.preventDefault();
      const val = e.target.value.replace(/[^\w\s]/gi, "")
      this.setState({
        page: 1,
        search: val,
        sortField: "",
        sortOrder: "",
      });
    };
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        this.fetchData();
      }, 200)
      // if this effect run again, because `value` changed, we remove the previous timeout
      return () => clearTimeout(timeout)
    }, [this.state.search])
    return (
      <div>
        <div class="form-group">
          <input
            type="text"
            class="form-control input-date-filter remove-bottom-margin"
            id="exampleInputName1Test"
            style={{ fontSize: "20px" }}
            placeholder="Search Last Name, First Name, Nickname, Organization, Enrolled Programs"
            ref={(n) => (input = n)}
            value={this.state.search}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };

  formatDate(cell) {
    return moment(cell).format("MMM DD, YYYY hh:mm:ss A");
  }
  toCapitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  NoDataIndication = () => (
    <div>
      {this.props.ongoingRequestCCMQuestions && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!this.props.ongoingRequestCCMQuestions && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No CCM answer found :("}
          subTitle={"We can't find any ccm answer."}
        />
      )}
    </div>
  );
  render() {    
    return (
      <div>
        <Row className="col-sm-12 main-cls-modal remove-table-padding">
          <Col sm="12" className="mt-12">
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              noDataIndication={() => <this.NoDataIndication />}
              data={this.props.ongoingRequestCCMQuestions ? [] : this.props.latestCCMAnswers ? this.props.latestCCMAnswers : []}
              filter={filterFactory()}
              filterPosition="top"
              columns={[
                {
                  dataField: "createdAt",
                  text: "Date",
                  sort: true,
                  formatter: (cell, row) => {                    
                    return moment(row.createdAt).tz(moment.tz.guess()).format("MMMM DD, YYYY HH:mm:ss");
                  },
                  headerStyle: (colum, colIndex) => {
                    return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                  },
                },
                {
                  dataField: "description",
                  text: "Last Modify Date",
                  formatter: (cell, row) => {
                    if (row.modifyDate) {
                      return moment(row.modifyDate).tz(moment.tz.guess()).format("MMMM DD, YYYY HH:mm:ss");
                    } else {
                      return "NONE";
                    }
                  },
                  headerStyle: (colum, colIndex) => {
                    return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                  },
                },
                {
                  dataField: "createdBy.lname",
                  text: "Created By",
                  sort: true,
                  formatter: (cell, row) => {
                    if (!row.createdBy) {
                      return <div className="text-center">-</div>;
                    }
                    return (
                      <div className="text-center">
                        {this.toCapitalize(row.createdBy.lname)}  {this.toCapitalize(row.createdBy.fname)}
                      </div>
                    );
                  },
                  filter: textFilter({
                    className: 'input-date-filter full-width',
                    placeholder: "Search by last name"
                  }),
                  headerStyle: (colum, colIndex) => {
                    return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                  },
                },
                {
                  dataField: "modifyBy.fname",
                  text: "Last Modify By",
                  sort: false,
                  formatter: (cell, row) => {
                    if (!row.lastModifyBy) {
                      return <div className="text-center"> Initial CCM Answers </div>;
                    }
                    return (
                      <div className="text-center">{row.lastModifyBy.lname}{" "} {row.lastModifyBy.fname}</div>
                    );
                  },
                  headerStyle: (colum, colIndex) => {
                    return { textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                  },
                  filter: textFilter({
                    className: 'input-date-filter full-width',
                    placeholder: "Search by last name"
                  })
                },
                {
                  dataField: "",
                  text: "Action",
                  sort: false,
                  headerStyle: (colum, colIndex) => {
                    return { width: '150px', textAlign: 'center',textAlign: "center", padding: "16px", fontSize: "16px", color: "#070707" };
                  },
                  formatter: (cell, row, rowIndex, extra) => {
                    return (
                      <>
                        <button
                          className="btn btn-success answer-display"
                          onClick={() => {
                            this.setState({
                              selectedModel: row,
                              ccmAnswersModal: true
                            })
                          }}
                        >
                          <i class="mdi mdi-eye answer-display-icon" style={{
                            fontSize: 20,
                          }}></i> <text style={{ color: "#fffff" }}>View</text>
                        </button>
                      </>
                    );
                  },
                }
              ]}
            />
          </Col>
        </Row>
        {this.state.selectedModel && (
          <ViewCCMAnswerDetails
            toggleCcmAnswersModal={this.toggleCcmAnswersModal}
            selectedModel={this.state.selectedModel}
            ccmAnswersModal={this.state.ccmAnswersModal}
            mQuestionsList={this.props.mQuestionsList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    ccmAnswers,
    latestCCMAnswers,
    ongoingRequestCCMQuestions } = careprovider;
  const userId = user.userDetails._id;
  const profile = user.profile;
  const profileOwner = user.profileOwner ? user.profileOwner : user.userDetails;
  const userDetails = user;
  return {
    isGenericSuccess,
    isGenericError,
    userId,
    profileOwner,
    profile,
    userDetails,
    ccmAnswers,
    latestCCMAnswers,
    ongoingRequestCCMQuestions
  };
};

export default connect(mapStateToProps)(CCMPatientAnswerList);
