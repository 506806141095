import React from "react";
import Link from "components/ExtendLink";

import { Nav, NavItem, NavLink } from "reactstrap";
import population from "files/population.svg";
import onBoardingMenu from "../../files/ic_menu_on_boarding.png";
import { matchPath, withRouter } from "react-router";

const Sidebar = (props) => {
  const sidebarColor = props.clientInfo
    ? props.clientInfo.sidebarColor || "#ffffff"
    : "";
  const iconColor = props.clientInfo
    ? "" || "#222222" //props.clientInfo.iconColor
    : "";
  const isOpenSidebar = props.isOpenSidebar ? "active" : "mt-4";
  

  return (
    <div
      className="sidebar"
      onClick={() => {
        if (props.windowSize < "1199") props.openSidebar();
      }}
    >
      <div className="main-menu">
        <div className="scroll">
          <div className="scrollbar-container ps">
            <ul className="list-unstyled nav flex-column">
              {!props.user.isCareProvider && (
                <NavItem
                  active={matchPath(props.location.pathname, {
                    path: "/:client?/",
                    exact: true,
                    strict: false,
                  })}
                >
                  <NavLink tag={Link} to="/" className="">
                    <i
                      className={`mdi mdi-home icon-lg text-success ${
                        iconColor || "text-dark"
                      }`}
                      style={{ color: iconColor }}
                    />
                    <span style={{ color: iconColor }}>HOME</span>
                  </NavLink>
                </NavItem>
              )}
              {/* {matchPath(props.location.pathname, {
                path: "/:client?/events",
                exact: true,
                strict: false
              }) && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/events",
                      exact: true,
                      strict: false
                    })
                  }
                >
                  <NavLink tag={Link} to="/events" className="">
                    <i
                      style={{ color: iconColor }}
                      className={`mdi mdi-alert-octagon icon-lg ${
                        iconColor || " text-danger"
                      }`}
                    />
                    <span className="" style={{ color: iconColor }}>
                      EVENTS /
                      <br />
                      EXCEPTIONS
                    </span>
                  </NavLink>
                </NavItem>
              )} */}
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/:client?/exceptions",
                    exact: true,
                    strict: false,
                  })
                }
              >
                <NavLink tag={Link} to="/exceptions" className="">
                  <i
                    style={{ color: iconColor }}
                    className={`mdi mdi-alert-octagon icon-lg ${
                      iconColor || " text-danger"
                    }`}
                  />
                  <span className="" style={{ color: iconColor }}>
                    EVENTS /
                    <br />
                    EXCEPTIONS
                  </span>
                </NavLink>
              </NavItem>
              {props.user.isCareProvider && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/patients",
                      exact: true,
                      strict: false,
                    })
                  }
                >
                  <NavLink tag={Link} to="/patients" className="">
                    <i
                      style={{ color: iconColor }}
                      className={`mdi mdi-account-multiple icon-lg  ${
                        iconColor || "text-dark"
                      }`}
                    />
                    <span className="" style={{ color: iconColor }}>
                      PATIENTS
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {/* {props.user.isCareProvider && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/population-data",
                      exact: true,
                      strict: false
                    })
                  }
                >
                  <NavLink tag={Link} to="/population-data" className="">
                    <i
                      style={{ color: iconColor }}
                      className={`mdi mdi-finance icon-lg ${iconColor ||
                        "text-dark"}`}
                    />

                    <span className="" style={{ color: iconColor }}>
                      POPULATION DATA
                    </span>
                  </NavLink>
                </NavItem>
              )}*/}
              {props.user.isCareProvider && !props.user.isStaff && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/organization-staff",
                      exact: true,
                      strict: false,
                    })
                  }
                >
                  <NavLink tag={Link} to="/organization-staff" className="">
                    <i
                      style={{ color: iconColor }}
                      className={`mdi mdi-doctor icon-lg  ${
                        iconColor || "text-success"
                      }`}
                    />
                    <span className="" style={{ color: iconColor }}>
                      STAFF
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {props.user.isCareProvider && !props.user.isStaff && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/shared-account",
                      exact: true,
                      strict: false,
                    })
                  }
                ><NavLink tag={Link} to="/shared-account" className="">
                    <i
                      style={{ color: iconColor }}
                      className={`mdi mdi-doctor icon-lg  ${
                        iconColor || "text-success"
                      }`}
                    />
                    <span className="" style={{ color: iconColor }}>
                      Shared/Family Account
                    </span>
                  </NavLink>
                </NavItem>
              )}

              {/* && !props.user.isStaff */}
              {props.user.isCareProvider && !props.user.isStaff && (
                <>
                  <NavItem
                    active={
                      !!matchPath(props.location.pathname, {
                        path: "/:client?/conditions",
                        exact: true,
                        strict: false,
                      })
                    }
                  >
                    <NavLink tag={Link} to="/conditions" className="">
                      <i
                        style={{ color: iconColor }}
                        className={`mdi mdi-account-heart icon-lg ${
                          iconColor || "text-dark"
                        }`}
                      />
                      <span className="" style={{ color: iconColor }}>
                        CONDITIONS
                      </span>
                    </NavLink>
                  </NavItem>
                </>
              )}
              {props.user.isCareProvider && (
                <>
                  <NavItem
                    active={
                      !!matchPath(props.location.pathname, {
                        path: "/:client?/ccm-records",
                        exact: true,
                        strict: false,
                      })
                    }
                  >
                    <NavLink tag={Link} to="/ccm-records" className="">
                      <i
                        style={{ color: iconColor }}
                        className={`mdi mdi-monitor icon-lg ${
                          iconColor || "text-dark"
                        }`}
                      />
                      <span className="" style={{ color: iconColor }}>
                        PM SESSIONS
                      </span>
                    </NavLink>
                  </NavItem>
                </>
              )}

              {/* && !props.user.isStaff */}
              {props.user.isCareProvider && !props.user.isStaff && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/expectations",
                      exact: true,
                      strict: true,
                    })
                  }
                >
                  <NavLink tag={Link} to="/expectations" className="">
                    <i
                      className={`mdi mdi-calendar-alert icon-lg  ${
                        iconColor || "text-dark"
                      }`}
                      style={{ color: iconColor }}
                    />
                    <span style={{ color: iconColor }}>EXPECTATIONS</span>
                  </NavLink>
                </NavItem>
              )}
              {props.user.isCareProvider && !props.user.isStaff && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/questionnaire",
                      exact: true,
                      strict: true,
                    })
                  }
                >
                  <NavLink tag={Link} to="/questionnaire" className="">
                    <i
                      className={`mdi mdi-comment-question-outline icon-lg  ${
                        iconColor || "text-dark"
                      }`}
                      style={{ color: iconColor }}
                    />
                    <span style={{ color: iconColor }}>Questionnaire</span>
                  </NavLink>
                </NavItem>
              )}
              {(props.user.isCareProvider || props.user.isStaff) && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/onboarding",
                      exact: true,
                      strict: true,
                    })
                  }
                >
                  <NavLink tag={Link} to="/onboarding" className="">
                    <span style={{ color: iconColor }}>
                      <img className="img-tabsfluid" src={onBoardingMenu} style={{marginRight:5}}/><br></br>RPM On-Boarding</span>
                  </NavLink>
                </NavItem>
              )}
              {(props.user.isCareProvider || props.user.isStaff) && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/offboarding",
                      exact: true,
                      strict: true,
                    })
                  }
                >
                  <NavLink tag={Link} to="/offboarding" className="">
                    <span style={{ color: iconColor }}><img className="img-tabsfluid" src={onBoardingMenu} style={{marginRight:5}}/><br></br>RPM Off-Boarding</span>
                  </NavLink>
                </NavItem>
              )}
              {(props.user.isCareProvider || props.user.isStaff) && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/onboardingccm",
                      exact: true,
                      strict: true,
                    })
                  }
                >
                  <NavLink tag={Link} to="/onboardingccm" className="">
                    <span style={{ color: iconColor }}>
                      <img className="img-tabsfluid" src={onBoardingMenu} style={{marginRight:5}}/><br></br>CCM On-Boarding</span>
                  </NavLink>
                </NavItem>
              )}
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/:client?/settings",
                    exact: true,
                    strict: false,
                  })
                }
              >
                <NavLink tag={Link} to="/settings" className="">
                  <i
                    style={{ color: iconColor }}
                    className={`mdi mdi-account-settings icon-lg  ${
                      iconColor || "text-dark"
                    }`}
                  />
                  <span className="" style={{ color: iconColor }}>
                    SETTINGS
                  </span>
                </NavLink>
              </NavItem>
            </ul>
            <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
              <div
                className="ps__thumb-x"
                tabIndex={0}
                style={{ left: "0px", width: "0px" }}
              />
            </div>
            <div
              className="ps__rail-y"
              style={{ top: "0px", right: "0px", height: "568px" }}
            >
              <div
                className="ps__thumb-y"
                tabIndex={0}
                style={{ top: "0px", height: "0px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
