import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import EmailInput from "./EmailInput";
const _ = require("lodash");

const ReportConfigration = React.forwardRef((props, ref) => {

    const [isEditable, setEditable] = useState(false);
    const { register, control, getValues, setValue, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: useMemo(() => {
            return props.notifyReadingMonthlyReport;
        }, [props])
    });

    useEffect(() => {
        reset(props.notifyReadingMonthlyReport);
    }, [props.notifyReadingMonthlyReport]);

    /**
     * Submit form details
     * @param {*} data 
     */
    const onSubmit = (data) => {
        setEditable(false);
        props.saveCCMDetails(data)
    };

    return (
        <>
            <div className="configration-header-back">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="delete-condition-view">
                        <h5 className="display-align-justify-center"><i class="mdi mdi-note-text" style={{ fontSize: 20, marginRight: 5 }}></i>The monthly graph and log book report will be sent through the following information system!</h5>
                        <div className="float-right d-flex flex-row-reverse">
                            {isEditable && (
                                <button
                                    style={{ float: 'right', marginRight: 10 }}
                                    onClick={() => {
                                        setEditable(false);
                                        reset(props.notifyReadingMonthlyReport);
                                    }}
                                    className="btn btn-info ml-2 close-configration float-right" >
                                    <i class="mdi mdi-close" style={{
                                        fontSize: 20,
                                        marginRight: 8
                                    }}></i>
                                    Close
                                </button>
                            )}
                            {isEditable && (
                                <button
                                    type="submit"
                                    style={{ float: 'right', marginRight: 10 }}
                                    className="btn btn-info ml-2 save-configration float-right" >
                                    <i class="mdi mdi-check-circle" style={{
                                        fontSize: 20,
                                        marginRight: 8
                                    }}></i>
                                    Submit
                                </button>
                            )}
                            {!isEditable && props.notifyReadingMonthlyReport && (
                                <button
                                    style={{ float: 'right', marginRight: 10 }}
                                    onClick={() => {
                                        setEditable(true)
                                    }}
                                    className="btn btn-info ml-2 edit-configration float-right" >
                                    <i class="mdi mdi-lead-pencil" style={{
                                        fontSize: 20,
                                        marginRight: 8
                                    }}></i>
                                    Edit
                                </button>
                            )}

                            {!isEditable && !props.notifyReadingMonthlyReport && (
                                <button
                                    style={{ float: 'right', marginRight: 10 }}
                                    onClick={() => {
                                        setEditable(true)
                                    }}
                                    className="btn btn-info ml-2 edit-configration float-right" >
                                    <i class="mdi mdi-plus" style={{
                                        fontSize: 20,
                                        marginRight: 8
                                    }}></i>
                                    Add Details
                                </button>
                            )}
                            

                        </div>
                    </div>
                    <div className="draw-div-line"></div>
                    <EmailInput
                        {...{ control, register, getValues, setValue, reset, errors, isEditable, props }}
                    />

                </form>
            </div>
        </>
    );
});

export default ReportConfigration;