import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "components/utils/fetchHook";
import Heading from "./Heading";
import { Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import ReactLoading from "react-loading";
import careproviderpatient from "files/careproviderpatient.png";
import CareproviderAndStaff from "./CareproviderAndStaff";
import Patient from "./Patient";
import settingsImage from "../../../../files/settings.png";
import careprovidersStaffsImage from "../../../../files/doctors-female-and-male.png";
import patientsImage from "../../../../files/patient-in-bed.png";
import { useDispatch } from "react-redux";
const ClientManagement = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "DO_REMOVE_PROFILE_DATA"
    });
    // dispatch({
    //   type: "DO_GET_CLIENT_DETAILS"
    // });
  }, []);
  return (
    <div>
      <div className="row">
        {/* <Heading
          title="CLIENTS"
          description=""
          backbutton="d-none"
          mainbutton="d-block"
        >
          <span className="page-title-icon bg-gradient-danger   text-white mr-2">
            <i className="mdi mdi-account-convert" />
          </span>
        </Heading> */}
        <div className="col-12 grid-margin">
          <section>
            <div className="sttabs tabs-style-iconbox">
              <nav>
                <ul>
                  <li
                    className={classnames({
                      tabcurrent: activeTab === "1"
                    })}
                    onClick={() => {
                      setactiveTab("1");
                    }}
                  >
                    <a className="char font-bold urgent-tabs">
                      {" "}
                      <img
                        src={careprovidersStaffsImage}
                        width="80px"
                        className="img-circle"
                        alt
                      />
                      <br />
                      <span>
                        {" "}
                        <br />
                        CAREPROVIDERS & STAFF
                      </span>
                    </a>
                  </li>
                  <li
                    className={classnames({
                      tabcurrent: activeTab === "2"
                    })}
                    onClick={() => {
                      setactiveTab("2");
                    }}
                  >
                    <a className="char font-bold urgent-tabs">
                      {" "}
                      <img
                        src={patientsImage}
                        width="80px"
                        className="img-circle"
                        alt
                      />
                      <br />
                      <span>
                        {" "}
                        <br />
                        PATIENTS
                      </span>
                    </a>
                  </li>
                  <li
                    className={classnames({
                      tabcurrent: activeTab === "3"
                    })}
                    onClick={() => {
                      setactiveTab("3");
                    }}
                  >
                    <a className="char font-bold urgent-tabs">
                      {" "}
                      <img
                        src={settingsImage}
                        width="80px"
                        className="img-circle"
                        alt
                      />
                      <br />
                      <span>
                        {" "}
                        <br />
                        SETTINGS
                      </span>
                    </a>
                  </li>
                  {/* <li
                    className={classnames({
                      tabcurrent: activeTab === "3"
                    })}
                    onClick={() => {
                      setactiveTab("3");
                    }}
                  >
                    <a className="char font-bold urgent-tabs">
                      {" "}
                      <img
                        src="https://image.flaticon.com/icons/png/512/1605/1605350.png"
                        width="80px"
                        className="img-circle"
                        alt
                      />
                      <br />
                      <span>
                        {" "}
                        <br /> SUBCLIENTS
                      </span>
                    </a>
                  </li> */}
                  {/* <li
                    className={classnames({
                      tabcurrent: activeTab === "4"
                    })}
                    onClick={() => {
                      setactiveTab("4");
                    }}
                  >
                    <a className="char font-bold urgent-tabs">
                      {" "}
                      <img
                        src={careproviderpatient}
                        width="80px"
                        className="img-circle"
                        alt
                      />
                      <br />
                      <span>
                        {" "}
                        <br /> SUPPORT (under maintenance)
                      </span>
                    </a>
                  </li> */}
                </ul>
              </nav>
              <div className="content-wrap">
                <section
                  id="section-iconbox-5"
                  className={classnames({
                    contentcurrent: activeTab === "1"
                  })}
                >
                  <h1 className="text-center mb-1">
                    {props.organization.toUpperCase()} CAREPROVIDER AND STAFF
                    ACCOUNTS
                  </h1>
                  <CareproviderAndStaff
                    clientId={props.clientId}
                    textTitle={props.title ? "SubClient" : "Client"}
                  ></CareproviderAndStaff>
                </section>

                <section
                  id="section-iconbox-2"
                  className={classnames({
                    contentcurrent: activeTab === "2"
                  })}
                >
                  <h1 className="text-center mb-1">
                    {" "}
                    {props.organization.toUpperCase()} PATIENTS
                  </h1>
                  <Patient
                    clientId={props.clientId}
                    textTitle={props.title ? "SubClient" : "Client"}
                  ></Patient>
                </section>
              </div>
              <div className="content-wrap">
                <section
                  id="section-iconbox-3"
                  className={classnames({
                    contentcurrent: activeTab === "3"
                  })}
                >
                  <h1 className="text-center mb-1">
                    {" "}
                    {props.organization.toUpperCase()} SETTINGS
                    <br></br>
                    <b>Note: Under Maintenance</b>
                  </h1>
                </section>
              </div>
              {/* /content */}
            </div>
            {/* /tabs */}
          </section>
        </div>
      </div>
    </div>
  );
};
export default ClientManagement;
