import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import Header from "./Header";
import ToggleModel from "./ToggleModel";
import MyQuestionnaireList from "./QuestionnaireRuleList";
const AppContext = React.createContext();
class QuestionPage extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
    };
    this.toggleModal = this.toggleModal.bind(this);    
  }
  toggleModal() {
    
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_GET_MY_RULES",
    });
    this.props.dispatch({
      type: "DO_GET_RULES_SET_BY_OTHERS",
    });
    this.props.dispatch({
      type: "DO_GET_USERS_YOU_HAVE_ACCESS",
    });
    this.props.dispatch({
      type: "DO_GET_MY_QUESTIONNAIRES_CARE_PROVIDER"      
    });
    if (this.props.userDetails.isCareProvider) {
      this.props.dispatch({
        type: "DO_GET_CONDITIONS",
      });
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <Header
            title="QUESTIONS"
            description=""
            backbutton="d-none"
            isCareProvider={true}
            mainbutton="d-flex"
            toggleModal={this.toggleModal}
          >
            <span className="page-title-icon bg-gradient-danger   text-white mr-2">
              <i className="mdi mdi-account-convert" />
            </span>
          </Header>

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body project-tab pt-2">
                <Nav tabs className="justify-content-center">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      MY Questionnaire
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2"
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      QUESTIONNAIRE SET BY OTHERS
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12" className="grid-margin">
                        {this.props.questionnaireRule && (
                          <MyQuestionnaireList
                            questionnaireRule={this.props.questionnaireRule}
                            userId={this.props.userId}
                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12" className="grid-margin">
                        {/* {this.props.rulesSetByOthers && (
                          <MyQuestionnaireList
                            myRules={this.props.rulesSetByOthers}
                            userId={this.props.userId}
                          />
                        )} */}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>                
              </div>
            </div>
          </div>
        </div>
        {this.props.userDetails && this.props.userDetails.isCareProvider && (
          <ToggleModel
            modal={this.state.modal}
            toggleModal={this.toggleModal}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myRules,
    rulesSetByOthers,
    questionnaireRule,
    usersOfferedAccess,
  } = careprovider;
  const userId = user.userDetails._id;
  const userDetails = user.userDetails;

  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myRules,
    rulesSetByOthers,
    questionnaireRule,
    userId,
    userDetails,
    usersOfferedAccess,
  };
};

export default connect(mapStateToProps)(QuestionPage);
