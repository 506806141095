import { map } from "lodash";
import { Component, default as React } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import Heading from "./Heading";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactLoading from "react-loading";
import ConditionTable from "../../../Condition/ConditionTable";

class Condition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sweetalert: false,
      deleteId: null,
      valueEdit: false,
      modalEdit: false,
      modal: false,
      patientsPlaceholder: null,
      tab: "1",
      condition: "",
      description: ""
    };

    this.updateCondition = this.updateCondition.bind(this);
    this.deleteCondition = this.deleteCondition.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  updateCondition(data, patients) {
    const patientIds = map(data.patients, "_id");
    const originalPatients = patients;
    const newPatients = data.patients;
    const patientValue = originalPatients.filter((value) => {
      const patientId = value._id;
      return patientIds.includes(patientId);
    });

    this.setState({
      modalEdit: !this.state.modalEdit,
      valueEdit: data,
      patientsPlaceholder: {
        patientsAssigned: patientIds,
        patientsPlaceholder: patientValue
      }
    });
  }
  deleteCondition(data) {
    this.setState({
      sweetalert: true,
      deleteId: data
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const patients = this.state.patientsPlaceholder
      ? this.state.patientsPlaceholder.patientsAssigned
      : [];
    const payload = {
      condition: this.state.condition,
      description: this.state.description,
      patients,
      careProviderId: this.props.profile._id
    };

    this.props.dispatch({
      type: "DO_CREATE_CAREPROVIDER_CONDITION",
      payload
    });
    this.props.dispatch({
      type: "DO_GET_CONDITIONS",
      payload: { careproviderId: this.props.profile._id }
    });

    this.setState({ modal: !this.state.modal });
  }

  onUpdateSubmit(e) {
    e.preventDefault();

    const patients = this.state.patientsPlaceholder
      ? this.state.patientsPlaceholder.patientsAssigned
      : [];
    const payload = {
      condition: this.state.condition,
      description: this.state.description,
      patients,
      careProviderId: this.props.profile._id,
      conditionId: this.state.valueEdit._id
    };
    this.props.dispatch({
      type: "DO_UPDATE_CAREPROVIDER_CONDITION",
      payload
    });
    this.props.dispatch({
      type: "DO_GET_CONDITIONS",
      payload: { careproviderId: this.props.profile._id }
    });

    this.setState({ modalEdit: !this.state.modalEdit });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;

    const name = target.name;
    this.setState({ [name]: value });
  }

  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_CONDITIONS",
      payload: { careproviderId: this.props.profile._id }
    });
  }

  render() {
    const clearError = () => {
      // sendError({ error: null, message: null });
    };

    const handleUsers = (selectedOption) => {
      const patientIds = map(selectedOption, "_id");
      this.setState({
        patientsPlaceholder: {
          patientsAssigned: patientIds,
          patientsPlaceholder: selectedOption
        }
      });
    };

    const deleteConditionNow = (deleteId) => {
      this.props.dispatch({
        type: "DO_DELETE_CAREPROVIDER_CONDITION",
        payload: { conditionid: deleteId, userid: this.props.profile._id }
      });
      this.props.dispatch({
        type: "DO_GET_CONDITIONS",
        payload: { careproviderId: this.props.profile._id }
      });
      this.setState({ sweetalert: false, deleteId: null });
    };

    return (
      <div>
        <div className="row">
          <Heading
            title="CONDITIONS"
            description=""
            backbutton="d-none"
            mainbutton="d-block"
            actionMainButton={() => {
              this.setState({
                modal: !this.state.modal,
                patientsPlaceholder: null
              });
            }}
          >
            <span className="page-title-icon bg-gradient-danger   text-white mr-2">
              <i className="mdi mdi-account-convert" />
            </span>
          </Heading>

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body project-tab pt-2">
                <Nav tabs className="justify-content-center">
                  <NavItem>
                    <NavLink
                      className="active"
                      onClick={() => {
                        this.setState({
                          tab: "1"
                        });
                      }}
                    >
                      CONDITIONS
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row>
                  <h5 className="mb-4" />
                  {!this.props.conditions && (
                    <ReactLoading
                      type="spin"
                      className="text-center mx-auto"
                      color="blue"
                    />
                  )}
                  <Col md={12}>
                    <ConditionTable
                      patients={this.props.patients}
                      conditions={this.props.conditions}
                      updateCondition={this.updateCondition}
                      deleteCondition={this.deleteCondition}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.setState({ modal: !this.state.modal })}
        >
          <ModalHeader
            toggle={() => this.setState({ modal: !this.state.modal })}
          >
            Create A Condition
          </ModalHeader>
          <ModalBody className="bg-white">
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <Label for="exampleSelect">Condition</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="condition"
                  required={true}
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">Description</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  rows="6"
                  type="textarea"  
                  name="description"
                  required={true}
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">
                  Pick one or more Patient to Assign to this Condition
                </Label>
                <Select
                  value={
                    this.state.patientsPlaceholder
                      ? this.state.patientsPlaceholder.patientsPlaceholder
                      : ""
                  }
                  onChange={handleUsers}
                  options={this.props.patients}
                  isMulti={true}
                  getOptionLabel={(option) =>
                    option["fname"] + " " + option["lname"]
                  }
                  getOptionValue={(option) => option}
                />
                <FormText color="muted">
                  You can also assign patient after creating condition
                </FormText>
                <FormFeedback></FormFeedback>
              </FormGroup>
              <button type="submit" className="btn btn-block btn-info">
                {!this.props.conditions ? (
                  <ReactLoading
                    type="spin"
                    height={"24px"}
                    width={"24px"}
                    className="text-center mx-auto"
                    color="white"
                  />
                ) : (
                  "Send"
                )}
              </button>
            </Form>
          </ModalBody>
        </Modal>
        <SweetAlert
          warning
          showCancel
          show={this.state.sweetalert}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={() => deleteConditionNow(this.state.deleteId)}
          onCancel={() => this.setState({ sweetalert: false })}
        >
          This will delete the condition and patients belong to it
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
        <Modal
          isOpen={this.state.modalEdit}
          toggle={() => this.setState({ modalEdit: !this.state.modalEdit })}
        >
          <ModalHeader
            toggle={() => this.setState({ modalEdit: !this.state.modalEdit })}
          >
            Update A Condition
          </ModalHeader>
          <ModalBody className="bg-white">
            <Form onSubmit={this.onUpdateSubmit}>
              <FormGroup>
                <Label for="exampleSelect">Condition</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="hidden"
                  name="_id"
                  defaultValue={this.state.valueEdit._id}
                  required={true}
                />
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="text"
                  name="condition"
                  defaultValue={this.state.valueEdit.condition}
                  required={true}
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">Description</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  rows="6"
                  type="textarea"  
                  name="description"
                  defaultValue={this.state.valueEdit.description}
                  required={true}
                  onChange={this.handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">
                  Removing a patient from the option will automatically removed
                  them from the list of this condition
                </Label>
                <Select
                  value={
                    this.state.patientsPlaceholder
                      ? this.state.patientsPlaceholder.patientsPlaceholder
                      : ""
                  }
                  onChange={handleUsers}
                  options={this.props.patients}
                  isMulti={true}
                  getOptionLabel={(option) =>
                    option["fname"] + " " + option["lname"]
                  }
                  getOptionValue={(option) => option}
                />
                <FormText color="muted" />
                <FormFeedback></FormFeedback>
              </FormGroup>
              <button type="submit" className="btn btn-block btn-info">
                {!this.props.conditions ? (
                  <ReactLoading
                    type="spin"
                    height={"24px"}
                    width={"24px"}
                    className="text-center mx-auto"
                    color="white"
                  />
                ) : (
                  "Send"
                )}
              </button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    careProviderExpectations,
    conditions,
    patients
  } = careprovider;
  const profile = user.profile;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    careProviderExpectations,
    profile,
    patients,
    conditions
  };
};

export default connect(mapStateToProps)(Condition);
