import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactLoading from "react-loading";
import PatientList from "./PatientList";
import Heading from "./Heading";
import CreateUserModal from "./CreateUserModal";
import ResetPassword from "../AdminAnalytics/ResetPassword";
import { omit, size, filter, pick } from "lodash";
import doctorsImage from "../../../files/doctors.png";
class AdminCareProviders extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      modalUser: false,
      modalReset: false,
      emailOffer: "",
      emailAccess: "",
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      fax: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      showAlertSuccess: false,
      errorPassword: false,
      isClient: true,
      careprovider: true,
      resetFullname: "",
      resetPatientId: "",
      password: "",
      showWarning: false,
      displayActiveList: true
    };
    this.toggleUser = this.toggleUser.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.toggleReset = this.toggleReset.bind(this);
    this.submitReset = this.submitReset.bind(this);
    this.submitResetPassword = this.submitResetPassword.bind(this);
    this.resetStateBack = this.resetStateBack.bind(this);
    this.toggleDisplayActiveList = this.toggleDisplayActiveList.bind(this);
  }
  submitReset(e) {
    e.preventDefault();
    if (this.state.errorPassword) {
      return;
    }
    this.setState({
      showWarning: !this.state.showWarning
    });
  }
  submitResetPassword() {
    this.props.dispatch({
      type: "DO_RESET_PATIENT_PASSWORD",
      payload: {
        user: this.state.resetPatientId,
        password: this.state.password
      }
    });
    this.setState({
      showWarning: !this.state.showWarning
    });
  }
  toggleReset(fullname, patientId) {
    const previousState = this.state.modalReset;

    this.setState({
      modalReset: !this.state.modalReset,
      resetFullname: fullname,
      resetPatientId: patientId
    });
    if (previousState) {
      this.resetStateBack();
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }
  resetStateBack() {
    this.setState({
      modalReset: false,
      showWarning: false,
      resetFullname: "",
      resetPatientId: ""
    });
  }
  resetForm() {
    this.setState({
      modalReset: false,
      showWarning: false,
      resetFullname: "",
      resetPatientId: "",
      modal2: false,
      modal: false,
      modalUser: false,
      fname: "",
      lname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      fax: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      role: "",
      isClient: true,
      isCareProvider: true
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    this.props.dispatch({
      type: "DO_GET_CAREPROVIDERS",
      payload: { client: true }
    });
    // this.props.dispatch({
    //   type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    // });
  }
  submitUser(e) {
    e.preventDefault();
    const cleanUser = pick(this.state, [
      "fname",
      "lname",
      "nickname",
      "username",
      "email",
      "phone",
      "homeNumber",
      "workNumber",
      "fax",
      "address",
      "city",
      "state",
      "zip",
      "weight",
      "height",
      "bday",
      "memberId",
      "organization",
      "gender",
      "password",
      "subOrganization",
      "isCareProvider",
      "careprovider"
    ]);
    this.props.dispatch({
      type: "DO_SAVE_USER",
      payload: cleanUser
    });
    setTimeout(() => {
      this.props.dispatch({
        type: "DO_GET_CAREPROVIDERS",
        payload: { client: true }
      });
    }, 1500);
  }
  toggleUser() {
    
    this.setState({
      modalUser: !this.state.modalUser
    });
  }
  toggleModal() {
    
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggleDisplayActiveList(display) {
    if (display) {
      //Display active list
      this.setState({
        displayActiveList: true,
      });
    } else {
      // inactive list clicked
      this.setState({
        displayActiveList: false,
      });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    this.props.dispatch({
      type: "DO_REMOVE_PROFILE_DATA"
    });
    this.props.dispatch({
      type: "DO_GET_CAREPROVIDERS",
      payload: { client: true }
    });

    // this.props.dispatch({
    //   type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    // });
  }

  render() {
    const activePatients = filter(this.props.careproviders, {
      isActive: true
    });
    const inActivePatients = filter(this.props.careproviders, {
      isActive: false
    });
    const numberActive = size(activePatients);
    const numberInactive = size(inActivePatients);
    var activeCareproviderStaffCard = { backgroundColor: "white", color: "black" };
    var inActiveCareproviderStaffCard = { backgroundColor: "white", color: "black" };
    if (this.state.displayActiveList == true) {
      //Active List
      activeCareproviderStaffCard = { backgroundColor: "#198ae3", color: "white" };
      inActiveCareproviderStaffCard = { backgroundColor: "white", color: "black" };
    } else if (this.state.displayActiveList == false) {
      //Inactive list      
      inActiveCareproviderStaffCard = { backgroundColor: "#198ae3", color: "white" };
      activeCareproviderStaffCard = { backgroundColor: "white", color: "black" };
    }
    return (
      <div>
        <div className="row">
          <Heading
            title="CAREPROVIDERS"
            description=""
            toggleModal={this.toggleModal}
            backbutton="d-none"
            mainbutton="d-block"
          >
            <span className="page-title-icon bg-gradient-danger   text-white mr-2">
              <i className="mdi mdi-account-convert" />
            </span>
          </Heading>

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body project-tab pt-2">
                <Nav tabs className="justify-content-center">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Care Providers & Staff
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row>
                  {(this.props.ongoingCareproviderStaffRequest) && (
                    <ReactLoading className="mx-auto" color={"#007bff"} />
                  )}
                  {(this.props.careproviders && !this.props.ongoingCareproviderStaffRequest) &&
                    (
                      <>
                    <Col sm="3" className="mt-4 "></Col>
                    <Col sm="3" className="mt-4">
                      {" "}
                      <div className="card overflow-hidden"
                      style={{ pointerEvents: "yes" }}
                      onClick={() => this.toggleDisplayActiveList(true)}
                      >
                        <div className="card-content" style={activeCareproviderStaffCard}>
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>Active Careproviders</h4>
                                <h1>{numberActive}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm="3" className="mt-4">
                      {" "}
                      <div className="card overflow-hidden"
                        style={{ pointerEvents: "yes" }}
                        onClick={() => this.toggleDisplayActiveList(false)}
                      >
                        <div className="card-content" style={inActiveCareproviderStaffCard}>
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>Inactive Careproviders</h4>
                                <h1>{numberInactive}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm="3" className="mt-4 "></Col>
                    <Col sm="12" className="mt-4">
                      {this.state.displayActiveList == true && (
                        <PatientList
                          usersGivenAccessMyReading={activePatients}
                          toggleReset={this.toggleReset}
                          readingSubTypes={this.props.readingSubTypes}
                          userId={this.props.userId}
                          deletePermission={this.deletePermission}
                          approvePermission={this.approvePermission}
                          receivedFrom={false}
                        />
                      )}
                      {this.state.displayActiveList == false && (
                        <PatientList
                          usersGivenAccessMyReading={inActivePatients}
                          toggleReset={this.toggleReset}
                          readingSubTypes={this.props.readingSubTypes}
                          userId={this.props.userId}
                          deletePermission={this.deletePermission}
                          approvePermission={this.approvePermission}
                          receivedFrom={false}
                        />
                      )}
                    </Col>
                    </>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>
            Create a New Patient
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <h5 className="text-center" />
            <div className="row">
              {/* <div className="col-6">
                <NavLink
                  className="text-center d-block"
                  onClick={this.openInvite}
                >
                  <img
                    src="https://image.flaticon.com/icons/svg/201/201618.svg"
                    style={{ height: "180px" }}
                  />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    INVITE A USER
                  </span>
                  <small>Send a sign up link</small>
                </NavLink>
              </div> */}
              <div className="col-6 mx-auto">
                <NavLink
                  className="text-center d-block"
                  onClick={this.toggleUser}
                >
                  <img src={doctorsImage} style={{ height: "180px" }} />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    CREATE A NEW CAREPROVIDER ACCOUNT
                  </span>
                  <small>You will input everything</small>
                </NavLink>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <SweetAlert
          warning
          showCancel
          show={this.state.showWarning}
          confirmBtnText="Yes, reset the password now!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.submitResetPassword}
          onCancel={this.submitReset}
        >
          This will change the password of these user
        </SweetAlert>
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={this.resetForm}
          confirmBtnBsStyle="info"
        >
          <div>Your action is successful</div>
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
        <ResetPassword
          modalReset={this.state.modalReset}
          toggleReset={this.toggleReset}
          fullname={this.state.resetFullname}
          errorPassword={this.state.errorPassword}
          submitReset={this.submitReset}
          handleInputChange={this.handleInputChange}
        />
        <CreateUserModal
          modalUser={this.state.modalUser}
          toggleUser={this.toggleUser}
          errorPassword={this.state.errorPassword}
          submitUser={this.submitUser}
          handleInputChange={this.handleInputChange}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user, auth, client }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    usersOfferedAccessOrganization,
    ongoingCareproviderStaffRequest
  } = careprovider;
  const { readingSubTypes } = auth;
  const { careproviders } = client;
  const userId = user.userDetails._id;

  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    usersOfferedAccessOrganization,
    userId,
    careproviders,
    ongoingCareproviderStaffRequest
  };
};

export default connect(mapStateToProps)(AdminCareProviders);
