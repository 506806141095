import React, { PureComponent } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import ReactLoading from "react-loading";

import moment from "moment";
import ResetPassword from "../AdminAnalytics/ResetPassword";
import SweetAlert from "react-bootstrap-sweetalert";
import PatientList from "./PatientList";
import NewUser from "./NewUser";
import NoReading from "./NoReading";
import ClientList from "./ClientList";
import DeleteUserAction from "./DeleteUserAction";
import clipboardImage from "../../../files/clipboard.png";
import clientsImage from "../../../files/clients.png";
import addUsersImage from "../../../files/add-users.png";
import resetPasswordImage from "../../../files/reset-password.png";
import reminderSentImage  from "../../../files/sent.png";
import followUpImage  from "../../../files/follow-up.jpg";
import ImprovedPatientList from '../AdminAnalytics/ImprovedPatientList';
import SignatureCanvas from "react-signature-canvas";
import pdf from "../../../files/Privacy Policy And Hipaa Business Associate Agreement.pdf";
const _ = require("lodash");
class AdminHome extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      toggleResetModal: false,
      modalReset: false,
      errorPassword: false,
      resetFullname: "",
      resetPatientId: "",
      showWarning: false,
      togglemodalNewUser: false,
      toggleModalNoReadings: false,
      toggleModalClientList: false,
      toggleFollowUpReminderModal : false,
      toggleNeedToFollowUpModal: false,
      startDate: "",
      startDateError: null,
      endDate: "",
      endDateError: null,
      sortOrder : 'desc',
      sortField : null,
      search: "",
      enrolledPrograms: "",
      page: 1,
      limit: 10,
      timeout : null,
      modalAgreement: false,
    };
    this.sigCanvas = React.createRef();
    this.toggleResetModal = this.toggleResetModal.bind(this);
    this.togglemodalNewUser = this.togglemodalNewUser.bind(this);
    this.toggleReset = this.toggleReset.bind(this);
    this.submitReset = this.submitReset.bind(this);
    this.submitResetPassword = this.submitResetPassword.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.resetStateBack = this.resetStateBack.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleModalNoReadings = this.toggleModalNoReadings.bind(this);
    this.toggleModalClientList = this.toggleModalClientList.bind(this);
    this.toggleFollowUpReminderModal = this.toggleFollowUpReminderModal.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputDateChange = this.handleInputDateChange.bind(this);
    this.validatedate = this.validatedate.bind(this);
    this.filterEnrolledPrograms = this.filterEnrolledPrograms.bind(this)
    this.toggleAgreement = this.toggleAgreement.bind(this);
    this.logout = this.logout.bind(this);
    this.toggleNeedToFollowUpModal = this.toggleNeedToFollowUpModal.bind(this);
    this.closeModel = this.closeModel.bind(this);
  }
  toggleAgreement() {
    if(!this.sigCanvas.current.isEmpty()){
      this.setState((prevState) => ({
        modalAgreement: !prevState.modalAgreement
      }));
      this.props.dispatch({
        type: "DO_AGGREMENT_APPROVED",
        payload: {
          userId: this.props.userDetails._id,
          Signature: this.sigCanvas.current.getSignaturePad().toDataURL("image/png")
        }
      });
    } else {
      alert("Please read the agreement and sign your digital signature.")
    }    
  }
  logout() {
    this.props.dispatch({
      type: "DO_LOGOUT"
    });
  }
  toggleResetModal() {
    this.setState((prevState) => ({
      toggleResetModal: !prevState.toggleResetModal
    }));
  }
  toggleFollowUpReminderModal() {
    this.setState({
      toggleFollowUpReminderModal: true,
      toggleNeedToFollowUpModal: false,
      startDate: "",
      startDateError: null,
      endDate: "",
      endDateError: null,
      sortOrder : '',
      sortField : null,
      search: "",
      enrolledPrograms: "",
      page: 1,
      limit: 10
    },() => {
      this.fetchData();
    });
  }
  toggleNeedToFollowUpModal() {
    this.setState({
      toggleNeedToFollowUpModal: true,
      toggleFollowUpReminderModal: false,
      startDate: "",
      startDateError: null,
      endDate: "",
      endDateError: null,
      sortOrder : '',
      sortField : null,
      search: "",
      enrolledPrograms: "",
      page: 1,
      limit: 10
    },() => {
      this.fetchData();
    });
  }
  closeModel(){
    this.setState({
      toggleNeedToFollowUpModal: false,
      toggleFollowUpReminderModal: false,
      startDate: "",
      startDateError: null,
      endDate: "",
      endDateError: null,
      sortOrder : '',
      sortField : null,
      search: "",
      enrolledPrograms: "",
      page: 1,
      limit: 10
    });
  }
  toggleModalNoReadings() {
    // this.props.dispatch({
    //   type: "DO_GET_NO_READINGS"
    // });
    this.setState((prevState) => ({
      toggleModalNoReadings: !prevState.toggleModalNoReadings
    }));
  }
  toggleModalClientList() {
    // this.props.dispatch({
    //   type: "DO_GET_CLIENT_LIST"
    // });
    this.setState((prevState) => ({
      toggleModalClientList: !prevState.toggleModalClientList
    }));
  }
  togglemodalNewUser() {
    this.setState((prevState) => ({
      togglemodalNewUser: !prevState.togglemodalNewUser
    }));
  }
  toggleReset(fullname, patientId) {
    const previousState = this.state.modalReset;

    this.setState({
      modalReset: !this.state.modalReset,
      resetFullname: fullname,
      resetPatientId: patientId
    });
    if (previousState) {
      this.resetStateBack();
    }
  }
  resetForm() {
    this.setState({
      toggleResetModal: false,
      modalReset: false,
      errorPassword: false,
      resetFullname: "",
      resetPatientId: "",
      showWarning: false
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    this.props.dispatch({
      type: "DO_GET_USERS_YOU_HAVE_ACCESS",
      payload: { client: true }
    });
    // this.props.dispatch({
    //   type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    // });
  }
  submitReset(e) {
    e.preventDefault();
    if (this.state.errorPassword) {
      return;
    }
    this.setState({
      showWarning: !this.state.showWarning
    });
  }
  clearFilter(){
    this.setState({
      startDate: "",
      startDateError: null,
      endDate: "",
      endDateError: null,
      search: "",
      enrolledPrograms: "",
      page: 1,
      limit: 10,
    }, () => this.fetchData());
  }
  submitResetPassword() {
    this.props.dispatch({
      type: "DO_RESET_PATIENT_PASSWORD",
      payload: {
        user: this.state.resetPatientId,
        password: this.state.password
      }
    });
    this.setState({
      showWarning: !this.state.showWarning
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }
  resetStateBack() {
    this.setState({
      toggleResetModal: false,
      errorPassword: false,
      resetFullname: "",
      resetPatientId: ""
    });
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_GET_NO_READINGS"
    });
    this.props.dispatch({
      type: "DO_GET_CLIENT_LIST"
    });
    this.props.dispatch({
      type:"DO_GET_NEW_PATIENT"
    })    
    this.props.dispatch({
      type: "DO_GET_ALL_PATIENT_COUNT"
    });
    // this.fetchData()
    // this.props.dispatch({
    //   type: "DO_GET_USERS_YOU_HAVE_ACCESS",
    //   payload: { client: true }
    // });   
    if (!this.props.userDetails.isAgreedAggrement || !this.props.userDetails.isAgreedPrivacyPolicy) {
      this.setState({ modalAgreement: true });
      console.log("STATUS")
    }     
  }
  fetchData() {
    if(this.state.startDate){
      const startDateCheck = this.validatedate(this.state.startDate,'startDate');
      if(!startDateCheck){
        return
      }
    }
    if(this.state.endDate){
      const endDateCheck = this.validatedate(this.state.endDate,'endDate');
      if(!endDateCheck){
        return
      }
    }
    // Check whether start date is choose by customer or not if chooses end date then
    if(this.state.endDateError || this.state.startDateError){
      return;
    }
    // to get reminder sent user pass selectedApi pass "ReminderSent" and to get reminderd pass "FollowUpReminder"
        this.props.dispatch({
          type: "DO_GET_ALL_PATIENT",
          payload: {
            page: this.state.page,
            limit: this.state.limit,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            sortField: this.state.sortField ? this.state.sortField : "",
            sortOrder: this.state.sortOrder && this.state.sortField ? this.state.sortOrder : "",
            search: this.state.search ? this.state.search : "",
            enrolledPrograms: this.state.enrolledPrograms ? this.state.enrolledPrograms : "",
            selectedApi: this.state.toggleNeedToFollowUpModal ? 'NeedToFollowUp' : 'ReminderSent',   
            timezone: moment.tz.guess()
          }
        });
  }
  handleTableChange(type, { page, sizePerPage, filters, sortField, sortOrder }) {
    var sortNewOrder;
    const payload = {
      page: page, limit: sizePerPage
    }
    if(!_.isEmpty(sortField)) {
      payload.sortField = sortField
    }
    if(type == 'sort'){
      if(this.state.sortOrder == "desc"){
        if(!_.isEmpty(sortField)) {
          sortNewOrder = "asc";
        }else{
          sortNewOrder = "desc";
        }
        sortNewOrder = "asc";
        payload.sortOrder = sortNewOrder;
        this.setState(
          payload
        , () => {
          this.fetchData();
        });    
      }else{
        if(!_.isEmpty(sortField)) {
          sortNewOrder = "desc"
        }else{
          sortNewOrder = "asc";
        }
        sortNewOrder = "desc"
        payload.sortOrder = sortNewOrder;
        this.setState(
          payload
        , () => {
          this.fetchData();
        });    
    }
    }else{
      this.setState(
        payload
      , () => {
        this.fetchData();
      });  
    }

  }

  handleChange(e){
      e.preventDefault();
      const val = e.target.value
      this.setState({
        page: 1,
        search: val,
        sortField: "",
        sortOrder: "",
      });
      if (this.state.timeout) {
        clearTimeout(this.state.timeout);
      }
      const newTimeoutId = setTimeout(() => {
        this.fetchData();        
      }, 500)
      this.setState({ timeout: newTimeoutId });
    };
   handleInputDateChange(event) {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      this.setState(
        { 
          [name]: value }, () => {
          const check = this.validatedate(value,name);
          if(check){
            this.setState({
              endDateError: null,
              startDateError : null
            }, () => this.fetchData());
          }
        }
      );
  }
  validatedate(date,name){
    const dateParts = date.split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);
   
    if(name == 'startDate'){
      this.setState({
        startDateError: null,
      });
    }else{
      this.setState({
        endDateError: null
      });
    }
    // JavaScript Date object handles invalid dates by returning "Invalid Date"
    const isValid = !isNaN(year) && !isNaN(month) && !isNaN(day);
    const todayDate = moment().format('YYYY-MM-DD');
    if(!isValid){
      if(name == 'startDate'){
        this.setState({startDateError : null}, () => this.fetchData())
      }if(name == 'endDate'){
        this.setState({endDateError : null}, () => this.fetchData())
      }
    } else if (year.toString().length != 4) {
      if(name == 'startDate'){
        this.setState({ startDateError : "Please select proper formmated start date." });
        return false;
      }else{
        this.setState({ endDateError: "Please select proper formmated end date." });
        return false;
      }
    }else if(this.state.startDate && this.state.endDate && this.state.startDate > this.state.endDate){
      this.setState({ endDateError: "Please select end date greater than start day." });
      if(date > todayDate){
        if(name == 'startDate'){
          this.setState({ startDateError : "Please select start date less than today." });
        }
      }
      return false;
    }else{
      // Check date is greter than today or not
      if(date > todayDate){
        if(name == 'startDate'){
          this.setState({ startDateError : "Please select start date less than today." });
        }else{
          this.setState({ endDateError: "Please select end date less than today." });
        }
      }else{
        const isValid = !isNaN(year) && !isNaN(month) && !isNaN(day);
        if(isValid && year.toString().length == 4){
          return true;
        }
      }
     
    }
  }
  filterEnrolledPrograms(event) {
    if (event.target.value) {
      this.setState({
        page: 1,
        sortField: "",
        sortOrder: "",
        enrolledPrograms: event.target.value
      }, () => {
        this.fetchData();
      });
    } else {
      this.setState({
        enrolledPrograms: undefined
      }, () => {
        this.fetchData();
      });
    }
  }
  render() {      
    return (
      <Container className="align-middle">
        {/* {!this.props.noReadings && !this.props.newPatients && !this.props.clientList && (
            <ReactLoading className="mx-auto" color={"#007bff"} />
        )} */}
        {/*{!this.props.ongoingRequest && this.props.noReadings && (  */}
        <Row className="">
          <Col md="3" className="text-center px-1 py-1 cursor-pointer">
            <Card
              body
              className="px-1 card-design"
              onClick={this.toggleResetModal}
            >
              {/*"https://www.flaticon.com/premium-icon/icons/svg/1458/1458520.svg"*/}
              <CardText>
                <img
                  src={resetPasswordImage}
                  width="140"
                  height="140"
                  border="0"
                  className="img-fluid"
                />
                <p className="text-center" style={{ marginTop: 15 }}>
                  {" "}
                  RESET USER'S PASSWORD
                </p>
              </CardText>
            </Card>
          </Col>
          <Col md="3" className="text-center px-1 py-1 cursor-pointer">
            <Card
              body
              className="px-1 card-design"
              onClick={this.togglemodalNewUser}
            >
              {/*"https://image.flaticon.com/icons/png/512/559/559475.png"*/}
              <CardText>
                <img
                  src={addUsersImage}
                  width="140"
                  height="140"
                  border="0"
                  className="img-fluid"
                />
                <span class="bg-gradient-info notify-badge badge ">
                  {_.size(this.props.newPatients)}
                </span>
                <p className="text-center" style={{ marginTop: 15 }}>
                  NEW PATIENTS (WITHIN WEEK)
                </p>
              </CardText>
            </Card>
          </Col>
          <Col md="3" className="text-center px-1 py-1 cursor-pointer">
            <Card
              body
              className="px-1 card-design"
              onClick={this.toggleModalNoReadings}
            >
              <CardText>
                <img
                  src={clipboardImage}
                  width="140"
                  height="140"
                  border="0"
                  className="img-fluid"
                />
                <span class="bg-gradient-info notify-badge badge ">
                  {this.props.noReadings ? this.props.noReadings.count : 0}
                </span>
                <p className="text-center" style={{ marginTop: 15 }}>
                  NO READING UPLOADS (PAST 6 DAYS)
                </p>
              </CardText>
            </Card>
          </Col>
          <Col md="3" className="text-center px-1 py-1 cursor-pointer">
            <Card
              body
              className="px-1 card-design"
              onClick={this.toggleModalClientList}
            >
              <CardText>
                <img
                  src={clientsImage}
                  width="140"
                  height="140"
                  border="0"
                  className="img-fluid"
                />
                <span class="bg-gradient-info notify-badge badge ">
                  {this.props.clientList ? this.props.clientList.length : 0}
                </span>
                <p className="text-center" style={{ marginTop: 15 }}>
                  CLIENTS/ORGANIZATIONS
                </p>
              </CardText>
            </Card>
          </Col>
          <Col md="3" className="text-center px-1 py-1 cursor-pointer">
            <Card
              body
              className="px-1 card-design"
              onClick={this.toggleFollowUpReminderModal}
            >
              <CardText>
                <img
                  src={reminderSentImage}
                  width="140"
                  height="140"
                  border="0"
                  className="img-fluid"
                />
                <span class="bg-gradient-info notify-badge badge ">
                  {this.props.allPatientCount &&
                  this.props.allPatientCount.reminderSent
                    ? this.props.allPatientCount.reminderSent
                    : this.props.totalRecode ? this.props.totalRecode : 0}
                </span>
                <p className="text-center" style={{ marginTop: 15 }}>
                  FOLLOW UP REMINDERS SENT
                </p>
              </CardText>
            </Card>
          </Col>
          <Col md="3" className="text-center px-1 py-1 cursor-pointer">
            <Card
              body
              className="px-1 card-design"
              onClick={this.toggleNeedToFollowUpModal}
            >
              <CardText>
                <img
                  src={followUpImage}
                  width="185"
                  height="185"
                  border="0"
                  className="img-fluid"
                />
                <span class="bg-gradient-info notify-badge badge ">
                  {this.props.allPatientCount &&
                  this.props.allPatientCount.needToFollowUp 
                    ? this.props.allPatientCount.needToFollowUp : 0}
                </span>
                <p className="text-center" style={{ marginTop: 15 }}>
                  NEED TO FOLLOW UP
                </p>
              </CardText>
            </Card>
          </Col>
          {/*
              <DeleteUserAction
                users={this.props.usersOfferedAccess}
                readingSubTypes={this.props.readingSubTypes}
                userId={this.props.userId}
              />
              <Col md="3" className="text-center px-1 py-1">
                <Card body className="px-1" onClick={this.toggleResetModal}>
                  <CardText>
                    <img
                      src={transferImage}
                      width="140"
                      height="140"
                      border="0"
                      className="img-fluid"
                    />
                    <div className="text-center">TRANSFER USER TO CLIENT</div>
                  </CardText>
                </Card>
              </Col>
              <Col md="3" className="text-center px-1 py-1">
                <Card body className="px-1">
                  <CardText>
                    <img
                      src="https://image.flaticon.com/icons/svg/2390/2390318.svg"
                      width="140"
                      height="140"
                      border="0"
                      className="img-fluid"
                    />
                    <div className="text-center">
                      MONTHLY PATIENT COUNT AND RATE (MAINTENANCE)
                    </div>
                  </CardText>
                </Card>
              </Col>*/}

          {/* <Col md="3" className="text-center px-1 py-1">
                <Card body className="px-1">
                  <CardText>
                    <img
                      src="https://www.flaticon.com/premium-icon/icons/svg/1465/1465600.svg"
                      width="140"
                      height="140"
                      border="0"
                      className="img-fluid"
                    />
                    <div className="text-center">
                      ACTIVITY LOGS (MAINTENANCE)
                    </div>
                  </CardText>
                </Card>
              </Col> */}
          <Modal
            isOpen={this.state.toggleResetModal}
            toggle={this.toggleResetModal}
            className={this.props.className}
            size="lg"
          >
            <ModalHeader
              className="close_button"
              toggle={this.toggleResetModal}
            >
              <button
                style={{ float: "right" }}
                className="btn btn-info ml-2 close-click-cls"
                onClick={this.toggleResetModal}
              >
                <i
                  class="mdi mdi-close"
                  style={{
                    fontSize: 20,
                  }}
                ></i>
                Close
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              {/* <PatientList
                    usersGivenAccessMyReading={this.props.usersOfferedAccess}
                    toggleReset={this.toggleReset}
                    readingSubTypes={this.props.readingSubTypes}
                    userId={this.props.userId}
                    deletePermission={this.deletePermission}
                    approvePermission={this.approvePermission}
                    receivedFrom={false}
                  /> */}{" "}
              <h1 className="text-center">
                This part has been moved to Accounts Management Section
              </h1>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.togglemodalNewUser}
            toggle={this.togglemodalNewUser}
            className={this.props.className}
            size="lg"
          >
            <ModalHeader
              className="close_button"
              toggle={this.togglemodalNewUser}
            >
              New Patients This Week
              <button
                style={{ float: "right" }}
                className="btn btn-info ml-2 close-click-cls"
                onClick={this.togglemodalNewUser}
              >
                <i
                  class="mdi mdi-close"
                  style={{
                    fontSize: 20,
                  }}
                ></i>
                Close
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              {this.props.newPatients && (
                <NewUser
                  usersGivenAccessMyReading={this.props.newPatients}
                  toggleReset={this.toggleReset}
                  readingSubTypes={this.props.readingSubTypes}
                  userId={this.props.userId}
                  deletePermission={this.deletePermission}
                  approvePermission={this.approvePermission}
                  receivedFrom={false}
                />
              )}
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.toggleModalNoReadings}
            toggle={this.toggleModalNoReadings}
            className={this.props.className}
            size="lg"
            style={{ maxWidth: "98%" }}
          >
            <ModalHeader
              className="close_button"
              toggle={this.toggleModalNoReadings}
            >
              Patients with No Readings past 6 Days
              <button
                style={{ float: "right" }}
                className="btn btn-info ml-2 close-click-cls"
                onClick={this.toggleModalNoReadings}
              >
                <i
                  class="mdi mdi-close"
                  style={{
                    fontSize: 20,
                  }}
                ></i>
                Close
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              {/* {JSON.stringify(this.props.noReadings.users)} */}
              <NoReading
                usersGivenAccessMyReading={
                  this.props.noReadings ? this.props.noReadings.users : []
                }
              />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.toggleModalClientList}
            toggle={this.toggleModalClientList}
            className={this.props.className}
            size="lg"
          >
            <ModalHeader
              className="close_button"
              toggle={this.toggleModalClientList}
            >
              Client List
              <button
                style={{ float: "right" }}
                className="btn btn-info ml-2 close-click-cls"
                onClick={this.toggleModalClientList}
              >
                <i
                  class="mdi mdi-close"
                  style={{
                    fontSize: 20,
                  }}
                ></i>
                Close
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              {/* {JSON.stringify(this.props.noReadings.users)} */}
              <ClientList
                usersGivenAccessMyReading={
                  this.props.noReadings ? this.props.clientList : []
                }
              />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.toggleFollowUpReminderModal || this.state.toggleNeedToFollowUpModal}
            toggle={this.toggleFollowUpReminderModal}
            className={this.props.className}
            size="lg"
          >
            <ModalHeader
              className="close_button"
              toggle={this.toggleFollowUpReminderModal}
            >
              {this.state.toggleNeedToFollowUpModal ? 'Need To Follow Up' : 'Follow Up Reminder Sent Patients'}
              <button
                style={{ float: "right" }}
                className="btn btn-info ml-2 close-click-cls"
                onClick={this.closeModel}
              >
                <i
                  class="mdi mdi-close"
                  style={{
                    fontSize: 20,
                  }}
                ></i>
                Close
              </button>
            </ModalHeader>
            <ModalBody className="bg-white">
              <Row className="col-sm-12 main-cls-modal remove-table-padding">
                <Col sm="12" className="mt-12">
                  <div>
                    <Row
                      className={`col-md-12 mx-auto float-right remove-pading-table`}
                      style={{ marginTop: 35 }}
                    >
                      <Col md={3}>
                        <FormGroup className="remove-bottom-margin form-group-patient">
                          <Label for="exampleEmail">
                            Start Date {this.state.toggleFollowUpReminderModal ? '(Reminder Date)' : '(Enrolled Date)'}:
                          </Label>
                          <Input
                          className="input-date-filter remove-bottom-margin"
                          type="date"
                          name="startDate"
                          max={this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                          onChange={this.handleInputDateChange}
                          value={this.state.startDate}
                          />
                          <Label
                            style={{
                              color: "red",
                              textAlign: "left",
                              padding: 5,
                            }}
                          >
                            {this.state.startDateError}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className="remove-bottom-margin form-group-patient">
                          <Label for="examplePassword">
                            End Date {this.state.toggleFollowUpReminderModal ? '(Reminder Date)' : '(Enrolled Date)'}:
                          </Label>
                          <Input
                          className="input-date-filter remove-bottom-margin"
                          type="date"
                          name="endDate"
                          max={moment().format("YYYY-MM-DD")}
                          onChange={this.handleInputDateChange}
                          value={this.state.endDate}
                        />
                          <Label
                            style={{
                              color: "red",
                              textAlign: "left",
                              padding: 5,
                            }}
                          >
                            {this.state.endDateError}
                          </Label>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <FormGroup className="remove-bottom-margin form-group-patient">
                          <Label for="examplePassword">
                            Enrolled Programs:&nbsp;&nbsp;
                          </Label>
                          <select
                            className="filter-Questionnaire"
                            onChange={this.filterEnrolledPrograms}
                            value={this.state.enrolledPrograms}
                          >
                            <option value="">Select Enrolled Program</option>
                            <option value="CCM">CCM</option>
                            <option value="RPM">RPM</option>
                            <option value="CCM&RPM">CCM&RPM</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className="remove-bottom-margin form-group-patient">
                          <Label for="examplePassword">&nbsp;&nbsp;</Label>
                          {(this.state.startDate ||
                            this.state.endDate ||
                            this.state.search ||
                            this.state.enrolledPrograms) && (
                            <button
                              style={{
                                float: "left",
                                marginTop: 20,
                                width: 150,
                                height: 37,
                              }}
                              onClick={() => {
                                this.clearFilter();
                              }}
                              className="btn btn-info ml-2 close-click-cls"
                            >
                              <i
                                class="mdi mdi-close"
                                style={{
                                  fontSize: 20,
                                }}
                              ></i>
                              Clear Filters
                            </button>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      className={`text-center col-md-12 mx-auto float-right remove-pading-table`}
                    >
                      <Col md={12} style={{ marginTop: 15 }}>
                        <div>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control input-date-filter remove-bottom-margin"
                              id="exampleInputName1Test"
                              style={{ fontSize: "20px" }}
                              placeholder="Search Last Name, First Name, Nickname, Organization, Enrolled Programs"
                              value={this.state.search}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {this.props.ongoingPatientListRequest && (
                    <ImprovedPatientList
                      data={[]}
                      page={this.state.page}
                      loading={this.props.ongoingPatientListRequest}
                      sizePerPage={this.state.limit}
                      profileOwner={this.props.profileOwner}
                      readingSubTypes={this.props.readingSubTypes}
                      userId={this.props.userId}
                      totalSize={0} 
                      onTableChange={this.handleTableChange}
                      displayFollowUpReminderList={null}
                      displayActiveList={null}
                      displayCellularConnectivityList={null}
                      displayNeedToFollowUpList={this.state.toggleNeedToFollowUpModal ? true : false}
                      displayHospitalAddmissionList={null}
                      displayItIssuesList={null}
                      displayReminderSentList={this.state.toggleFollowUpReminderModal ? true : false}
                    />
                  )}
                  {!this.props.ongoingPatientListRequest &&
                    this.props.allPatientOfferedAccess && (
                      <ImprovedPatientList
                        data={this.props.allPatientOfferedAccess}
                        page={this.state.page}
                        loading={this.props.ongoingPatientListRequest}
                        sizePerPage={this.state.limit}
                        profileOwner={this.props.profileOwner}
                        readingSubTypes={this.props.readingSubTypes}
                        userId={this.props.userId}
                        totalSize={this.props.totalRecode}
                        displayFollowUpReminderList={null}
                        onTableChange={this.handleTableChange}
                        displayActiveList={null}
                        displayCellularConnectivityList={null}
                        displayNeedToFollowUpList={this.state.toggleNeedToFollowUpModal ? true : false}
                        displayHospitalAddmissionList={null}
                        displayItIssuesList={null}
                        displayReminderSentList={this.state.toggleFollowUpReminderModal ? true : false}
                      />
                    )}
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <ResetPassword
            modalReset={this.state.modalReset}
            toggleReset={this.toggleReset}
            fullname={this.state.resetFullname}
            errorPassword={this.state.errorPassword}
            submitReset={this.submitReset}
            handleInputChange={this.handleInputChange}
          />
          <SweetAlert
            warning
            showCancel
            show={this.state.showWarning}
            confirmBtnText="Yes, reset the password now!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={this.submitResetPassword}
            onCancel={this.submitReset}
          >
            This will change the password of these user
          </SweetAlert>
          {/* <SweetAlert
                success
                title="Success!"
                show={this.props.isGenericSuccess}
                onConfirm={this.resetForm}
                confirmBtnBsStyle="info"
              >

                <div>Your action is successful</div>
              </SweetAlert> */}
              <SweetAlert
                error
                title={this.props.genericErrorMessage.message}
                show={this.props.isGenericError}
                onConfirm={() => {
                  this.props.dispatch({
                    type: "GENERIC_ERROR"
                  });
                }}
                confirmBtnBsStyle="danger"
                timeout={1000}
              >
                Error Code: {this.props.genericErrorMessage.error}
              </SweetAlert>
            </Row>
          
          <Modal
            isOpen={this.state.modalAgreement}
            size="lg"
            toggle={this.logout}
            backdrop="static"
            className={this.props.className}
          >
            <ModalHeader toggle={this.logout} charCode="">
              <Label className="align-items-center justify-content-center text-center model-title-size">Terms and Conditions & Hipaa Business Associate Agreement</Label>
            </ModalHeader>
            <ModalBody className="modal-body-remove-padding">
              <embed src={pdf} frameBorder={0} width="100%" height="500px" />
              <div>
                <Row>
                  <Col md="8">
                    <div style={{paddingLeft:19, paddingTop: "2%"}}>
                      <p><span >I have carefully reviewed and understand the terms and conditions outlined in the aforementioned documents. I hereby give my consent for the collection, use, and processing of my personal information in accordance with the terms and conditions of the Privacy Policy. I am aware that my continued use of <strong>MyVitalvView's</strong> services is subject to my acceptance of these terms.</span></p>                   
                      <p><span >If you accept, please locate a digital signature pad and sign on the right side, confirming <strong>MyVitalview</strong> agreement.</span></p>                    
                      <p className="remove-padding-margin" style={{fontWeight:600}}><span >I appreciate your attention to this agreement. If you need any further information or documents regarding this agreement, please contact us at <strong>info@myvitalview.com</strong>.</span></p>
                      <p><span >Thank you for your precious time for reading this.</span></p>
                      <p className="remove-padding-margin"><span >Sincerely,</span>
                      <br/>
                      <span ><strong>MyVitalView Team</strong></span></p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="signature-div-style">
                      <SignatureCanvas
                        ref={this.sigCanvas}
                        penColor="black"
                        canvasProps={{ width: 350, height: 150 }}
                      />
                      <code><hr className="header-line-style" />
                        <button className="clear-signature" onClick={() => this.sigCanvas.current.clear()}>Clear</button></code>
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="aggre-click-cls" color="info" onClick={this.toggleAgreement}>
                <i class="mdi mdi-checkbox-marked" style={{
                  fontSize: 20,
                  olor:"white"
                }}></i> Agree
              </Button>{" "}
              <Button color="black" className="logout-click-cls" onClick={this.logout}>
                <i class="mdi mdi-exit-to-app" style={{
                  fontSize: 20,
                  color:"white"
                }}></i> Logout
              </Button>
            </ModalFooter>
          </Modal>
      </Container>
    );
  }
}

const mapStateToProps = ({ careprovider, user, auth, support }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    usersOfferedAccessOrganization,
    ongoingRequest,
    allPatientOfferedAccess,
    totalRecode,
    ongoingPatientListRequest,
    allPatientCount
  } = careprovider;
  const { readingSubTypes } = auth;
  const { noReadings, clientList,newPatients } = support;
  const userId = user.userDetails._id;
  const userDetails = user.userDetails;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccess,
    readingSubTypes,
    usersOfferedAccessOrganization,
    userId,
    noReadings,
    clientList,
    newPatients,
    ongoingRequest,
    allPatientOfferedAccess,
    totalRecode,
    ongoingPatientListRequest,
    allPatientCount,
    userDetails
  };
};

export default connect(mapStateToProps)(AdminHome);
