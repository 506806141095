import React, { PureComponent } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Col,
  Row,
  Label,
  Input,
  FormText,
  FormFeedback
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BP from "../../files/devices/eocene_image_blood_monitor_transparent.png";
import GLUCOSE from "../../files/devices/eocene_image_glucose_analyzertransparent.png";
import TEMPERATURE from "../../files/devices/eocene_image_infrared_ear_and_body_termotransparent.png";
import WEIGH from "../../files/devices/eocene_image_weight_scaletransparent.png";
import PULSE from "../../files/devices/eocene_pulseoxygenetransparent.png";
import BODYTRACEBP from "../../files/devices/body_trace_blood_pressure.png";
import BODYTRACEWEIGHT from "../../files/devices/body_trace_weight_scale.png";
import ThirdPartyDevices from "./ThirdPartyDevices";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
const _ = require("lodash");
class Devices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      deviceId: null,
      deviceUser: null,
      openModal: false
    };
    this.submitUser = this.submitUser.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.deleteDevice = this.deleteDevice.bind(this);
    this.deleteDeviceNow = this.deleteDeviceNow.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }

  resetForm() {
    this.setState({
      modal2: false,
      modal: false,
      modalUser: false,
      fname: "",
      lname: "",
      email: "",
      username: ""
    });
  }
  openModal() {
    this.setState({
      openModal: !this.state.openModal
    });
    /*if (this.state.openModal && this.props.hideTitle) {
      setTimeout(() => {
        if(this.props.onCurrentLoginProfile != undefined && this.props.onCurrentLoginProfile){
          this.props.dispatch({
            type: "DO_GET_PROFILE"
          });
        }else{
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId }
        });
        }
      }, 400);
    } else {
    }*/
  }
  submitUser(e) {
    e.preventDefault();
    const cleanUser = this.state;
    this.props.dispatch({
      type: "DO_SAVE_USER",
      payload: cleanUser
    });
  }
  componentDidMount() {
    if (this.props.hideTitle) {
    } else {
      this.props.dispatch({
        type: "DO_GET_DEVICES",
        payload: { id: this.props.currentUserId }
      });
    }
  }
  fullname(cell, row) {
    if (!row.device) {
      return <div>Wrong Device</div>;
    }
    if (row.device.brand.indexOf("Body Trace Blood") > -1)
      return (
        <div className="text-center">
          <img height="200px" src={BODYTRACEBP} className="rounded-circle img-fluid reading-icon-summary"/>
        </div>
      );
    if (row.device.brand.indexOf("Body Trace Weight") > -1)
      return (
        <div className="text-center">
          <img height="200px" src={BODYTRACEWEIGHT} className="rounded-circle img-fluid reading-icon-summary"/>
        </div>
      );
    if (row.device.name.indexOf("Blood") > -1)
      return (
        <div className="text-center">
          <img height="200px" src={BP} className="rounded-circle img-fluid reading-icon-summary"/>
        </div>
      );
    if (row.device.name.indexOf("Glucose") > -1)
      return (
        <div className="text-center">
          <img src={GLUCOSE} className="rounded-circle img-fluid reading-icon-summary"/>
        </div>
      );
    if (row.device.name.indexOf("Thermometer") > -1)
      return (
        <div className="text-center">
          <img src={TEMPERATURE} className="rounded-circle img-fluid reading-icon-summary"/>
        </div>
      );
    if (row.device.name.indexOf("Weigh") > -1)
      return (
        <div className="text-center">
          <img src={WEIGH} className="rounded-circle img-fluid reading-icon-summary"/>
        </div>
      );
    if (row.device.name.indexOf("Pulse") > -1)
      return (
        <div className="text-center">
          <img src={PULSE} className="rounded-circle img-fluid reading-icon-summary"/>
        </div>
      );
    return <div className="text-center"> - </div>;
  }
  deleteButton(cell, row, rowIndex, formatExtraData) {
    if (row.device.brand === "BodyTrace") {
      return <div className='text-center remove-device-btn'><span>Remove IMEI To Remove Device</span></div> ;
    }
    return (
      <button
        type="button"
        onClick={() => formatExtraData.delete(row._id, row.user)}
        className="btn btn-info ml-2 delete-note-click"><i className="mdi mdi-delete" /> Delete </button>
    );
  }
  deleteDevice(deviceId, user) {
    this.setState({
      confirmDelete: true,
      deviceId: deviceId,
      deviceUser: user
    });
  }
  cancelDelete() {
    this.setState({ confirmDelete: false, deviceId: null, deviceUser: null });
  }
  deleteDeviceNow() {
    this.props.dispatch({
      type: "DO_DELETE_DEVICE",
      payload: {
        deviceId: this.state.deviceId,
        isPatient: this.props.patient ?? this.props.currentUserId,
        deviceUser: this.state.deviceUser._id
      }
    });
    this.setState({ confirmDelete: false, deviceId: null, deviceUser: null });
    /*if (this.props.hideTitle) {
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId }
        });
      }, 400);
    }*/
    setTimeout(() => {
      this.props.dispatch({
        type: "DO_GET_DEVICES",
        payload: { id: this.props.currentUserId }
      });
    }, 400);
  }
  render() {
    let deviceList;
    if (this.props.patient) {
      deviceList = this.props.deviceList;
    } else {
      deviceList = this.props.devices;
    }
    if (this.props.intervention && this.props.deviceList) {
      deviceList = _.filter(this.props.deviceList, function (o) {
        return o.device.brandModel != "SD-APP";
      });
    }
    return (
      <div
        className={this.props.patient ? "col-12 mx-auto" : "col-10 mx-auto"}>
        <Row className="header-device-list">
          <Col sm="8" className="center-label remove-table-padding">
            {!this.props.hideTitle && (
              <h3 className="text-center title-device">
              <i class="mdi mdi-sign-text"></i> My Devices
            </h3>
            )}
            {this.props.hideTitle && (
              <h3 className="text-center title-device">
              <i class="mdi mdi-sign-text"></i> Devices&nbsp;
              {"("+this.props.profile.fname}{" "}
              {this.props.profile.lname+")"}
            </h3>
            )}
          </Col>
          <Col sm="4" className="remove-table-padding">
            <div className="text-center">
              {" "}
              <a target="_blank"
                onClick={this.openModal}
                className="btn btn-info ml-2 view-log-click-cls add-device-btn">
                <i class="mdi mdi-plus"></i> Add a Device
              </a>
              <ThirdPartyDevices
                modal={this.state.openModal}
                user={this.props.currentUserId}
                resetToggle={this.openModal}
              ></ThirdPartyDevices>
            </div>
          </Col>
        </Row>
        <div className="scrollbar-table-cls">
        <BootstrapTable
          wrapperClasses="table-responsive"
          keyField="id"
          filterPosition="top"
          data={deviceList && _.size(deviceList) > 0 ? deviceList : []}
          noDataIndication={() => (
            <div>
              <LottieLoader
                  loadLoop={false}
                  autoplayLoop={true}
                  source={lottieEmptyFile}
                  Lheight={200}
                  Lwidth={200}
                  titleSmall={"Sorry! No device found :("}
                  subTitleSmall={"We can't find any devices."}
                />
            </div>
          )}
          columns={[
            {
              dataField: "_id",
              text: "Icon",
              formatter: this.fullname,
              headerStyle: (colum, colIndex) => {
                return { 
                  padding: "16px 8px",
                  fontSize: "15px",
                  width: "150px",
                  textAlign: "center"
                };
              }
            },
            {
              dataField: "serial",
              text: "Serial Number",             
              headerStyle: (colum, colIndex) => {
                return { 
                  padding: "16px 8px",
                  fontSize: "15px" 
                };
              },   
              filter: this.props.intervention ? false : textFilter({
                className: 'input-date-filter',
                placeholder:"Enter Serial Number"
              }),
              sort: true
            },
            {
              dataField: "device.brand",
              text: "Device Brand",
              headerStyle: (colum, colIndex) => {
                return { 
                  padding: "16px 8px",
                  fontSize: "15px" 
                };
              },
              filter: this.props.intervention ? false : textFilter({
                className: 'input-date-filter',
                placeholder:"Enter Device Brand"
              }),
              sort: true
            },
            {
              dataField: "device.name",
              text: "Device",
              headerStyle: (colum, colIndex) => {
                return { 
                  padding: "16px 8px",
                  fontSize: "15px" 
                };
              },
              filter: this.props.intervention ? false : textFilter({
                className: 'input-date-filter',
                placeholder:"Enter Device Name"
              }),
              sort: true
            },

            {
              dataField: "device.brandModel",
              text: "Device Model",
              headerStyle: (colum, colIndex) => {
                return { 
                  padding: "16px 8px",
                  fontSize: "15px" 
                };
              },
              filter: this.props.intervention ? false : textFilter({
                className: 'input-date-filter',
                placeholder:"Enter Device Model"
              }),
              sort: true
            },
            {
              dataField: "performTest",
              text: "Perform Test",
              formatter: (cell, row) => {
                return row.performTest ? row.performTest : "-";
              },
              headerStyle: (colum, colIndex) => {
                return { 
                  padding: "16px 8px",
                  fontSize: "15px" 
                };
              },
              filter: this.props.intervention ? false : textFilter({
                className: 'input-date-filter',
                placeholder:"Enter Perform Test"
              }),
              sort: true
            },
            {
              dataField: "testingFrequency",
              text: "Testing Frequency",
              formatter: (cell, row) => {
                return row.testingFrequency ? row.testingFrequency : "-";
              },
              headerStyle: (colum, colIndex) => {
                return { 
                  padding: "16px 8px",
                  fontSize: "15px" 
                };
              },
              sort: true
            },
            {
              dataField: "dummy",
              text: "Action",
              headerStyle: (colum, colIndex) => {
                return { 
                  width: '150px', textAlign: 'center',
                  padding: "16px 8px",
                  fontSize: "15px" 
                };
              },
              hidden: this.props.intervention ? true : false,
              formatter: this.deleteButton,
              formatExtraData: {
                delete: this.deleteDevice
              }
            }
          ]}
          filter={filterFactory()}
        />
        </div>
        <SweetAlert
          warning
          showCancel
          show={this.state.confirmDelete}
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure deleting this patient device?"
          onConfirm={this.deleteDeviceNow}
          onCancel={this.cancelDelete}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ user, careprovider }) => {
  const { usersOfferedAccess, timerStarts } = careprovider;
  const { authenticated, userDetails, isRegister, profile, devices } = user;
  return {
    authenticated,
    userDetails,
    isRegister,
    usersOfferedAccess,
    timerStarts,
    profile,
    devices
  };
};

export default connect(mapStateToProps)(Devices);
