import React, { Component } from "react";
import { connect } from "react-redux";

import ReactToPrint from "react-to-print";
import Select from "react-select";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from "react-day-picker/moment";
import "react-day-picker/lib/style.css";

import Link from "components/ExtendLink";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Form,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  CardImg,
  CardBody,
  Col,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  CardSubtitle
} from "reactstrap";

import ReactLoading from "react-loading";
import classnames from "classnames";
import FilterExceptionCard from "./FilterExceptionCard";
import FilterConditionCard from "./FilterConditionCard";
import TableSummary from "./TableSummary";
import ViewGlobal from "./ViewGlobal";
const _ = require("lodash");

class index extends Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleFromChange2 = this.handleFromChange2.bind(this);
    this.handleToChange2 = this.handleToChange2.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      from2: undefined,
      to2: undefined,
      readingType: undefined,
      readingSubType: undefined,
      readingTypeValue: undefined,
      readingSubTypeValue: undefined,
      condition: undefined,
      value: undefined,
      minValue: undefined,
      maxValue: undefined,
      violation: undefined,
      ruleType: undefined,
      readingType2: undefined,
      readingType2Value: undefined,
      readingSubType2: undefined,
      pickSelect: "none",
      pickSelect2: "none",
      loading: false,
      countRequest: 0,
      prevFilter: undefined,
      prevFilter2: undefined,
      filterType: "condition",
      activeTab: "1",
      hideProfile: false,
      careprovider: null
    };
    this.showFromMonth = this.showFromMonth.bind(this);
    this.showFromMonth2 = this.showFromMonth2.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCareprovider = this.handleCareprovider.bind(this);
    this.submitFilter = this.submitFilter.bind(this);
    this.submitFilterExpectation = this.submitFilterExpectation.bind(this);
  }
  handleCareprovider = selectedOption => {
    //
    const patientIds = selectedOption._id;
    this.setState({
      careprovider: patientIds,
      expectationsList: selectedOption.expectation
    });
    
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      if (tab === "1") {
        this.setState({
          filterType: "condition"
        });
      } else {
        this.setState({
          filterType: "expectation"
        });
      }
    }
  }
  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), "months") < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }
  showFromMonth2() {
    const { from2, to2 } = this.state;
    if (!from2) {
      return;
    }
    if (moment(to2).diff(moment(from2), "months") < 2) {
      this.to2.getDayPicker().showMonth(from2);
    }
  }
  handleSelect = (selectedOption, event) => {
   
    if (selectedOption.reading) {
      this.setState({
        readingSubType: selectedOption.index + "i",
        readingSubTypeValue: selectedOption,
        pickSelect: "none"
      });
    }
    if (selectedOption.description) {
      this.setState({
        readingType: selectedOption._id,
        readingTypeValue: selectedOption,
        readingSubType: null,
        readingSubTypeValue: [],
        pickSelect: "none"
      });
    }
    if (selectedOption.sharedUsers) {
      this.setState({
        ruleType: selectedOption.ruleType,
        readingType2Value: selectedOption.name,
        violation: selectedOption._id,
        readingType2: selectedOption.readingType,
        readingSubType2: selectedOption.readingSubType
      });
    }
  };
  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }
  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }
  handleFromChange2(from2) {
    // Change the from date and focus the "to" input field
    this.setState({ from2 });
  }
  handleToChange2(to2) {
    this.setState({ to2 }, this.showFromMonth2);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "condition" && value === ">=") {
      this.setState({
        [name]: value,
        minValue: undefined,
        maxValue: undefined
      });
    }
    if (name === "condition" && value === "<=") {
      this.setState({
        [name]: value,
        minValue: undefined,
        maxValue: undefined
      });
    }
    if (name === "condition" && value === "between") {
      this.setState({
        [name]: value,
        value: undefined
      });
    } else {
      this.setState({
        [name]: value
      });
    }
    if (name === "condition" && value === "notBetween") {
      this.setState({
        [name]: value,
        value: undefined
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }
  submitFilter(e) {
    e.preventDefault();
    if (!this.state.readingSubType) {
      this.setState({
        pickSelect: "block"
      });
      return;
    }
    const {
      from,
      to,
      readingType,
      readingSubType,
      readingTypeValue,
      readingSubTypeValue,
      condition,
      value,
      minValue,
      maxValue,
      violation
    } = this.state;

    let cleanUser;
    if (condition == "between" || condition == "notBetween") {
      cleanUser = _.omit(this.state, [
        "readingTypeValue",
        "readingSubTypeValue",
        "value",
        "pickSelect",
        "activeTab",
        "loading",
        "prevFilter",
        "readingType2Value",
        "expectationsList"
      ]);
    } else {
      cleanUser = _.omit(this.state, [
        "readingTypeValue",
        "readingSubTypeValue",
        "minValue",
        "maxValue",
        "pickSelect",
        "activeTab",
        "loading",
        "prevFilter",
        "readingType2Value",
        "expectationsList"
      ]);
    }

    this.props.dispatch({
      type: "DO_FILTER_USER_POPULATION",
      payload: cleanUser
    });
    this.setState({ prevFilter: this.state, countRequest: 1 });
  }
  submitFilterExpectation(e) {
    e.preventDefault();
    if (!this.state.violation) {
      this.setState({
        pickSelect2: "block"
      });
      return;
    }
    const {
      from,
      to,
      readingType,
      readingSubType,
      readingTypeValue,
      readingSubTypeValue,
      condition,
      value,
      minValue,
      maxValue,
      violation
    } = this.state;

    let cleanUser;
    if (condition == "between") {
      cleanUser = _.omit(this.state, [
        "readingTypeValue",
        "readingSubTypeValue",
        "value",
        "pickSelect",
        "activeTab",
        "loading",
        "prevFilter",
        "readingType2Value",
        "expectationsList"
      ]);
    } else {
      cleanUser = _.omit(this.state, [
        "readingTypeValue",
        "readingSubTypeValue",
        "minValue",
        "maxValue",
        "pickSelect",
        "activeTab",
        "loading",
        "prevFilter",
        "readingType2Value",
        "expectationsList"
      ]);

      this.props.dispatch({
        type: "DO_FILTER_USER_POPULATION",
        payload: cleanUser
      });
      this.setState({ prevFilter2: this.state });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_REMOVE_POPULATION",
      payload: ""
    });
    this.props.dispatch({
      type: "DO_GET_MY_RULES"
    });
    this.props.dispatch({
      type: "DO_GET_CAREPROVIDERS",
      payload: { client: true }
    });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: "DO_REMOVE_POPULATION",
      payload: ""
    });
  }
  render() {
    const {
      from,
      to,
      from2,
      to2,
      readingType,
      readingSubType,
      readingTypeValue,
      readingSubTypeValue,
      condition,
      value,
      minValue,
      maxValue,
      violation
    } = this.state;
    const modifiers = { start: from, end: to };
    const modifiers2 = { start: from2, end: to2 };
    let filterReadingSubType;
    if (this.props.readingType) {
      filterReadingSubType = _.filter(this.props.readingSubTypes, {
        reading: this.state.readingType
      });
    }
    const countPopulation = _.size(this.props.population);
    let population;
    if (this.props.population && this.props.population.users_with_readings) {
      population = _.orderBy(
        this.props.population.users_with_readings,
        function(user) {
          return user.readings.length;
        },
        ["desc"]
      );
    }
    
    const getReadingOnlyUsers = _.map(this.props.population, "readings");
    const flattenAndCountReadings = _.size(_.flatten(getReadingOnlyUsers));
    const removeFirst = _.dropRightWhile(this.state.expectationsList, {
      ruleType: "Trending Violation"
    });
    const removeTrending = _.dropRightWhile(removeFirst, {
      ruleType: "Reminder"
    });
    let countExceptions;
    let percentage;
    let countExceptionsReadings;
    let getViolatedBy;
    let flattenAndCountReadingsExpectation;
    let userReadingWithCountException;
    if (
      this.props.populationException &&
      this.props.populationException.expectations
    ) {
      const getReadingOnlyUsers = _.map(
        this.props.populationException.users_with_readings,
        "readings"
      );
      flattenAndCountReadingsExpectation = _.size(
        _.flatten(getReadingOnlyUsers)
      );
      countExceptions = _.size(
        this.props.populationException.expectations.exceptions
      );
      percentage = Math.round(
        (100 * countExceptions) / flattenAndCountReadingsExpectation
      );

      userReadingWithCountException = _.map(
        this.props.populationException.users_with_readings,
        element => {
          const findUserException = _.filter(
            this.props.populationException.expectations.exceptions,
            { violatedBy: { _id: element._id } }
          );
          const countThis = _.size(findUserException);
          return _.extend({}, element, { exceptionCount: countThis });
        }
      );

      // const userReadingWithCountException = _.map(
      //   this.props.populationException.users_with_readings,
      //   function(element) {
      //     const findUserException = _.filter(
      //       this.props.populationException.expectations.exceptions,
      //       { violatedBy: { _id: element._id } }
      //     );
      //     const countThis = _.size(findUserException);
      //     return _.extend({}, element, { exceptionCount: countThis });
      //   }
      // );
    }

    return (
      <div>
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body pt-2 pb-4">
              <Nav tabs className="justify-content-center">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    ANALYZE BY CONDITION
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2"
                    })}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    ANALYZE BY EXPECTATION
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "3"
                    })}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    ANALYZE BY GLOBAL THRESHOLD
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId="1">
                  <h2 className="text-center py-4">Analyze by Condition</h2>
                  <Row>
                    <Col sm="12">
                      <Form onSubmit={this.submitFilter}>
                        <Row form>
                          <Col lg={12}>
                            {" "}
                            <FormGroup>
                              <Label for="exampleSelect">Careprovider</Label>
                              <Select
                                required={true}
                                value={this.state.patientsPlaceholder}
                                onChange={this.handleCareprovider}
                                options={this.props.careproviders}
                                isMulti={false}
                                getOptionLabel={option =>
                                  option["fname"] + " " + option["lname"]
                                }
                                getOptionValue={option => option}
                              />
                            </FormGroup>
                          </Col>

                          <Col lg={2}>
                            <FormGroup>
                              <Label for="exampleCity">Start</Label>
                              <DayPickerInput
                                value={from}
                                placeholder="From"
                                inputProps={{
                                  className: "form-control",
                                  required: true
                                }}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                  selectedDays: [from, { from, to }],
                                  disabledDays: { after: to },
                                  toMonth: to,
                                  modifiers,
                                  numberOfMonths: 2,
                                  onDayClick: () => this.to.getInput().focus()
                                }}
                                onDayChange={this.handleFromChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={2}>
                            <FormGroup>
                              <Label for="exampleState">End</Label>
                              <DayPickerInput
                                ref={el => (this.to = el)}
                                value={to}
                                placeholder="To"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                inputProps={{
                                  className: "form-control",
                                  required: true
                                }}
                                dayPickerProps={{
                                  selectedDays: [from, { from, to }],
                                  disabledDays: { before: from },
                                  modifiers,
                                  month: from,
                                  fromMonth: from,
                                  numberOfMonths: 2
                                }}
                                onDayChange={this.handleToChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={2}>
                            <FormGroup>
                              <Label for="exampleZip">Reading Type</Label>

                              <Select
                                name="readingType"
                                onChange={this.handleSelect}
                                required={true}
                                value={this.state.readingTypeValue}
                                options={this.props.readingType}
                                getOptionLabel={option => option["description"]}
                                getOptionValue={option => option["_id"]}
                              />
                              <FormFeedback
                                style={{ display: this.state.pickSelect }}
                              >
                                Please pick a reading type
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={2}>
                            <FormGroup>
                              <Label for="exampleZip">Reading Subtype</Label>
                              <Select
                                name="readingSubType"
                                onChange={this.handleSelect}
                                required={true}
                                value={this.state.readingSubTypeValue}
                                style={{ width: "200px" }}
                                options={filterReadingSubType}
                                getOptionLabel={option => option["name"]}
                                getOptionValue={option => option["_id"]}
                              />
                              <FormFeedback
                                style={{ display: this.state.pickSelect }}
                              >
                                {" "}
                                Please pick a reading sub type
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg={2}>
                            <FormGroup>
                              <Label for="exampleZip">Condition</Label>
                              <Input
                                type="select"
                                name="condition"
                                onChange={this.handleInputChange}
                                required
                              >
                                <option value="">Select Condition</option>
                                <option value=">=">Greater Than</option>
                                <option value="<=">Less Than</option>
                                <option value="between">Between</option>
                                <option value="notBetween">Not Between</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg={2}>
                            <FormGroup>
                              <Label for="exampleZip">Value</Label>
                              {this.state.condition === ">=" && (
                                <Input
                                  type="text"
                                  required
                                  name="value"
                                  placeholder="Value"
                                  onChange={this.handleInputChange}
                                />
                              )}
                              {this.state.condition === "<=" && (
                                <Input
                                  type="text"
                                  required
                                  name="value"
                                  placeholder="Value"
                                  onChange={this.handleInputChange}
                                />
                              )}

                              {this.state.condition === "between" && (
                                <div>
                                  <Input
                                    type="text"
                                    name="minValue"
                                    required
                                    placeholder="Min Value"
                                    onChange={this.handleInputChange}
                                  />
                                  <Input
                                    type="text"
                                    name="maxValue"
                                    required
                                    placeholder="Max Value"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              )}
                              {this.state.condition == "notBetween" && (
                                <div>
                                  <Input
                                    type="text"
                                    name="minValue"
                                    required
                                    placeholder="Min Value"
                                    onChange={this.handleInputChange}
                                  />
                                  <Input
                                    type="text"
                                    name="maxValue"
                                    required
                                    placeholder="Max Value"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <button
                            type="submit"
                            className="btn btn-info btn-block"
                          >
                            SUBMIT
                          </button>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <h2 className="text-center py-4">
                        Analyze Population by Expectations
                      </h2>
                      <Form onSubmit={this.submitFilterExpectation}>
                        <Row form>
                          <Col lg={12}>
                            {" "}
                            <FormGroup>
                              <Label for="exampleSelect">Careprovider</Label>
                              <Select
                                required={true}
                                value={this.state.patientsPlaceholder}
                                onChange={this.handleCareprovider}
                                options={this.props.careproviders}
                                isMulti={false}
                                getOptionLabel={option =>
                                  option["fname"] + " " + option["lname"]
                                }
                                getOptionValue={option => option}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup>
                              <Label for="exampleCity">Start</Label>
                              <DayPickerInput
                                value={from2}
                                placeholder="From"
                                inputProps={{
                                  className: "form-control",
                                  required: true
                                }}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                dayPickerProps={{
                                  selectedDays: [from2, { from2, to2 }],
                                  disabledDays: { after: to2 },
                                  toMonth: to2,
                                  modifiers,
                                  numberOfMonths: 2,
                                  onDayClick: () => this.to2.getInput().focus()
                                }}
                                onDayChange={this.handleFromChange2}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup>
                              <Label for="exampleState">End</Label>
                              <DayPickerInput
                                ref={el => (this.to2 = el)}
                                value={to2}
                                placeholder="To"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                inputProps={{
                                  className: "form-control",
                                  required: true
                                }}
                                dayPickerProps={{
                                  selectedDays: [from2, { from2, to2 }],
                                  disabledDays: { before: from2 },
                                  modifiers2,
                                  month: from2,
                                  fromMonth: from2,
                                  numberOfMonths: 2
                                }}
                                onDayChange={this.handleToChange2}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={8}>
                            <FormGroup>
                              <Label for="exampleSelect">
                                Pick a Expectation
                              </Label>
                              <Select
                                required={true}
                                value={this.state.rule}
                                onChange={this.handleSelect}
                                getOptionLabel={option => option["name"]}
                                getOptionValue={option => option["_id"]}
                                options={removeTrending}
                              />
                              <FormFeedback
                                style={{ display: this.state.pickSelect2 }}
                              >
                                Please pick an expection
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <button
                            type="submit"
                            className="btn btn-info btn-block"
                          >
                            SUBMIT
                          </button>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <ViewGlobal />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
        {this.props.ongoingRequest && (
          <ReactLoading className="mx-auto" color={"#007bff"} />
        )}
        {this.props.population &&
          this.props.population.totalFilteredCondition === 0 &&
          this.state.countRequest > 0 &&
          !this.props.ongoingRequest &&
          this.state.activeTab === "1" && (
            <h1 className="text-center">No Result Found</h1>
          )}
        {population &&
          population.length > 0 &&
          !this.props.ongoingRequest &&
          this.state.countRequest > 0 &&
          this.state.activeTab === "1" && (
            <Row className="mx-auto">
              <Col md={12} className="no-gutters">
                <FilterConditionCard
                  countExceptions={this.props.population.totalFilteredCondition}
                  totalReadings={this.props.population.totalReadingPerType}
                  average={this.props.population.percent}
                  currentState={this.state.prevFilter}
                />
              </Col>

              <Col md={10} className="no-gutters mx-auto">
                <ReactToPrint
                  trigger={() => (
                    <div className="pull-right">
                      <a
                        className="btn btn-info text-white"
                        onClick={() => this.setState({ hideProfile: true })}
                      >
                        <i className="mdi mdi-printer" />
                      </a>
                    </div>
                  )}
                  content={() => this.componentRef}
                  onBeforePrint={() => this.setState({ hideProfile: true })}
                  closeAfterPrint={() => this.setState({ hideProfile: false })}
                  onAfterPrint={() => this.setState({ hideProfile: false })}
                />
                <TableSummary
                  ref={el => (this.componentRef = el)}
                  users={population}
                  prevFilter={this.state.prevFilter}
                  hideProfile={this.state.hideProfile}
                  startDate={this.state.prevFilter.from}
                  endDate={this.state.prevFilter.to}
                />
              </Col>
            </Row>
          )}

        {this.props.populationException &&
          !this.props.ongoingRequest &&
          this.state.activeTab === "2" && (
            <Row className="mx-auto">
              <Col md={12} className="no-gutters">
                <FilterExceptionCard
                  countExceptions={countExceptions}
                  totalReadings={flattenAndCountReadingsExpectation}
                  average={percentage}
                  currentState={this.state.prevFilter2}
                />
              </Col>
              <Col md={10} className="no-gutters mx-auto">
                <ReactToPrint
                  trigger={() => (
                    <div className="pull-right">
                      <a
                        className="btn btn-info text-white"
                        onClick={() => this.setState({ hideProfile: true })}
                      >
                        <i className="mdi mdi-printer" />
                      </a>
                    </div>
                  )}
                  content={() => this.componentRef2}
                  onBeforePrint={() => this.setState({ hideProfile: true })}
                  closeAfterPrint={() => this.setState({ hideProfile: false })}
                  onAfterPrint={() => this.setState({ hideProfile: false })}
                />
                <TableSummary
                  ref={el => (this.componentRef2 = el)}
                  exceptionType={true}
                  users={userReadingWithCountException}
                  prevFilter={this.state.prevFilter2}
                  hideProfile={this.state.hideProfile}
                  startDate={this.state.prevFilter2.from2}
                  endDate={this.state.prevFilter2.to2}
                />
              </Col>
            </Row>
          )}
      </div>
    );
  }
}
function mapStateToProps({ auth, careprovider, client }) {
  const { readingSubTypes, readingType } = auth;
  const { careproviders } = client;
  const {
    population,
    populationException,
    isGenericSuccess,
    isGenericError,
    ongoingRequest,
    myRules
  } = careprovider;
  return {
    population,
    populationException,
    readingSubTypes,
    readingType,
    isGenericSuccess,
    isGenericError,
    ongoingRequest,
    myRules,
    careproviders
  };
}
export default connect(mapStateToProps)(index);
