import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import Link from "components/ExtendLink";
import Avatar from "react-avatar";
import { useDispatch } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col
} from "reactstrap";
import UserName from "../Reusable/UserName";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
const _ = require("lodash");
const { ExportCSVButton } = CSVExport;

function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}

const ReminderList = (props, context) => {
  const [selectedMonth, setSelectedMonth] = useState(0);
  const { fname = "ERROR", lname = "ERROR", date = "" } = props;

  function fullname(cell, row) {
    if(moment(row.recepient.createdAt).month() + 1 == selectedMonth) { //new user
      return (
          <div>{(row.recepient.fname ?row.recepient.fname : '') + " " + (row.recepient.lname ? row.recepient.lname : '')} <label class="badge badge-gradient-info">NEW</label></div>
        )
    }
    return (row.recepient.fname ? row.recepient.fname : '') + " " + (row.recepient.lname ? row.recepient.lname : '');
  }
  const MyExportCSV = props => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Row style={{marginTop: "5%"}}>
        <Col>
          <button className="btn btn-success float-left" onClick={handleClick}>
            <i className="mdi mdi-file-excel" /> Export to CSV
          </button>
        </Col>
      </Row>
    );
  };
  return (
    <div>
      <ToolkitProvider
        keyField="_id"
        data={props.patientReminderList}
        columns={[
          {
            dataField: props.patientReminderList ? "recepient": "",
            text: "Name",
            sort: true,
            formatter: fullname,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
              if(moment(row.recepient.createdAt).month() + 1 == selectedMonth) {
                  return row.recepient.fname + " " + row.recepient.lname + " (NEW)";
              }
              return row.recepient.fname + " " + row.recepient.lname;
            }
          },
          {
            dataField: "nickname",
            text: "Nickname",
            sort: true,
            searchable:true,
            formatter: (cell, row) => {
              return (row.recepient.nickname? row.recepient.nickname :"-");
            },
            csvFormatter: (cell, row) => {
              return (row.recepient.nickname? row.recepient.nickname :"-");
            },
            headerFormatter: priceFormatter,          
          },
          {
            dataField: props.patientReminderList ? "recepient.createdAt": "",
            text: "Joined Date",
            sort: true,
            formatter: (cell, row) => {
              return moment(row.recepient.createdAt).format("MMMM DD, YYYY");
            },
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
                return moment(row.recepient.createdAt).format("MMMM DD, YYYY");
            }
          },
          {
            dataField: props.patientReminderList ? "messageDate": "",
            text: "Reminder Date",
            sort: true,
            formatter: (cell, row) => {
              return moment(row.messageDate).format("MMMM DD, YYYY");
            },
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
                return moment(row.messageDate).format("MMMM DD, YYYY");
            }
          },
          {
            dataField: props.patientReminderList ? "isRead": "",
            text: "Read by Patient",
            sort: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
                return row.isRead;
            }
          },
          {
            dataField: props.patientReminderList ? "body" : "",
            text: "Reminder Body",
            sort: true,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
                return row.body;
            }
          },
          {
            dataField: props.patientReminderList
            ? "sender" : "",
            text: "Sender",
            sort: true,
            formatter: (cell, row) => {
              return (row.sender ? row.sender.fname : '') + " " + (row.sender ? row.sender.lname : '');
            },
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row, rowIndex) => {
                return (row.sender ? row.sender.fname : '' )+ " " + (row.sender ? row.sender.lname : '');
            }
          }
        ]}
        exportCSV={{ fileName: `patient-reminder-report.csv` }}
      >
        {toolKitProp => (
          <div>
          <Row>
            <Col md={6}>
              <MyExportCSV {...toolKitProp.csvProps}>Export CSV</MyExportCSV>
            </Col>
          </Row>
            <hr />
            <div className="scrollbar-table-cls">
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              noDataIndication="No Data Found"
              filter={filterFactory()}
              {...toolKitProp.baseProps}
            />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ReminderList;
