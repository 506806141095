import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import RuleList from "./RuleList";
import Heading from "./Heading";
import PickRuleModal from "./PickRuleModal";
import expectationImages from "../../files/ic_expectations.svg";

const AppContext = React.createContext();
class Sharing extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }
  toggleModal() {
    
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_GET_MY_RULES"
    });
    this.props.dispatch({
      type: "DO_GET_RULES_SET_BY_OTHERS"
    });
    this.props.dispatch({
      type: "DO_GET_USERS_YOU_HAVE_ACCESS"
    });
    if (this.props.userDetails.isCareProvider) {
      this.props.dispatch({
        type: "DO_GET_CONDITIONS"
      });
    }
  }

  render() {

    return (
      <div>
        <div className="row">
          <Heading
            title="EXPECTATIONS"
            description=""
            backbutton="d-none"
            isCareProvider={true}
            mainbutton="d-flex"
            toggleModal={this.toggleModal}
          >
            <span className="page-title-icon bg-gradient-danger   text-white mr-2">
              <i className="mdi mdi-account-convert" />
            </span>
          </Heading>

          <div className="col-12">
            <div className="card">
              <div className="card-body project-tab pt-2">
                <Nav tabs className="justify-content-center">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <img style={{width:50, marginRight:10}} src={expectationImages} />
                      MY EXPECTATIONS
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2"
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      EXPECTATIONS SET BY OTHERS
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row style={{padding:0}}>
                      <Col sm="12" style={{padding:0}}>
                        {this.props.myRules && (
                          <RuleList
                            myRules={this.props.myRules}
                            userId={this.props.userId}
                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row style={{padding:0}}>
                      <Col sm="12" style={{padding:0}}>
                        {this.props.rulesSetByOthers && (
                          <RuleList
                            myRules={this.props.rulesSetByOthers}
                            userId={this.props.userId}
                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
        {this.props.userDetails && this.props.userDetails.isCareProvider && (
          <PickRuleModal
            modal={this.state.modal}
            toggleModal={this.toggleModal}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myRules,
    rulesSetByOthers,
    usersOfferedAccess
  } = careprovider;
  const userId = user.userDetails._id;
  const userDetails = user.userDetails;

  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myRules,
    rulesSetByOthers,
    userId,
    userDetails,
    usersOfferedAccess
  };
};

export default connect(mapStateToProps)(Sharing);
