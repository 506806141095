import React, { Component } from "react";
import { connect } from "react-redux";
import NoteList from "./NoteList";
import _, { filter } from "lodash";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Alert,
  CustomInput
} from "reactstrap";
import ReactToPrint from "react-to-print";
import NoteListPrint from "./NoteListPrint";
import LottieLoader from '../../LottieLoader';
import lottieLoaderFile from '../../../files/animation/loading.json';
import lottieWarningFile from '../../../files/animation/ic_warning.json';
import Link from "components/ExtendLink";
import Select from "react-select";
import RequiredSelect from "../../Rules/RequiredSelect";
import Datetime from "react-datetime";
class Notes extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      userToSend: this.props.currentUserId,
      body: "",
      subject: "",
      isPublic: true,
      startDate: undefined,
      endDate: undefined,
      endDateError: null,
      device: undefined,
      deviceModel: undefined,
      errorDeviceModel: false,
      ignoreDevice: true,
      hidePublicNotes: false,
      timeDuration : null,
      reminderDate : null,
      reminderDateError : undefined,
      notifyMe : ['Inbox'],
      time : null, 
      timeError : null
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.deleteInbox = this.deleteInbox.bind(this);
    this.readInbox = this.readInbox.bind(this);
    this.handleInputDateChange = this.handleInputDateChange.bind(this);
    this.handleInputReminderDateChange = this.handleInputReminderDateChange.bind(this);
    this.handelSelectChange = this.handelSelectChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.validateTime = this.validateTime.bind(this);
  }
  handleUsers = (selectedOption) => {
    this.setState({ userToSend: selectedOption });
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (value == "64620873b1cebb9c4d5b70ea" ||
        value == "646208d0b1cebb9c4d5b70eb" ||
        value == "64f02ecb6d9efff481a93e67" ||
        value == "646208d3b1cebb9c4d5b70ec" ||
        value == "646208d6b1cebb9c4d5b70ed" ||
        value == "64620ebdb1cebb9c4d5b70f7" || 
        value == "64c781a345daedcb3562a359" || 
        value == "64c781b245daedcb3562a35a" ||
        value == "65081c5b4e8e9173654eb40b" ||
        value == "65081cba4e8e9173654eb40c" ||
        value == "650a77061e37821fe76376dc") {
          this.setState({
            ignoreDevice: false,
            title: this.props.onBoardingStatus ? _.find(this.props.onBoardingStatus, v => v._id === value) ? (_.find(this.props.onBoardingStatus, v => v._id === value)).title : "" : "",
            body: this.props.onBoardingStatus ? _.find(this.props.onBoardingStatus, v => v._id === value) ? (_.find(this.props.onBoardingStatus, v => v._id === value)).message : " " : "",
            deviceModel: undefined,
            device: undefined,
          });
        this.props.dispatch({
          type: "DO_GET_ALL_DEVICE_FOR_ON_BOARDING",
          payload :{
            user: this.state.userToSend,
            category: value
          }
        });        
    } else {
      if(name == "subject"){
        this.setState({
           body : "",
           ignoreDevice: true,
           title : "",
        })
      }
    }
    this.setState({
      [name]: value      
    }, () => {
      if(name == 'timeDuration'){
      var today = moment();
      if (this.state.timeDuration == 'One Week') {
        var nextWeek = today.add(7, 'days');
        this.setState({reminderDate : nextWeek.format("YYYY-MM-DD")}, () => this.validateTime())
      } else if (this.state.timeDuration == 'Two Weeks') {
        var nextTwoWeek = today.add(2, 'weeks');
        this.setState({reminderDate : nextTwoWeek.format("YYYY-MM-DD")}, () => this.validateTime())
      } else if (this.state.timeDuration == 'One Month') {
        var oneMonth = today.add(1, 'months');
        this.setState({reminderDate : oneMonth.format("YYYY-MM-DD")}, () => this.validateTime());
      }else if (this.state.timeDuration == 'Custom Date') {
        let reminderDate;
          reminderDate = today.format("YYYY-MM-DD");
        this.setState({reminderDate : reminderDate}, () => this.validateTime())
      }
    }
    });
    if (name == "startDate" || name == "endDate") {
      if (this.props.datesForNotes) {
        this.props.datesForNotes({
          [name + "ForNotes"]: value
        });
      }
    }
    if (name == "subject") {
      if (value == "64620873b1cebb9c4d5b70ea" ||
        value == "646208d0b1cebb9c4d5b70eb" ||
        value == "64f02ecb6d9efff481a93e67" ||
        value == "646208d3b1cebb9c4d5b70ec" ||
        value == "646208d6b1cebb9c4d5b70ed" ||
        value == "64620ebdb1cebb9c4d5b70f7" ||
        value == "64c781a345daedcb3562a359" ||
        value == "64c781b245daedcb3562a35a" ||
        value == "65081c5b4e8e9173654eb40b" ||
        value == "65081cba4e8e9173654eb40c" ||
        value == "650a77061e37821fe76376dc" || 
        value == "Patient was recently admitted to the Hospital" ||
        value == "Follow Up Reminder") {
        this.setState({
          hidePublicNotes: true,
          isPublic:true
        });
      } else {
        this.setState({
          hidePublicNotes: false,
        });
      }
    }
    if(name == "subject" && this.state.subject !== value && value == "Follow Up Reminder"){
      this.setState({body : ''})
    }
    if(name == "subject" && value !== "Follow Up Reminder"){
      this.setState({
      timeDuration : null,
      reminderDate : null,
      reminderDateError : undefined,
      notifyMe : ['Inbox'],
      time : null, 
      timeError : null
    })
    }
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
    // this.resetForm();
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  resetForm() {
    this.setState({
      modal: !this.state.modal,
      body: "",
      subject: "",
      isPublic: true,
      timeDuration : null,
      reminderDate : null,
      reminderDateError : undefined,
      notifyMe : [],
      time : null,
      timeError : null
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
  }
  deleteInbox(id) {
    this.props.dispatch({
      type: "DO_DELETE_USER_NOTES",
      payload: {
        id: id
      }
    });
    setTimeout(() => {
      this.setState({
        startDate:"",
        endDate:"",        
      }, () => {
        this.fetchData();
      });      
    }, 400);
  }
  readInbox(id) {
    this.props.dispatch({
      type: "DO_READ_NOTES",
      payload: {
        id: id
      }
    });
    this.props.dispatch({
      type: "DO_GET_NOTES"
    });
  }
  submit(e) {
    e.preventDefault();
    const valid =  this.validateTime();
    if(this.state.reminderDateError || this.state.timeError || valid){
      return;
    }
    const payload = {
      user: this.state.userToSend,
      body: this.state.body,          
      subject:
        this.state.subject === "Other"
          ? this.state.customSubject
          : this.state.subject,
      isPublic: this.state.isPublic
    }
    // title add on based on subject select
    if(this.state.subject == "64620873b1cebb9c4d5b70ea" ||
      this.state.subject == "646208d0b1cebb9c4d5b70eb" ||
      this.state.subject == "64f02ecb6d9efff481a93e67" ||
      this.state.subject == "646208d3b1cebb9c4d5b70ec" ||
      this.state.subject == "646208d6b1cebb9c4d5b70ed" ||
      this.state.subject == "64620ebdb1cebb9c4d5b70f7" || 
      this.state.subject == "64c781a345daedcb3562a359" || 
      this.state.subject == "64c781b245daedcb3562a35a" ||
      this.state.subject == "65081c5b4e8e9173654eb40b" ||
      this.state.subject == "65081cba4e8e9173654eb40c" ||
      this.state.subject == "650a77061e37821fe76376dc"){
        if(_.isEmpty(this.state.title)){
          return;
         }
       payload.title = this.state.title;
      }
    if(this.props.hideNotesButton){
      payload.patientPath = true;
   }else{
     payload.device = this.state.deviceModel;
   }
   const notifyMe = this.state.notifyMe;
   if(notifyMe.length == 0){
    notifyMe.push('Inbox')
   }
   if(this.state.reminderDate && this.state.subject == 'Follow Up Reminder'){
    let selectedTime = this.state.time;
    const reminderSentDate = moment(`${this.state.reminderDate}`).set({hour:moment(selectedTime).hour(),minute:moment(selectedTime).minutes(),second:0,millisecond:0});
      // add time in reminderSentDate varible this time is a cron run this time
    const reminderWithTime = reminderSentDate.utc();
     payload.reminderNoteData = {
       reminderDate : reminderWithTime,
       status : 'Reminder',
       timeDuration : this.state.timeDuration,
       notifyMe : this.state.notifyMe,
       timeZone : moment.tz.guess(),
       time : this.state.time
      }
   }
    if (this.props.hideNotesButton) {
      this.props.dispatch({
        type: "DO_SAVE_USER_NOTES",
        payload: payload
      });
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_USER_NOTES",
          payload: { userid: this.props.currentUserId, patientPath: true }
        });
      }, 400);
    } else {
      if(!this.state.ignoreDevice && this.props.onBoarding && this.props.onBoarding.length == 0){
        this.setState({
          errorDeviceModel: true                 
        });
        return;
      }
      if(!this.state.ignoreDevice && this.state.deviceModel && this.state.deviceModel.length == 0 && !this.props.isNotApplicable){
        this.setState({
          errorDeviceModel: true                 
        });
        return;
      }
      this.props.dispatch({
        type: "DO_SAVE_USER_NOTES",
        payload: payload
      });
      setTimeout(() => {
        this.setState({
          startDate:"",
          endDate:"",        
        }, () => {
          this.fetchData();
        });
      }, 400);
    }
    this.resetForm();
  }
  handleInputReminderDateChange(event){
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(
      { [name]: value }, () => {
        this.validDateValiadation(this.state.reminderDate);
        this.validateTime();
      }
    );   
  }
  validDateValiadation(date ){
    const todayDate = moment().format('YYYY-MM-DD');
    let startDate;
    if(date){
      startDate = moment(date).format('YYYY-MM-DD');
    }
   let errorFlag = false;
   
   if(startDate < todayDate){
      errorFlag = true;
      this.setState({ reminderDateError: "Please select Date greater or equal to Today's Date" });
    }

  if(!errorFlag){
    this.setState({
      reminderDateError: undefined
    });
  }
  }

  componentDidMount() {    
    // Getting On Boarding Status    
    this.props.dispatch({
      type: "DO_GET_ON_BOARDING_STATUS",
    });    
    if (this.props.hideNotesButton) {
      this.props.dispatch({
        type: "DO_GET_USER_NOTES",
        payload: { userid: this.props.currentUserId, patientPath: true }
      });
    } else {
      this.setState({
        startDate:"",
        endDate:"",        
      }, () => {
        this.fetchData();
      });
    }
  }
  fetchData(){
    this.props.dispatch({
      type: "DO_GET_USER_NOTES",
      payload: { 
        userid: this.props.currentUserId
      },    
    });
  }
  handleInputDateChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      { [name]: value }, () => {
        this.isEndDateValid(this.state.endDate)
      }
    );   
  }
  isEndDateValid(value) {
    if (value === undefined || value === null || value.trim().length == 0) {
      this.setState({
        endDateError: undefined
      });
      return false;
    } else {
      var date = moment(new Date(this.state.startDate), 'MM/DD/YYYY');
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      var dateEntered = new Date(value);
      var today = moment(date, 'MM/DD/YYYY');
      var enteredDate = moment(dateEntered, 'MM/DD/YYYY');
      var dDiff = today.isSameOrAfter(enteredDate);
      if (dDiff) {
        this.setState({
          endDateError: "Please select end date is greater than equal to start date."
        });
        return false;
      } else if (today.isSame(enteredDate)) {
        this.setState({
          endDateError: undefined
        });
        return true;
      } else {
        this.setState({
          endDateError: undefined
        });
        return true;
      }
    }
  };
  handelSelectChange(event){
    const notifyMe = this.state.notifyMe;
    if(notifyMe.length == 0){
      notifyMe.push('Inbox')
    }
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    if(value && target && (target.value == 'Sms' || target && target.value == 'Email') && name == 'notifyMe'){
      notifyMe.push(event.target.value)
    }else if(!value && name == 'notifyMe'){
     let index = notifyMe.indexOf(target.value);
     notifyMe.splice(index, 1);
    }
    let uniqueArray = _.uniq(notifyMe);
    this.setState({notifyMe: uniqueArray})
  }
  handleTimeChange(event) {
    const validTime =  moment(event, 'hh:mm A', true).isValid();
    if(!validTime){
      this.setState({timeError : 'Invalid time format. Please use "hh:mm AM/PM"'});
      return
    }
    let time = event.format("hh:mm A");
    this.setState({time : event, timeError : null}, () => this.validateTime())
  }
  validateTime(){
    const todayDate = moment().format('YYYY-MM-DD');
    if(this.state.reminderDate < todayDate){
      this.setState({ reminderDateError: "Please select Date greater or equal to Today's Date" });
      if(this.state.time){
        this.setState({timeError : 'The time is not valid. Please select correct Date'});
      }else{
        this.setState({timeError : null});
      }
      return true
    }else{
      this.setState({ reminderDateError: null, timeError : null });
    }
    if(this.state.timeDuration == "Custom Date" && this.state.reminderDate <= todayDate){
      if(moment(this.state.reminderDate).isSame(todayDate) && this.state.time){
        let selectedTime = this.state.time;
        const reminderSentDate = moment(`${this.state.reminderDate}`).set({hour:moment(selectedTime).hour(),minute:moment(selectedTime).minutes(),second:0,millisecond:0}).format('YYYY-MM-DD HH:mm');
        const todayDateTime = moment().format('YYYY-MM-DD HH:mm');
        if(!moment(reminderSentDate).isAfter(todayDateTime)){
          this.setState({timeError : "Please select the Time greater than Today's Time"});
          return true
        }else{
          this.setState({timeError : null});
        }
      }else{
        this.setState({timeError : null});
      }
    }else{
      this.setState({timeError : null });
    }
  }
  render() {
    var properStartDate = this.state.startDate ? moment(this.state.startDate).startOf("day").format() : undefined;
    var properEndDate = this.state.endDate? moment(this.state.endDate).endOf("day").format() : undefined;
    var filteredNotes = []
    if(this.state.endDateError != undefined || (properStartDate == undefined && properEndDate == undefined)){
      properEndDate = undefined;
    } 
    var filteredNotes = filter(this.props.notes, function (value) {
      const convertFirst = moment(value.createdAt).format();      
      if(properStartDate && properEndDate){
        return moment(convertFirst).isBetween(properStartDate, properEndDate);    
      }else if(properStartDate) {
        return moment(convertFirst).isAfter(properStartDate);    
      }else if(properEndDate) {
        return moment(convertFirst).isBefore(properEndDate);    
      } else {
        return true;
      } 
    });  
    return (
      <div>
        <div className={`text-center col-md-12 mx-auto`}>
          <Row>
          <Col md={2} style={{marginTop:5}}>
              <FormGroup className="remove-bottom-margin form-group-patient">
                <Label for="exampleEmail">Filter By Start Date</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="date"
                  name="startDate"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={this.handleInputDateChange}
                  value={this.state.startDate}
                />
              </FormGroup>
            </Col>
            <Col md={2} style={{marginTop:5}}>
              <FormGroup className="remove-bottom-margin form-group-patient">
                <Label for="examplePassword">Filter By  End Date</Label>
                <Input
                  className="input-date-filter remove-bottom-margin"
                  type="date"
                  name="endDate"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={this.handleInputDateChange}
                  value={this.state.endDate}
                />
                <Label
                  style={{
                    color: "red",
                    textAlign: "left",
                    padding: 5
                  }}>{this.state.endDateError}</Label>
              </FormGroup>
            </Col>
            <Col md={8}>
              {(this.props.isCareProvider || this.props.isStaff) && (
                <>
                  <Link
                    style={{ margin: 5 }}
                    to={`/onboarding`}
                    className="btn pull-right on-boarding-note-click-cls link-padding-on-boarding">
                    <i className="mdi mdi-account-card-details"></i> RPM On-Boarding
                  </Link>
                  <Link
                    style={{ margin: 5 }}
                    to={`/onboardingccm`}
                    className="btn pull-right on-boarding-ccm-note-click-cls link-padding-on-boarding">
                    <i class="mdi mdi-account-card-details"></i> CCM On-Boarding
                  </Link>
                  <Link
                    style={{ margin: 5 }}
                    to={`/offboarding`}
                    className="btn pull-right off-boarding-note-click-cls link-padding-on-boarding">
                    <i class="mdi mdi-account-card-details"></i> RPM Off-Boarding
                  </Link>

                </>
              )}
              <ReactToPrint
                documentTitle={"Notes"}
                trigger={() => (
                  <div className="pull-right">
                    <button
                      className="btn btn-info ml-2 print-click-cls"
                      style={{ float: 'right', margin: 5, padding: "10px 26px" }}
                    >
                      <i class="mdi mdi-cloud-print"></i>
                      Print
                    </button>
                  </div>
                )}
                content={() => this.componentRef}
              />
              <button
                className="btn btn-info ml-2 create-note-click-cls"
                style={{ float: 'right', margin: 5 }}
                onClick={() => {
                  this.toggleModal();
                }}
              >
                <i class="mdi mdi-note btn-icon-prependd"></i>
                Create a Note
              </button>
            </Col>
          </Row>
        </div>
        {this.props.ongoingNoteListRequest && (
          <NoteList
            deleteInbox={this.deleteInbox}
            readInbox={this.readInbox}
            loading={this.props.ongoingNoteListRequest}
            notes={[]}
          />
        )}
        {!this.props.ongoingNoteListRequest && this.props.notes && (
          <NoteList
            deleteInbox={this.deleteInbox}
            readInbox={this.readInbox}
            loading={this.props.ongoingNoteListRequest}
            isCareProvider={this.props.isCareProvider}
            isStaff={this.props.isStaff}
            notes={filteredNotes}
          />
        )}
        {this.props.notes && (
          <div className="hide">
            <NoteListPrint
              ref={el => (this.componentRef = el)}
              deleteInbox={this.deleteInbox}
              readInbox={this.readInbox}
              loading={this.props.ongoingRequest}
              notes={filteredNotes}         
              notesPatientDetails={this.props.notesPatientDetails}
              latestReadingUser={this.props.latestReadingUser}
              rpmTotalDuration={this.props.rpmTotalDuration}
              ccmTotalDuration={this.props.ccmTotalDuration}
            />
          </div>
        )}
        
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>Create a Note</ModalHeader>

          <ModalBody className="bg-white">
            <Form onSubmit={this.submit}>
              <FormGroup>
                <Label for="exampleSelect">Subject</Label>
                {this.props.onBoardingStatus && (this.props.isCareProvider || this.props.isStaff) && _.size(this.props.onBoardingStatus) > 0 && (
                  <Input
                    className="input-date-filter remove-bottom-margin"
                    type="select"
                    name="subject"
                    required={true}
                    onChange={this.handleInputChange}
                    id="exampleSelect"
                    value={this.state.subject}
                  >
                    <option value="">Select Subject</option>
                    <option value="Adherence Request">Adherence Request</option>
                    <option value="RPM Session">RPM Session</option>
                    <option value="CCM Session">CCM Session</option>
                    {/* <option value="Initial Setup & Training Completed">
                      Initial Setup & Training Completed
                    </option>                    
                    <option value="Welcome Call Request">
                      Welcome Call Request
                    </option>
                    <option value="Do Not Call">Do Not Call</option> */}
                    <option value="Patient Education">Patient Education</option>
                    <option value="Follow Up Reminder">Follow Up Reminder</option>               
                    {(this.props.isStaff) && (
                      <option value="Patient was recently admitted to the Hospital">Patient was recently admitted to the Hospital</option>
                    )}                                        
                    <option value="Other">Other</option>
                  </Input>
                )}
                {(!this.props.isCareProvider && !this.props.isStaff) && (
                  <Input
                    className="input-date-filter remove-bottom-margin"
                    type="select"
                    name="subject"
                    required={true}
                    onChange={this.handleInputChange}
                    id="exampleSelect"
                  >
                    <option value="">Select Subject</option>
                    
                    <option value="RPM Session">RPM Session</option>
                    <option value="CCM Session">CCM Session</option>
                    <option value="Initial Setup & Training Completed">
                      Initial Setup & Training Completed
                    </option>
                    <option value="Adherence Request">Adherence Request</option>
                    <option value="Welcome Call Request">
                      Welcome Call Request
                    </option>
                    <option value="Do Not Call">Do Not Call</option>
                    <option value="Patient Education">Patient Education</option>
                    <option value="Other">Other</option>
                  </Input>
                )}
                
              </FormGroup>
              {this.state.subject == "Other" && (
                <FormGroup>
                  <Input
                    type="text"
                    name="customSubject"
                    className="input-date-filter remove-bottom-margin"
                    placeholder="Please specify"
                    required={true}
                    onChange={this.handleInputChange}
                    style={{fontSize:14}}                    
                    rows={10}
                  />
                </FormGroup>
              )}
              {this.state.subject && this.state.subject == 'Follow Up Reminder' && ( 
                <FormGroup>
                <Label for="exampleSelect">Time Duration</Label>
                <Input
                className="input-date-filter remove-bottom-margin"
                type="select"
                name="timeDuration"
                required={true}
                onChange={this.handleInputChange}
                id="exampleSelect"
                value={this.state.timeDuration}
              >
              <option value="">Select Time Duration</option>
              <option value="One Week">One Week</option>
              <option value="Two Weeks">Two Weeks</option>
              <option value="One Month">One Month</option>
              <option value="Custom Date">Custom Date</option>
              </Input>
              </FormGroup>)}
              {this.state.timeDuration && this.state.timeDuration == "Custom Date" && this.state.subject && this.state.subject == 'Follow Up Reminder' && (
                <div>
                    <FormGroup className="remove-bottom-margin form-group-patient">
                      <Label for="exampleEmail">Select a Date</Label>
                      <Input
                        className="input-date-filter remove-bottom-margin"
                        type="date"
                        name="reminderDate"
                        min={moment().format("YYYY-MM-DD")}
                        onChange={this.handleInputReminderDateChange}
                        value={this.state.reminderDate}
                        required={this.state.timeDuration == "Custom Date" ? true : false}
                      />
                      <Label
                        style={{
                          color: "red",
                          textAlign: "left",
                          padding: 5
                        }}>{this.state.reminderDateError}</Label>
                    </FormGroup>
                </div>
              )}
              {this.state.timeDuration && this.state.subject == 'Follow Up Reminder' && (<FormGroup>
                <Label for="exampleReminder">Pick a Specific Time for Reminders</Label>
                  <Datetime
                    dateFormat={false}
                    inputProps={{
                      placeholder: "Input Desired Time",
                      required: this.state.timeDuration && this.state.subject == 'Follow Up Reminder' ? true : false
                    }}
                    onChange={(event) =>
                      this.handleTimeChange(event)
                    }
                    value={this.state.time}
                  />
                {this.state.timeError && (<Label
                  style={{
                    color: "red",
                    textAlign: "left",
                    padding: 5,
                    'marginBottom' : '-6px'
                  }}>{this.state.timeError}</Label>)}
              </FormGroup>)}
              {this.state.timeDuration && this.state.subject == 'Follow Up Reminder' && (<FormGroup>
                <Label for="exampleSelect">Notify Me</Label>
                <div>
                <CustomInput
                  type="checkbox"
                  id="exampleCustomInline7"
                  name="notifyMe"
                  onChange={this.handelSelectChange}
                  label="SMS"
                  value="Sms"
                  inline
                  checked={this.state.notifyMe.includes('Sms')}
                />
                <CustomInput
                  type="checkbox"
                  id="exampleCustomInline9"
                  name="notifyMe"
                  onChange={this.handelSelectChange}
                  label="EMAIL"
                  value="Email"
                  inline
                  checked={this.state.notifyMe.includes('Email')}
                />
                <Alert color="success">
                By default the reminder will be sent to Inbox
                </Alert>
              </div>
              </FormGroup>)}
              {(this.state.subject == "64620873b1cebb9c4d5b70ea" ||
                this.state.subject == "646208d0b1cebb9c4d5b70eb" ||
                this.state.subject == "64f02ecb6d9efff481a93e67" ||
                this.state.subject == "646208d3b1cebb9c4d5b70ec" ||
                this.state.subject == "646208d6b1cebb9c4d5b70ed" ||
                this.state.subject == "64620ebdb1cebb9c4d5b70f7" || 
                this.state.subject == "64c7818745daedcb3562a358" || 
                this.state.subject == "64c781a345daedcb3562a359" || 
                this.state.subject == "64c781b245daedcb3562a35a" ||
                this.state.subject == "65081c5b4e8e9173654eb40b" ||
                this.state.subject == "65081cba4e8e9173654eb40c" ||
                this.state.subject == "650a77061e37821fe76376dc") && !this.props.ongoingRequestDevice && !this.props.isNotApplicable && this.props.onBoarding && this.props.onBoarding.length > 0 && (
                  <>
                  <FormGroup className="remove-bottom-margin form-group-patient">
                    <Label for="examplePassword">Select Shipment Device:&nbsp;&nbsp;</Label>
                    {this.props.mDeviceListOnBoarding && this.props.mDeviceListOnBoarding.length> 0 && (
                      <RequiredSelect
                        SelectComponent={Select}
                        required={true}
                        isMulti={true}
                        onChange={(selectedOption) => {
                         //console.log(selectedOption);                          
                          this.setState({
                            deviceModel: selectedOption,
                            errorDeviceModel: false
                          });
                        }}
                        getOptionLabel={(option) =>
                          option.device["name"]
                        }
                        getOptionValue={(option) => option.device["_id"]}
                        options={this.props.mDeviceListOnBoarding}
                      />                      
                    )}                    
                  </FormGroup>
                  {this.props.onBoarding && this.props.onBoarding.length != 0 && this.props.mDeviceListOnBoarding && this.props.mDeviceListOnBoarding == 0 && (
                    <Label style={{ 
                      color: "red",
                      padding:"10px 0px 0px 5px"
                     }}>Device not available for the selected category.</Label>
                  )}                  
                  {this.props.onBoarding && this.props.onBoarding.length > 0 && this.state.errorDeviceModel && (
                    <Label style={{ 
                      color: "red",
                      padding:"10px 0px 0px 5px"
                     }}>Please select device</Label>
                  )}                 
                  </>
                )}
                {this.props.ongoingRequestDevice && (
                  <LottieLoader
                    loadLoop={true}
                    autoplayLoop={true}
                    source={lottieLoaderFile}
                    Lheight={50}
                    Lwidth={50}
                    subTitleExtraSmall={"Please Wait ..."}
                  />
                )}
                {(this.state.subject == "64620873b1cebb9c4d5b70ea" ||
                this.state.subject == "646208d0b1cebb9c4d5b70eb" ||
                this.state.subject == "64f02ecb6d9efff481a93e67" ||
                this.state.subject == "646208d3b1cebb9c4d5b70ec" ||
                this.state.subject == "646208d6b1cebb9c4d5b70ed" ||
                this.state.subject == "64620ebdb1cebb9c4d5b70f7" || 
                this.state.subject == "64c781a345daedcb3562a359" || 
                this.state.subject == "64c781b245daedcb3562a35a" ||
                this.state.subject == "65081c5b4e8e9173654eb40b" ||
                this.state.subject == "65081cba4e8e9173654eb40c" ||
                this.state.subject == "650a77061e37821fe76376dc") && !this.props.ongoingRequestDevice && this.props.onBoarding && this.props.onBoarding.length == 0 && this.props.displayLabel && (
                  <>
                  <div>
                  <LottieLoader
                    loadLoop={false}
                    autoplayLoop={true}
                    source={lottieWarningFile}
                    Lheight={75}
                    Lwidth={75}                    
                  />
                  <Label style={{ 
                    color: "red"
                    }}> Shipment category <b>{this.props.displayLabel}</b> is not available! Please include a category for <b>{this.props.displayLabel.toLowerCase()}</b>!</Label>
                  </div>
                  </>
                )}
                {(this.state.subject == "64620873b1cebb9c4d5b70ea" ||
                  this.state.subject == "646208d0b1cebb9c4d5b70eb" ||
                  this.state.subject == "64f02ecb6d9efff481a93e67" ||
                  this.state.subject == "646208d3b1cebb9c4d5b70ec" ||
                  this.state.subject == "646208d6b1cebb9c4d5b70ed" ||
                  this.state.subject == "64620ebdb1cebb9c4d5b70f7" ||  
                  this.state.subject == "64c781a345daedcb3562a359" || 
                  this.state.subject == "64c781b245daedcb3562a35a" ||
                  this.state.subject == "65081c5b4e8e9173654eb40b" ||
                  this.state.subject == "65081cba4e8e9173654eb40c" ||
                  this.state.subject == "650a77061e37821fe76376dc") && (
                  <>                  
                  <FormGroup style={{ marginTop: 10 }}>
                    <Label for="exampleText">Title</Label>
                    <Input
                      type="text"
                      name="title"
                      className="input-date-filter remove-bottom-margin"
                      required={true}
                      disabled={true}
                      onChange={this.handleInputChange}
                      value={this.state.title}
                      rows={1}
                    />
                  </FormGroup>
                  </>
                )}
              <FormGroup>
                <Label for="exampleText">Message</Label>
                <Input
                  type="textarea"
                  className="input-date-filter remove-bottom-margin"
                  name="body"
                  required={true}
                  onChange={this.handleInputChange}
                  value={this.state.body}
                  rows={10}
                />
              </FormGroup>
              {!this.props.hideNotesButton && (
                <FormGroup>
                  <Label for="defaultCheck1" className="form-check-label pr-4">
                    Public Note?
                  </Label>
                  <Input
                    type="checkbox"
                    id="defaultCheck1"
                    name="isPublic"
                    className="form-check-input mt-0"
                    checked={this.state.isPublic}
                    disabled={this.state.hidePublicNotes}
                    onChange={this.handleInputChange}
                    rows={10}
                  />
                </FormGroup>
              )}
              <button type="submit" class="btn btn-block btn-info save-click-cls">
                <i class="mdi mdi-content-save"></i> Send
              </button>
            </Form>
          </ModalBody>
        </Modal>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
      </div>
    );
  }
}

function mapStateToProps({ careprovider, user }) {
  const { notes, onBoardingStatus,isNotApplicable,onBoarding,displayLabel, ongoingNoteListRequest,notesPatientDetails,ongoingRequestDevice,latestReadingUser, isGenericSuccess, isGenericError, rpmTotalDuration, ccmTotalDuration, mDeviceListOnBoarding, 
    ongoingRequest,genericErrorMessage } =
    careprovider;
  const isCareProvider = user.userDetails.isCareProvider;
  const isStaff = user.userDetails.isStaff;
  return {
    notes,
    onBoardingStatus,
    isNotApplicable,
    onBoarding,
    mDeviceListOnBoarding,
    displayLabel,
    ongoingRequestDevice,
    ongoingRequest,
    notesPatientDetails,
    latestReadingUser,
    isCareProvider,
    isStaff,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    ongoingNoteListRequest,
    rpmTotalDuration,
    ccmTotalDuration
  };
}
export default connect(mapStateToProps)(Notes);
